import { useEffect } from "react";

import { useBoolean } from "@/hooks/use-boolean";

export const useActiveDrag = (el?: EventTarget) => {
    const target: EventTarget = el ?? window;
    const [active, { open, close, set }] = useBoolean();

    useEffect(() => {
        const targets = new Set<EventTarget>();

        const dragenter: EventListener = (e) => {
            if (e.target) {
                targets.add(e.target);
                open();
            }
        };

        const dragleave: EventListener = (e) => {
            if (e.target) {
                targets.delete(e.target);
            }
            set(targets.size > 0);
        };

        const dragend: EventListener = () => {
            targets.clear();
            close();
        };

        target.addEventListener("dragenter", dragenter);
        target.addEventListener("dragleave", dragleave);
        target.addEventListener("dragend", dragend);
        target.addEventListener("drop", dragend);
        return () => {
            target.removeEventListener("dragenter", dragenter);
            target.removeEventListener("dragleave", dragleave);
            target.removeEventListener("dragend", dragend);
            target.removeEventListener("drop", dragend);
        };
    }, [target, open, close, set]);

    return active;
};
