import { Account, OnboardingStatusEnum, User } from "@/api/types";
import { HoverTooltip } from "@/components/hover-tooltip";
import { Badge } from "@/components/ui/badge";
import { formatDateTime } from "@/utils/time";
import { deactivatedAt, getOnboardingStatus } from "@/utils/user";

type OnboardingStatusProps = {
    entity: Account | User;
};

// todo take status or entiy
export const OnboardingStatus = (props: OnboardingStatusProps) => {
    const status = getOnboardingStatus(props.entity);
    switch (status) {
        case OnboardingStatusEnum.created:
            return (
                <HoverTooltip tooltip={formatDateTime(props.entity.created_at)}>
                    <Badge className="bg-blue-500/15 text-blue-700 hover:bg-blue-500/20 dark:text-blue-300">
                        Created
                    </Badge>
                </HoverTooltip>
            );
        case OnboardingStatusEnum.onboarded:
            return (
                <HoverTooltip
                    tooltip={formatDateTime(props.entity.onboarded_at!)}
                >
                    <Badge className="bg-green-500/15 text-green-700 hover:bg-green-500/20 dark:text-green-300">
                        Onboarded
                    </Badge>
                </HoverTooltip>
            );
        case OnboardingStatusEnum.deactivated:
            return (
                <HoverTooltip
                    tooltip={formatDateTime(deactivatedAt(props.entity)!)}
                >
                    <Badge className="bg-red-500/15 text-red-700 hover:bg-red-500/20 dark:text-red-300">
                        Deactivated
                    </Badge>
                </HoverTooltip>
            );
        default:
            return status satisfies never;
    }
};
