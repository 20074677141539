import { ComponentPropsWithRef, ReactNode } from "react";

import { AsyncButton, AsyncButtonProps } from "@/components/async-button";
import { HoverTooltip } from "@/components/hover-tooltip";

type Props = AsyncButtonProps & {
    tooltip: ReactNode;
} & ComponentPropsWithRef<"button">;

export const AsyncButtonWithTooltip = ({
    ref,
    tooltip,
    children,
    ...props
}: Props) => {
    return (
        <HoverTooltip tooltip={tooltip}>
            <AsyncButton ref={ref} {...props}>
                {children}
                <span className="sr-only">{tooltip}</span>
            </AsyncButton>
        </HoverTooltip>
    );
};
