import { useRef, useState } from "react";

export function useIntersection<T extends HTMLElement>(
    options: IntersectionObserverInit = {},
) {
    const { rootMargin, root, threshold } = options;
    const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
    const observer = useRef<IntersectionObserver>(
        new IntersectionObserver(
            ([_entry]) => {
                setEntry(_entry);
            },
            { rootMargin, root, threshold },
        ),
    );

    const ref = (element: T | null) => {
        if (element) {
            observer.current.observe(element);
            return () => {
                observer.current.disconnect();
                setEntry(null);
            };
        }
    };

    return { ref, entry };
}
