import { ChevronUp, ChevronDown } from "lucide-react";
import { Children, HTMLAttributes } from "react";

import { Button } from "@/components/ui/button";
import { useBoolean } from "@/hooks/use-boolean";

interface CollapsibleProps extends HTMLAttributes<HTMLDivElement> {
    maxLines?: number;
}

export const Collapsible = ({
    maxLines = 3,
    children,
    ...props
}: CollapsibleProps) => {
    const childArr = Children.toArray(children);
    const [collapsed, actions] = useBoolean(childArr.length > maxLines);
    const numDisplay = collapsed ? maxLines - 1 : childArr.length;

    const button = (
        <Button
            variant="ghost"
            size="xs"
            className="gap-1.5"
            onClick={actions.toggle}
        >
            {collapsed ? (
                <>
                    <ChevronDown className="size-4" />
                    {childArr.length - numDisplay} more
                </>
            ) : (
                <>
                    <ChevronUp className="size-4" />
                    Show Less
                </>
            )}
        </Button>
    );

    return (
        <div {...props}>
            {childArr.slice(0, numDisplay)}
            {childArr.length > maxLines && button}
        </div>
    );
};
