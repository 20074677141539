import { Copy } from "lucide-react";
import { ElementContent } from "node_modules/react-markdown/lib";
import {
    PropsWithChildren,
    JSX,
    TableHTMLAttributes,
    ThHTMLAttributes,
    memo,
} from "react";
import ReactMarkdown, {
    Components,
    defaultUrlTransform,
    ExtraProps,
    Options,
    UrlTransform,
} from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkDirective from "remark-directive";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import { CopyToClipboard } from "@/components/admin/copy-to-clipboard";
import { ErrorBoundary } from "@/components/error-boundary";
import { ActionDirective } from "@/components/markdown/action-directive";
import { CitationDirective } from "@/components/markdown/citation-directive";
import { customDirectives } from "@/components/markdown/custom-directives";
import { Button } from "@/components/ui/button";
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { useUser } from "@/hooks/use-user";
import { cn } from "@/lib/utils";
import { not } from "@/utils/fn";
import { escapeLatexText } from "@/utils/markdown-latex";
import { isUserSuperAdmin } from "@/utils/user";

const createErrorWrapper =
    <T extends PropsWithChildren>(
        component: (props: T) => JSX.Element | null,
    ) =>
    (props: T): JSX.Element => (
        <ErrorBoundary fallback={<>{props.children}</>}>
            {component(props)}
        </ErrorBoundary>
    );

const urlTransform: UrlTransform = (url, key, node) => {
    if (
        node.tagName === "img" &&
        key === "src" &&
        url.startsWith("data:image/")
    ) {
        // TODO: sanitize url, ideally use a signed base64 and decode here to avoid injections
        return url;
    }
    return defaultUrlTransform(url);
};

const isNewLineLiteral = (node: ElementContent): boolean =>
    node.type === "text" && node.value == "\n";

const getNodeContents = (node: ElementContent | undefined): string => {
    if (node == undefined) return "";
    switch (node.type) {
        case "element":
            switch (node.tagName) {
                case "tr":
                    return (
                        node.children
                            .filter(not(isNewLineLiteral))
                            .map(getNodeContents)
                            .join("\t") + "\n"
                    );
                default:
                    return node.children
                        .filter(not(isNewLineLiteral))
                        .map(getNodeContents)
                        .join(" ");
            }
        case "text":
            if (node.value === "\n") return "";
            return node.value;
        case "comment":
        case "mdxJsxTextElement":
        case "mdxJsxFlowElement":
            return "";
        case "raw":
            return "";
    }
};

const CustomTable = ({
    node,
    className,
    ...props
}: TableHTMLAttributes<HTMLTableElement> & ExtraProps) => {
    return (
        <div className="rounded border">
            <Table className={cn("my-0", className)} {...props} />
            <div className="flex justify-end border-t p-1">
                <CopyToClipboard value={getNodeContents(node)} asChild>
                    <Button
                        variant="ghost"
                        size="xs"
                        className="gap-2 text-muted-foreground"
                    >
                        <Copy className="size-4" />
                        Copy Table Contents
                    </Button>
                </CopyToClipboard>
            </div>
        </div>
    );
};

const CustomTableHead = ({
    node,
    className,
    ...props
}: ThHTMLAttributes<HTMLTableCellElement> & ExtraProps) => (
    <TableHead className={cn("pt-2", className)} {...props} />
);

export type MarkdownProps = Options & {
    useCustomTable?: boolean;
};

export const Markdown = memo(
    ({
        useCustomTable = false,
        children,
        className,
        ...props
    }: MarkdownProps) => {
        const user = useUser();
        const customTableComponents = useCustomTable
            ? {
                  table: CustomTable,
                  thead: TableHeader,
                  tbody: TableBody,
                  tfoot: TableFooter,
                  th: CustomTableHead,
                  tr: TableRow,
                  td: TableCell,
              }
            : undefined;
        return (
            <ReactMarkdown
                remarkPlugins={[
                    remarkDirective,
                    customDirectives,
                    remarkGfm,
                    [remarkMath, { singleDollarTextMath: false }],
                ]}
                rehypePlugins={[rehypeKatex]}
                components={
                    {
                        ...props.components,
                        action: createErrorWrapper(ActionDirective),
                        cit: createErrorWrapper(CitationDirective),
                        ...customTableComponents,
                    } as Partial<Components>
                }
                urlTransform={isUserSuperAdmin(user) ? urlTransform : undefined}
                className={cn(
                    "prose prose-neutral max-w-none leading-6 dark:prose-invert prose-headings:font-headline prose-headings:font-bold prose-headings:text-foreground prose-h2:text-xl prose-h5:text-sm prose-h6:text-xs prose-p:text-foreground",
                    className,
                )}
                {...props}
            >
                {escapeLatexText(children ?? "")}
            </ReactMarkdown>
        );
    },
);
