import {
    Column,
    ColumnDefinition,
    ColumnType,
    FindingGroupInfo,
    FindingGroupType,
    ProbeType,
    UUID,
} from "@/api/types";

import { capitalizeAll } from "./string-helpers";

export enum ColumnFilterType {
    none = 0,
    text,
    boolean,
    numerical,
}

export const getColumId = (c: Column): string => {
    switch (c.column_type) {
        case ColumnType.system:
            return c.finding_group_type;
        case ColumnType.user_defined:
            return c.details.id;
    }
};

export const getColumnTitle = (
    column: Column,
    colDefs: Map<FindingGroupType, ColumnDefinition>,
): string => {
    switch (column.column_type) {
        case ColumnType.system:
            return (
                colDefs.get(column.finding_group_type)?.description ??
                capitalizeAll(column.finding_group_type.replace("_", " "))
            );
        case ColumnType.user_defined:
            return column.details.name;
    }
};

export const getColumnIdFromFindingGroup = (
    fg: FindingGroupInfo,
): string | undefined => {
    switch (fg.type) {
        case FindingGroupType.user_defined_text:
            return "probe" in fg ? fg.probe?.id : undefined;
        default:
            return fg.type;
    }
};

export const getProbeID = (c: Column): UUID | undefined => {
    switch (c.column_type) {
        case ColumnType.system:
            return undefined;
        case ColumnType.user_defined:
            return c.details.id;
    }
};

export const getColumnFilterType = (c: Column): ColumnFilterType => {
    switch (c.column_type) {
        case ColumnType.system:
            switch (c.finding_group_type) {
                case FindingGroupType.adversarial_questions:
                case FindingGroupType.compliance_events:
                case FindingGroupType.executive_summary:
                case FindingGroupType.followup_instructions:
                case FindingGroupType.governance_changes:
                case FindingGroupType.insights:
                case FindingGroupType.key_insights_and_stats:
                case FindingGroupType.litigation:
                case FindingGroupType.opportunities:
                case FindingGroupType.risks:
                case FindingGroupType.stats:
                    return ColumnFilterType.text;
                case FindingGroupType.merger_acquisition:
                    return ColumnFilterType.boolean;
                case FindingGroupType.user_defined_text:
                    return ColumnFilterType.none;
                default:
                    return c.finding_group_type satisfies never;
            }
        case ColumnType.user_defined:
            switch (c.details.type) {
                case ProbeType.boolean:
                    return ColumnFilterType.boolean;
                case ProbeType.finding_list:
                case ProbeType.list:
                case ProbeType.markdown:
                case ProbeType.short_text:
                case ProbeType.text:
                    return ColumnFilterType.text;
                case ProbeType.number:
                    return ColumnFilterType.numerical;
                default:
                    return c.details.type satisfies never;
            }
    }
};
