import { useEffect, useRef } from "react";

export const useOnUnmount = (cb: () => void) => {
    const isUnmounted = useRef(true);
    useEffect(() => {
        isUnmounted.current = false;
        return () => {
            isUnmounted.current = true;
            setTimeout(() => {
                if (isUnmounted.current) {
                    cb();
                }
            }, 0);
        };
    }, []);
};
