import { Column, RowData } from "@tanstack/react-table";
import { CSSProperties } from "react";

export const getColumnStyles = <TData extends RowData>(
    column: Column<TData>,
): CSSProperties => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn =
        isPinned === "left" && column.getIsLastColumn("left");
    const isFirstRightPinnedColumn =
        isPinned === "right" && column.getIsFirstColumn("right");

    return {
        position: isPinned ? "sticky" : undefined,
        zIndex: isPinned ? 1 : 0,
        left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
        right:
            isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
        width: `${column.getSize()}px`,
        boxShadow: isLastLeftPinnedColumn
            ? "-1px 0 1px 0 hsl(var(--border)) inset"
            : isFirstRightPinnedColumn
              ? "1px 0 1px 0 hsl(var(--border)) inset"
              : undefined,
        borderRightWidth: isPinned ? undefined : "1px",
    };
};
