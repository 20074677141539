import { InvoiceStatus as InvoiceStatusEnum } from "@/api/types";
import { Badge } from "@/components/ui/badge";

export const InvoiceStatus = (props: { status: InvoiceStatusEnum }) => {
    switch (props.status) {
        case InvoiceStatusEnum.open:
            return (
                <Badge
                    variant="secondary"
                    className="border-red-100 bg-amber-50 text-amber-500 dark:border-amber-800 dark:bg-amber-900"
                >
                    open
                </Badge>
            );
        case InvoiceStatusEnum.paid:
            return (
                <Badge
                    variant="secondary"
                    className="border-green-100 bg-green-50 text-green-500 dark:border-green-800 dark:bg-green-900"
                >
                    paid
                </Badge>
            );
        case InvoiceStatusEnum.void:
            return (
                <Badge
                    variant="secondary"
                    className="border-red-100 bg-red-50 text-red-500 dark:border-red-800 dark:bg-red-900"
                >
                    cancelled
                </Badge>
            );
        // these states should not happen
        case InvoiceStatusEnum.draft:
        case InvoiceStatusEnum.uncollectible:
            return null;
        default:
            return props.status satisfies never;
    }
};
