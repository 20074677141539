import { ReactNode } from "react";
import useSWRImmutable from "swr/immutable";

import { Subscription } from "@/api/types";
import { useApi } from "@/hooks/use-api";

type Props = {
    fetchKey?: string;
    children: (value: { subscription: Subscription }) => ReactNode;
};

export const SubscriptionContainer = (props: Props) => {
    const api = useApi();
    const { data: subscription } = useSWRImmutable(
        ["subscription", props.fetchKey].filter(Boolean).join("_"),
        async () => await api.fetch_subscription(),
        { suspense: true },
    );

    return props.children({ subscription });
};
