import { ChevronDown, X } from "lucide-react";
import { HTMLAttributes, MouseEvent, ReactNode } from "react";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";

interface SelectorPillProps extends HTMLAttributes<HTMLDivElement> {
    value: string;
    onRemove?: (value: string, e: MouseEvent<HTMLButtonElement>) => void;
}

const SelectorPill = ({
    value,
    onRemove,
    className,
    ...props
}: SelectorPillProps) => (
    <div
        className={cn(
            "flex items-center gap-1.5 rounded border bg-muted pl-1.5 text-sm",
            className,
        )}
        {...props}
    >
        {props.children}
        <Button
            type="button"
            variant="ghost"
            size="icon-xs"
            onClick={(e) => onRemove?.(value, e)}
        >
            <X className="size-4" />
        </Button>
    </div>
);

type Option<T> = {
    value: T;
    label: ReactNode;
};

interface Props<T> extends HTMLAttributes<HTMLDivElement> {
    placeholder?: string;
    options: Option<T>[];
    value: T[];
    onValueChange?: (value: T[]) => void;
}

export const MultiSelectCheckbox = <T extends string>({
    placeholder,
    options,
    value,
    onValueChange,
    className,
    ...props
}: Props<T>) => {
    const optMap = options.reduce(
        (acc, el) => acc.set(el.value, el.label),
        new Map<T, ReactNode>(),
    );
    const valueSet = new Set(value);
    const onCheckChange = (val: T) =>
        onValueChange
            ? (checked: boolean) => {
                  if (checked) {
                      onValueChange([...value, val]);
                  } else {
                      onValueChange(value.filter((v) => v !== val));
                  }
              }
            : undefined;
    const removeItem = (val: string, e: MouseEvent) => {
        if (onValueChange) {
            e.stopPropagation();
            onValueChange(value.filter((v) => v !== val));
        }
    };
    const selectAll = () => onValueChange?.(options.map((v) => v.value));
    const deselectAll = () => onValueChange?.([]);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <div
                    {...props}
                    className={cn(
                        "flex min-h-10 items-center justify-between gap-2 rounded border p-1.5",
                        className,
                    )}
                >
                    {value.length > 0 ? (
                        <div className="flex flex-wrap gap-2">
                            {value.map((v) => (
                                <SelectorPill
                                    key={v}
                                    value={v}
                                    onRemove={removeItem}
                                >
                                    {optMap.get(v)}
                                </SelectorPill>
                            ))}
                        </div>
                    ) : (
                        <span className="text-sm text-muted-foreground">
                            {placeholder}
                        </span>
                    )}
                    <ChevronDown className="ml-2 size-4" />
                </div>
            </PopoverTrigger>
            <PopoverContent
                side="bottom"
                align="start"
                className="overflow-hidden p-0"
            >
                <ScrollArea>
                    <div className="max-h-[300px] space-y-2 p-4">
                        {options.map(({ value, label }) => (
                            <div
                                key={value}
                                className="flex items-center space-x-2"
                            >
                                <Checkbox
                                    id={value}
                                    checked={valueSet.has(value)}
                                    onCheckedChange={onCheckChange(value)}
                                />
                                <label
                                    htmlFor={value}
                                    className="cursor-pointer font-medium"
                                    children={label}
                                />
                            </div>
                        ))}
                    </div>
                </ScrollArea>
                <div className="flex justify-between gap-4 border-t bg-gray-50 px-4 py-2 dark:bg-gray-900">
                    <Button
                        variant="link"
                        className="h-auto p-0"
                        onClick={selectAll}
                    >
                        Select All
                    </Button>
                    <Button
                        variant="link"
                        className="h-auto p-0"
                        onClick={deselectAll}
                    >
                        Deselect All
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    );
};
