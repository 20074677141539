import { Column as TSColumn } from "@tanstack/react-table";
import { Row } from "@tanstack/react-table";

import {
    AsyncFindingGroup,
    Column,
    ColumnType,
    DocumentInfo,
    FindingContentType,
    FindingGroupType,
    ProbeType,
} from "@/api/types";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { AsyncState } from "@/utils/async-value";
import { getAllFindingContent } from "@/utils/finding-group";
import { isNull, nonNull } from "@/utils/fn";
import { inRange, normalizeNumber } from "@/utils/math";

export enum FilterType {
    none = 0,
    text,
    boolean,
    numerical,
    categorical,
}

export const getColumnFilterType = (c: Column): FilterType => {
    switch (c.column_type) {
        case ColumnType.system:
            switch (c.finding_group_type) {
                case FindingGroupType.adversarial_questions:
                case FindingGroupType.compliance_events:
                case FindingGroupType.executive_summary:
                case FindingGroupType.followup_instructions:
                case FindingGroupType.governance_changes:
                case FindingGroupType.insights:
                case FindingGroupType.key_insights_and_stats:
                case FindingGroupType.litigation:
                case FindingGroupType.opportunities:
                case FindingGroupType.risks:
                case FindingGroupType.stats:
                    return FilterType.text;
                case FindingGroupType.merger_acquisition:
                    return FilterType.boolean;
                case FindingGroupType.user_defined_text:
                    return FilterType.none;
                default:
                    return c.finding_group_type satisfies never;
            }
        case ColumnType.user_defined:
            switch (c.details.type) {
                case ProbeType.boolean:
                    return FilterType.boolean;
                case ProbeType.finding_list:
                case ProbeType.list:
                case ProbeType.markdown:
                case ProbeType.short_text:
                case ProbeType.text:
                    return FilterType.text;
                case ProbeType.number:
                    return FilterType.numerical;
                default:
                    return c.details.type satisfies never;
            }
    }
};

export const getNumericalFilterRange = (
    columnId: string,
    column: TSColumn<DocumentWithAsyncFindings, unknown> | undefined,
) => {
    const values = (column?.getFacetedRowModel().flatRows ?? [])
        .flatMap((fr) =>
            fr.getUniqueValues<[DocumentInfo, AsyncFindingGroup]>(columnId),
        )
        .flatMap(([, fg]) => fg.findings)
        .filter((f) => f.state === AsyncState.success)
        .flatMap((f) => f.value)
        .filter((f) => f.content_type === FindingContentType.numerical)
        .map((f) => normalizeNumber(f.value, f.exponent ?? 0))
        .sort();
    if (values.length === 0) return [-Infinity, Infinity];
    return [Math.min(...values), Math.max(...values)];
};

type RowValue = [DocumentInfo, AsyncFindingGroup | undefined];

type TextFilterValue = { contains: string };
type BooleanFilterValue = boolean;
type NumericalFilterValue = { min: number; max: number };

export const getColumnFilterFn =
    (column: Column) =>
    (
        row: Row<DocumentWithAsyncFindings>,
        columnId: string,
        filterValue: unknown,
    ) => {
        if (isNull(filterValue)) {
            return true;
        }

        const [_, findingGroup] = row.getValue<RowValue>(columnId);

        if (isNull(findingGroup)) {
            return false;
        }
        const filterType = getColumnFilterType(column);
        switch (filterType) {
            case FilterType.none:
                return true;
            case FilterType.text:
                return evaluateTextFilter(
                    findingGroup,
                    filterValue as TextFilterValue,
                );
            case FilterType.boolean:
                return evaluateBooleanFilter(
                    findingGroup,
                    filterValue as BooleanFilterValue,
                );
            case FilterType.numerical:
                return evaluateNumericalFilter(
                    findingGroup,
                    filterValue as NumericalFilterValue,
                );
            case FilterType.categorical:
                return false;
            default:
                return filterType satisfies never;
        }
    };

const evaluateTextFilter = (
    findingGroup: AsyncFindingGroup,
    filterValue: TextFilterValue,
) => {
    if (findingGroup.findings.state !== AsyncState.success) {
        return false;
    }
    const content = findingGroup.findings.value
        .map(getAllFindingContent)
        .filter(nonNull)
        .join(" ")
        .toLowerCase();
    return content.includes(filterValue.contains.toLowerCase());
};

const evaluateBooleanFilter = (
    findingGroup: AsyncFindingGroup,
    filterValue: BooleanFilterValue,
) => {
    if (findingGroup.findings.state !== AsyncState.success) {
        return false;
    }
    return findingGroup.findings.value
        .filter((f) => f.content_type === FindingContentType.boolean)
        .some((f) => f.value === filterValue);
};

const evaluateNumericalFilter = (
    findingGroup: AsyncFindingGroup,
    { min, max }: NumericalFilterValue,
) => {
    if (findingGroup.findings.state !== AsyncState.success) {
        return false;
    }
    return findingGroup.findings.value
        .filter((f) => f.content_type === FindingContentType.numerical)
        .map((f) => normalizeNumber(f.value, f.exponent ?? 0))
        .every(inRange(min, max));
};
