import { Check, Loader2, X } from "lucide-react";
import { HTMLAttributes } from "react";

import { FileStatus, UploadFile } from "@/api/types";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { getErrorMessage } from "@/utils/file-upload";

interface RowProps extends HTMLAttributes<HTMLLIElement> {
    file: UploadFile;
}

const UploadFileComponent = ({ file, className, ...props }: RowProps) => {
    switch (file.status) {
        case FileStatus.initial:
        case FileStatus.fetching_info:
        case FileStatus.uploading:
            return (
                <li
                    className={cn("flex items-center gap-3", className)}
                    {...props}
                >
                    <Loader2 className="size-4 animate-spin" />
                    <span className="line-clamp-1">{file.name}</span>
                </li>
            );
        case FileStatus.uploaded:
        case FileStatus.processing:
        case FileStatus.ready:
            return (
                <li
                    className={cn(
                        "flex items-center gap-3 bg-green-50 text-green-800 dark:bg-green-900 dark:text-green-400",
                        className,
                    )}
                    {...props}
                >
                    <Check className="size-4" />
                    <span className="line-clamp-1">{file.name}</span>
                </li>
            );
        case FileStatus.failed:
        case FileStatus.rejected:
            return (
                <li
                    className={cn(
                        "flex items-center gap-3 bg-red-50 text-destructive dark:bg-red-950 dark:text-red-400",
                        className,
                    )}
                >
                    <X className="size-4" />
                    <div>
                        <p className="line-clamp-1">{file.name}</p>
                        <p className="empty:hide italic">
                            {getErrorMessage(file.error)}
                        </p>
                    </div>
                </li>
            );
    }
};

interface Props extends HTMLAttributes<HTMLDivElement> {
    files: UploadFile[];
    onDismiss?: () => void;
}

export const UploadToast = ({
    files,
    onDismiss,
    className,
    ...props
}: Props) => {
    if (files.length === 0) return null;
    const canDismiss =
        onDismiss !== undefined &&
        files.every((f) => f.status >= FileStatus.failed);
    return (
        <div
            className={cn(
                "absolute bottom-2 left-2 z-20 flex max-h-[50vh] min-w-96 max-w-screen-sm flex-col rounded-md border bg-background shadow-xl animate-in slide-in-from-bottom-4",
                className,
            )}
            {...props}
        >
            <div className="flex min-h-10 items-center justify-between gap-4 overflow-hidden border-b px-1 font-headline font-bold">
                <span className="px-3">Uploading files...</span>
                {canDismiss && (
                    <Button size="icon-sm" variant="ghost" onClick={onDismiss}>
                        <X className="size-4" />
                    </Button>
                )}
            </div>
            <ul className="divide-y overflow-x-scroll text-sm">
                {files.map((file) => (
                    <UploadFileComponent
                        key={file.upload_id}
                        file={file}
                        className="px-4 py-2.5"
                    />
                ))}
            </ul>
        </div>
    );
};
