import { Pencil, Plus } from "lucide-react";
import { toast } from "sonner";
import useSWRImmutable from "swr/immutable";

import { BillingDetailsData } from "@/api/rest";
import { AsyncButton } from "@/components/async-button";
import { BillingDetails } from "@/components/billing/billing-details";
import { ErrorAlert } from "@/components/error-alert";
import { Button } from "@/components/ui/button";
import { BillingInformationForm } from "@/container/paywall/billing-info-form";
import { StripeElements } from "@/container/paywall/stripe-elements";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";

export const BillingInformation = () => {
    const api = useApi();
    const [edit, editActions] = useBoolean();
    const { data, error, mutate } = useSWRImmutable(
        "billing_information",
        async () => await api.fetch_billing_info(),
        { suspense: true },
    );

    const action = useAsyncState(
        async (data: BillingDetailsData) => api.update_billing_info(data),
        {
            onSuccess: ({ billing_details }) => {
                mutate(billing_details);
                editActions.close();
                toast.success("Billing information updated");
            },
        },
    );

    if (error) return <ErrorAlert error={error} />;

    if (edit) {
        return (
            <div className="space-y-2 rounded border p-4">
                <StripeElements>
                    <BillingInformationForm
                        action={action}
                        address={data.billing_address ?? undefined}
                    >
                        <div className="-m-4 flex items-baseline justify-end gap-4 border-t bg-accent p-4">
                            <Button
                                type="button"
                                variant="link"
                                onClick={editActions.close}
                            >
                                Cancel
                            </Button>
                            <AsyncButton loading={action.isSubmitting}>
                                Save Changes
                            </AsyncButton>
                        </div>
                    </BillingInformationForm>
                </StripeElements>
            </div>
        );
    }

    return (
        <div className="rounded border">
            <BillingDetails billing_details={data} />
            <div className="border-t bg-accent px-2">
                {data.billing_address != null ? (
                    <Button
                        onClick={editActions.open}
                        variant="link"
                        className="px-2"
                    >
                        <Pencil className="mr-2 size-4" />
                        Edit
                    </Button>
                ) : (
                    <Button
                        onClick={editActions.open}
                        variant="link"
                        className="px-2"
                    >
                        <Plus className="mr-2 size-4" />
                        Add Billing Information
                    </Button>
                )}
            </div>
        </div>
    );
};
