import { Combine, FilePenLine, Loader2, RefreshCw, X } from "lucide-react";
import { Suspense, useState } from "react";
import { useShallow } from "zustand/react/shallow";

import { FindingGroupInfo, FindingGroupType, Probe } from "@/api/types";
import { IncomingWebsocketEventType } from "@/api/ws/websocket-types";
import { AsyncButton } from "@/components/async-button";
import { AsyncButtonWithTooltip } from "@/components/async-button-with-tooltip";
import { Debugger } from "@/components/debug/debugger";
import { SynthesisDetails } from "@/components/document-table/synthesis/synthesis-details";
import {
    FormSchema,
    SynthesisForm,
} from "@/components/document-table/synthesis/synthesis-form";
import { ErrorAlert } from "@/components/error-alert";
import { ErrorBoundary } from "@/components/error-boundary";
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetTitle,
} from "@/components/ui/sheet";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";
import { useGridView } from "@/hooks/use-grid-view-context";
import { useWebsocket } from "@/hooks/use-websocket";
import { notEmptyOrNull } from "@/utils/string-helpers";

export const SynthesisTrigger = ({
    findingGroupType,
    probe,
}: {
    findingGroupType: FindingGroupType;
    probe?: Probe;
}) => {
    const canRegenerateSynthesis = useFeatureFlagBool(
        FeatureFlagBool.regenerate_synthesis,
        true,
    );
    const [dialogOpen, dialogActions] = useBoolean();
    const [synthesisInfo, setSynthesisInfo] = useState<Omit<
        FindingGroupInfo,
        "document_id"
    > | null>(null);
    const api = useApi();
    const message_id = useGridView((s) => s.report.id);
    const finding_ids = useGridView(
        useShallow((s) =>
            Array.from(s.finding_groups.values())
                .filter(
                    (fg) =>
                        fg.type === findingGroupType &&
                        fg.probe?.id === probe?.id,
                )
                .map((fg) => fg.id),
        ),
    );
    const createAction = useAsyncState(
        async () => await api.create_synthesis(message_id, finding_ids),
        { onSuccess: setSynthesisInfo },
    );
    const regenAction = useAsyncState(
        async () =>
            await api.create_synthesis(message_id, finding_ids, "summary", {
                force: true,
            }),
        { onSuccess: setSynthesisInfo },
    );
    const handleOpenChange = (isOpen: boolean) => {
        if (!isOpen) {
            setSynthesisInfo(null);
        }
    };

    const createCustomAction = useAsyncState(
        async (data: FormSchema) =>
            await api.create_synthesis(message_id, finding_ids, data.type, {
                force: true,
                use_o1: data.use_o1,
                override_prompt: data.override_prompt,
                custom_input: notEmptyOrNull(data.prompt)
                    ? data.prompt
                    : undefined,
            }),
        {
            onSuccess: (synthesis) => {
                dialogActions.close();
                setSynthesisInfo(synthesis);
            },
        },
    );

    useWebsocket(
        (ws) => {
            ws.onIf(
                IncomingWebsocketEventType.finding_group_updated,
                (fg) => synthesisInfo !== null && fg.id === synthesisInfo.id,
                setSynthesisInfo,
            );
        },
        [synthesisInfo],
    );

    return (
        <>
            <AsyncButton
                variant="ghost"
                size="sm"
                type="button"
                className="w-full justify-start"
                action={createAction}
            >
                <Combine className="mr-2 size-4" />
                Synthesis
            </AsyncButton>
            <Debugger>
                <Button
                    variant="ghost"
                    size="sm"
                    type="button"
                    className="w-full justify-start"
                    onClick={dialogActions.open}
                >
                    <FilePenLine className="mr-2 size-4" />
                    Custom Synthesis
                </Button>
                <Dialog open={dialogOpen} onOpenChange={dialogActions.set}>
                    <DialogContent withCloseButton={false} className="p-2">
                        <DialogHeader className="flex flex-row items-center justify-between gap-2 space-y-0">
                            <DialogTitle className="pl-2">
                                Create Synthesis
                            </DialogTitle>
                            <DialogDescription className="sr-only">
                                Create a custom synthesis
                            </DialogDescription>
                            <DialogClose asChild>
                                <Button variant="ghost" size="icon">
                                    <X className="size-4" />
                                </Button>
                            </DialogClose>
                        </DialogHeader>
                        <SynthesisForm
                            action={createCustomAction}
                            className="px-2"
                        />
                    </DialogContent>
                </Dialog>
            </Debugger>
            <Sheet
                open={synthesisInfo !== null}
                onOpenChange={handleOpenChange}
            >
                <SheetContent
                    className="focus:ring-none flex min-w-[800px] flex-col border-none bg-transparent p-0 shadow-none"
                    withCloseButton={false}
                >
                    <div className="m-2 flex grow flex-col overflow-hidden rounded-lg border bg-background shadow-lg">
                        <div className="flex items-center justify-between gap-2 border-b p-1 pl-4">
                            <SheetTitle className="grow">Synthesis</SheetTitle>
                            {canRegenerateSynthesis && (
                                <AsyncButtonWithTooltip
                                    tooltip="Regenerate Synthesis"
                                    action={regenAction}
                                    variant="ghost"
                                    size="icon"
                                >
                                    <RefreshCw className="size-4" />
                                </AsyncButtonWithTooltip>
                            )}
                            <SheetDescription className="sr-only">
                                Synthesis for the column
                            </SheetDescription>
                            <SheetClose asChild>
                                <Button variant="ghost" size="icon">
                                    <X className="size-4" />
                                </Button>
                            </SheetClose>
                        </div>
                        <div className="overflow-y-scroll">
                            <ErrorBoundary
                                fallback={({ error }) => (
                                    <div className="p-4">
                                        <ErrorAlert
                                            message="Failed to load Synthesis"
                                            error={
                                                error instanceof Error
                                                    ? error
                                                    : undefined
                                            }
                                        />
                                    </div>
                                )}
                            >
                                <Suspense
                                    fallback={
                                        <div className="flex justify-center p-20">
                                            <Loader2 className="size-4 animate-spin" />
                                        </div>
                                    }
                                >
                                    {synthesisInfo && (
                                        <SynthesisDetails
                                            findingGroupInfo={synthesisInfo}
                                            regenerateAction={regenAction}
                                        />
                                    )}
                                </Suspense>
                            </ErrorBoundary>
                        </div>
                    </div>
                </SheetContent>
            </Sheet>
        </>
    );
};
