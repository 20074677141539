import { GenericFindingContent, FindingContentType } from "@/api/types";
import { useDevMode } from "@/hooks/use-dev-mode";
import { cn } from "@/lib/utils";
import { notEmptyOrNull, simpleHash } from "@/utils/string-helpers";

import { Markdown } from "../markdown";

export const FindingContent = ({
    finding,
    className,
}: {
    finding: GenericFindingContent;
    className?: string;
}) => {
    const [devMode] = useDevMode();
    switch (finding.content_type) {
        case FindingContentType.list_item:
            return <li>{finding.text}</li>;
        case FindingContentType.boolean:
            return notEmptyOrNull(finding.text) && finding.value ? (
                <p>{finding.text}</p>
            ) : null;
        case FindingContentType.short_text:
        case FindingContentType.full_text:
        case FindingContentType.long_text:
            return (
                <Markdown className={cn("basis-full text-sm", className)}>
                    {finding.text}
                </Markdown>
            );
        case FindingContentType.titled_long_text:
            return (
                <div className="basis-full">
                    <p className="font-headline font-bold">{finding.title}</p>
                    <p>{finding.text}</p>
                </div>
            );
        case FindingContentType.titled_list:
            return (
                <>
                    <p className="font-headline font-bold">{finding.title}</p>
                    <ul className="space-y-2">
                        {finding.key_points.map((item, i) => (
                            <li key={simpleHash(item + i)}>{item}</li>
                        ))}
                    </ul>
                </>
            );
        case FindingContentType.numerical:
        case FindingContentType.numerical_full_text:
        case FindingContentType.unstructured:
            return null;
        default:
            if (devMode) {
                return (
                    <pre className="rounded bg-muted p-2 text-[9px]">
                        {JSON.stringify(finding, null, 2)}
                    </pre>
                );
            }
            return finding satisfies never;
    }
};
