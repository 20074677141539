import { compareDesc } from "date-fns";

import {
    APAction,
    APActionChunk,
    APActionEvidence,
    APActionResponse,
    APActionBase,
    Citation,
    APActionType,
    User,
    ActionConfigBase,
    ActionLaunchStatus,
    ActionConfig,
    APActionCitation,
    Entity,
    ReferenceInfoWithCitations,
    UUID,
    APActionStatusReason,
    APActionStatusUpdate,
} from "@/api/types";
import { actionConfigMap } from "@/conf/actions";
import { firstX, uniqueBy } from "@/utils/collection";
import { notEmptyOrNull } from "@/utils/string-helpers";
import { hasBetaAccess, isUserSuperAdmin } from "@/utils/user";

export type Quote = {
    text: string;
    citations?: Citation[];
};

export const isAction = (a: APActionBase): a is APAction =>
    "evidence" in a &&
    a.evidence != null &&
    "response" in a &&
    a.response != null;

export const isChunk = (action: APActionBase): action is APActionChunk =>
    isAction(action) && "index" in action && action.index != null;

export const getResponse = (a: APActionBase): APActionResponse | undefined =>
    isAction(a) ? a.response : undefined;

export const getEvidence = (
    a: APActionBase,
): APActionEvidence[] | undefined => {
    if (isAction(a) && a.response.searched_for_evidence) {
        return a.evidence;
    }
    return undefined;
};

export const getCitations = (a: APActionBase): APActionCitation[] | undefined =>
    getResponse(a)?.evidence_citations;

export const getQuote = (a: APActionBase): Quote => {
    const response = getResponse(a);
    if (response && notEmptyOrNull(response.content)) {
        return {
            text: response.content,
            citations: response.citations.filter(
                // legacy handling, filter null citations
                (c) => c.start != null && c.end != null,
            ),
        };
    }
    return { text: a.content };
};

const merge = <T extends Entity>(
    a: T[] | undefined,
    b: T[] | undefined,
): T[] | undefined => {
    if (a == undefined || b == undefined) return b ?? a;
    return uniqueBy([...b, ...a], (e) => e.id);
};

export const reduceActionChunk = <T extends APActionBase>(
    action: T | undefined,
    chunk: APActionChunk,
): T | undefined => {
    if (action == undefined) return undefined;
    // skip if the payload chunk is outdated
    if (isChunk(action) && action.index > chunk.index) {
        return action;
    }

    return {
        ...action,
        evidence: merge(getEvidence(action), chunk.evidence),
        response: chunk.response,
    };
};

export const getActionLabel = (type: APActionType): string =>
    actionConfigMap[type].label;

const canSeeAction = (user: User, config: ActionConfigBase): boolean => {
    switch (config.launchStatus) {
        case ActionLaunchStatus.LAUNCHED:
            return true;
        case ActionLaunchStatus.BETA:
            return hasBetaAccess(user);
        case ActionLaunchStatus.SUPERADMIN:
            return isUserSuperAdmin(user);
        default:
            return config.launchStatus satisfies never;
    }
};

export const getActionsForUser = (user: User): ActionConfig[] => {
    return Array.from(Object.entries(actionConfigMap))
        .filter(([, config]) => canSeeAction(user, config))
        .map(([type, config]) => ({
            type: type as APActionType,
            ...config,
        }));
};

export const isContextMenuAction = (config: ActionConfig): boolean =>
    config.type !== APActionType.chat_message;

const sortEvidenceWithCitations = (
    a: ReferenceInfoWithCitations,
    b: ReferenceInfoWithCitations,
): number => {
    // sort by citation number
    if (a.citations && b.citations) {
        return firstX(a.citations) - firstX(b.citations);
    }
    if (a.citations && !b.citations) return -1;
    if (!a.citations && b.citations) return 1;

    // else sort by content date
    if (a.info.content_date && b.info.content_date)
        return compareDesc(a.info.content_date, b.info.content_date);
    return 0;
};

type CompareFn<T> = (a: T, b: T) => number;
const maybeToSortedArray = <T>(
    itr: Iterable<T> | undefined,
    cmpFn?: CompareFn<T>,
): T[] | undefined => {
    if (itr == undefined) return undefined;
    const arr = Array.from(itr);
    return arr.length > 0 ? arr.sort(cmpFn) : undefined;
};

export const getReferenceInfoWithCitations = (
    action: APActionBase,
): ReferenceInfoWithCitations[] => {
    const citations = (getCitations(action) ?? []).reduce(
        (map, { document_id: id, display_index }) => {
            const set = map.get(id);
            if (set) {
                set.add(display_index);
            } else {
                map.set(id, new Set([display_index]));
            }
            return map;
        },
        new Map<UUID, Set<number>>(),
    );

    return uniqueBy(
        (getEvidence(action) ?? []).map((e) => e.document_info),
        (d) => d.id,
    )
        .map((info) => ({
            info,
            citations: maybeToSortedArray(citations.get(info.id)?.values()),
        }))
        .sort(sortEvidenceWithCitations);
};

export const getActionStatusReason = (reason: APActionStatusReason): string => {
    switch (reason) {
        case APActionStatusReason.no_documents_found:
            return "No Documents Found";
        case APActionStatusReason.analyzing_query:
            return "Analyzing Query";
        case APActionStatusReason.analyzed_query:
            return "Analyzed Query";
        case APActionStatusReason.analyzing_documents:
            return "Analyzing Documents";
        case APActionStatusReason.gathering_evidence:
            return "Gathering Evidence";
        case APActionStatusReason.formulating_answer:
            return "Formulating Answer";
        case APActionStatusReason.api_error:
            return "API Error";
        case APActionStatusReason.bad_action_request:
            return "Bad Request";
        case APActionStatusReason.context_not_loaded:
            return "Context not loaded";
        case APActionStatusReason.generating_response:
            return "Generating Response";
        case APActionStatusReason.invalid_selection:
            return "Invalid selection";
        case APActionStatusReason.message_id_exists:
            return "Message ID Exists";
        case APActionStatusReason.no_evidence_found:
            return "No Evidence Found";
        case APActionStatusReason.permission_denied:
            return "Permission Denied";
        case APActionStatusReason.processing_documents:
            return "Processing Documents";
        case APActionStatusReason.searching_documents:
            return "Searching Documents";
        case APActionStatusReason.searched_documents:
            return "Searched Documents";
        case APActionStatusReason.timeout:
            return "Timeout";
        case APActionStatusReason.tool_error:
            return "Tool Error";
        case APActionStatusReason.unexpected_error:
            return "Unexpected Error";
        case APActionStatusReason.retrieving_documents:
            return "Retrieving Documents";
        case APActionStatusReason.no_companies_found:
            return "No Companies Found";
        case APActionStatusReason.searched_companies:
            return "Searched Companies";
        case APActionStatusReason.searching_companies:
            return "Searching Companies";
        default:
            return reason satisfies never;
    }
};

export const isAPActionStatusUpdate = (
    action: APActionBase,
): action is APActionStatusUpdate => "state" in action;

export const getActionState = (
    action: APActionBase,
): APActionStatusUpdate["state"] | undefined =>
    isAPActionStatusUpdate(action) ? action.state : undefined;
