import { useLocalStorage } from "@/hooks/use-local-storage";
import { isTokenExpired } from "@/utils/jwt";

export type Token = string | null;

const validateToken = (token: string | null): Token | null =>
    token != null && isTokenExpired(token) ? null : token;

const deserialize = (val: string | null | undefined): Token =>
    val != null ? validateToken(JSON.parse(val).token) : null;

export const TOKEN_LOCAL_STORAGE_NAME = "token";

export const useToken = () => {
    const [token, setToken] = useLocalStorage<Token>({
        key: TOKEN_LOCAL_STORAGE_NAME,
        deserialize: (val) => {
            try {
                return deserialize(val);
            } catch (_) {
                return null;
            }
        },
        serialize: (token) => JSON.stringify({ token }),
    });

    return {
        token,
        setToken,
        removeToken: () => setToken(null),
    };
};
