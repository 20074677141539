import { useEffect, useState } from "react";
import useSWRImmutable from "swr/immutable";

import { NuxType } from "@/conf/nux";
import { useApi } from "@/hooks/use-api";

type UseNux = (nux_id: NuxType) => [boolean, () => Promise<void>];

export const useNux: UseNux = (nux_id) => {
    const [showNux, setShowNux] = useState(false);
    const api = useApi();
    const { data: nux_state, mutate } = useSWRImmutable(
        "nux_state",
        async () => await api.fetch_nux_state(),
    );

    useEffect(() => {
        if (nux_state != null) {
            setShowNux(nux_state.has(nux_id) ? false : true);
        }
    }, [nux_state, setShowNux, nux_id]);

    const dismiss = async () => {
        await api.mark_nux_seen(nux_id);
        mutate((prev) => {
            const next = new Map(prev);
            return next.set(nux_id, new Date().toISOString());
        });
    };

    return [showNux, dismiss];
};
