import { v4 as uuidv4 } from "uuid";

import {
    WebsocketClient,
    WebsocketClientEvent,
    EventHandler,
} from "@/api/ws/websocket-client";
import { OutboundEvent } from "@/api/ws/websocket-outbound-events";
import { InboundWebsocketEvents } from "@/api/ws/websocket-types";

type CallbackFn = () => void;
type HandlerMap = Map<WebsocketClientEvent, Map<string, CallbackFn>>;

export class SubscriptionClient {
    private socket: WebsocketClient;
    private handlerIDs: Set<string> = new Set();
    private socketEventHandler: HandlerMap = new Map();

    public constructor(socket: WebsocketClient) {
        this.socket = socket;
        this.socket.addEventListener(
            WebsocketClientEvent.SOCKET_DISCONNECTED,
            this.handleSocketDisconnected,
        );
        this.socket.addEventListener(
            WebsocketClientEvent.SOCKET_RECONNECTED,
            this.handleSocketReconnected,
        );
        this.socket.addEventListener(
            WebsocketClientEvent.REAUTHENTICATED,
            this.handleSocketReauthenticated,
        );
    }

    public disconnect() {
        this.socket.removeEventListener(
            WebsocketClientEvent.SOCKET_DISCONNECTED,
            this.handleSocketDisconnected,
        );
        this.socket.removeEventListener(
            WebsocketClientEvent.SOCKET_RECONNECTED,
            this.handleSocketReconnected,
        );
        this.socket.removeEventListener(
            WebsocketClientEvent.REAUTHENTICATED,
            this.handleSocketReauthenticated,
        );

        this.handlerIDs.forEach((handler_id) =>
            this.socket.removeHandler(handler_id),
        );
        this.handlerIDs = new Set();
        this.socketEventHandler = new Map();
    }

    public on<Tk extends keyof InboundWebsocketEvents>(
        event: Tk,
        handler: EventHandler<InboundWebsocketEvents[Tk]>,
    ): string {
        const handler_id = this.socket.registerEventHandler(event, handler);
        this.handlerIDs.add(handler_id);
        return handler_id;
    }

    public onIf<Tk extends keyof InboundWebsocketEvents>(
        event: Tk,
        predicate: (body: InboundWebsocketEvents[Tk]) => boolean,
        handler: EventHandler<InboundWebsocketEvents[Tk]>,
    ): string {
        const wrappedHandler: EventHandler<InboundWebsocketEvents[Tk]> = (
            body,
        ) => {
            if (predicate(body)) {
                handler(body);
            }
        };
        const handler_id = this.socket.registerEventHandler(
            event,
            wrappedHandler,
        );
        this.handlerIDs.add(handler_id);
        return handler_id;
    }

    public subscribeToThread(threadID: string): string {
        return this.socket.subscribeToThread(threadID);
    }

    public unsubscribeFromThread(threadID: string, subscriptionID: string) {
        this.socket.unsubscribeFromThread(threadID, subscriptionID);
    }

    public removeHandler(handler_id: string) {
        this.socket.removeHandler(handler_id);
        this.handlerIDs.delete(handler_id);
    }

    public async send(event: OutboundEvent) {
        await this.socket.send(event);
    }

    public registerSocketEvent(
        type: WebsocketClientEvent,
        handler: CallbackFn,
    ): string {
        const handler_id = uuidv4();
        const handler_map = this.socketEventHandler.get(type);
        this.socketEventHandler.set(
            type,
            new Map(handler_map).set(handler_id, handler),
        );
        return handler_id;
    }

    public removeSocketEvent(handler_id: string) {
        this.socketEventHandler.forEach((map) => {
            map.delete(handler_id);
        });
    }

    private handleSocketDisconnected = () => {
        const handler = this.socketEventHandler.get(
            WebsocketClientEvent.SOCKET_DISCONNECTED,
        );
        if (handler) {
            handler.forEach((cb) => cb());
        }
    };

    private handleSocketReconnected = () => {
        const handler = this.socketEventHandler.get(
            WebsocketClientEvent.SOCKET_RECONNECTED,
        );
        if (handler) {
            handler.forEach((cb) => cb());
        }
    };

    private handleSocketReauthenticated = () => {
        const handler = this.socketEventHandler.get(
            WebsocketClientEvent.REAUTHENTICATED,
        );
        if (handler) {
            handler.forEach((cb) => cb());
        }
    };
}
