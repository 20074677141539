import { NavLink, Outlet } from "react-router-dom";

import { Button } from "@/components/ui/button";

export const AdminLayout = () => (
    <div className="container">
        <div className="-mx-4 my-4">
            <NavLink to="/admin/dashboard">
                {({ isActive }) => (
                    <Button variant={isActive ? "secondary" : "link"}>
                        Dashboard
                    </Button>
                )}
            </NavLink>
            <NavLink to="/admin/accounts">
                {({ isActive }) => (
                    <Button variant={isActive ? "secondary" : "link"}>
                        Accounts
                    </Button>
                )}
            </NavLink>
            <NavLink to="/admin/chat-messages">
                {({ isActive }) => (
                    <Button variant={isActive ? "secondary" : "link"}>
                        Chat Messages
                    </Button>
                )}
            </NavLink>
            <NavLink to="/admin/markdown">
                {({ isActive }) => (
                    <Button variant={isActive ? "secondary" : "link"}>
                        Markdown
                    </Button>
                )}
            </NavLink>
        </div>
        <Outlet />
    </div>
);
