import { ReactNode } from "react";
import useSWRImmutable from "swr/immutable";
import { useShallow } from "zustand/react/shallow";

import { DocumentInfo, FindingGroupType, UUID } from "@/api/types";
import { Document } from "@/components/document/document";
import { useApi } from "@/hooks/use-api";
import { useGridView } from "@/hooks/use-grid-view-context";
import { mapMaybeGet } from "@/utils/es6-map";
import { createInitialAsyncFindingGroup } from "@/utils/finding-group";
import { nonNull } from "@/utils/fn";

type Props = {
    id?: UUID;
    fallback?: ReactNode;
};

const REQUIRED_FINDING_GROUP_TYPES = [
    FindingGroupType.executive_summary,
    FindingGroupType.risks,
    FindingGroupType.opportunities,
];

export const DocumentContainer = ({ id, fallback }: Props) => {
    const info = useGridView((s) => mapMaybeGet(s.documents, id));
    const findingGroups = useGridView(
        useShallow((s) =>
            Array.from(
                mapMaybeGet(s.documents_to_finding_groups, id)?.values() ?? [],
            )
                .map((id) => s.finding_groups.get(id))
                .filter((s) => s != null),
        ),
    );
    const groups = REQUIRED_FINDING_GROUP_TYPES.map((type) =>
        findingGroups.find((f) => f.type === type),
    ).filter(nonNull);

    if (info === undefined) return fallback;
    if (groups.length < REQUIRED_FINDING_GROUP_TYPES.length) {
        return <DocumentEnsureFindingGroups info={info} />;
    }
    return <Document info={info} findingGroups={groups} />;
};

const DocumentEnsureFindingGroups = (props: { info: DocumentInfo }) => {
    const reportID = useGridView((s) => s.report.id);
    const api = useApi();
    const addFindingGroup = useGridView((s) => s.addFindingGroup);
    const { data } = useSWRImmutable(
        [reportID, props.info.id, "full_document"],
        async ([messageID, documentID]) =>
            await api.fetch_document(
                messageID,
                documentID,
                REQUIRED_FINDING_GROUP_TYPES,
            ),
        {
            suspense: true,
            onSuccess: (data) => {
                for (const fg of data.finding_groups) {
                    addFindingGroup(fg);
                }
            },
        },
    );

    return (
        <Document
            info={props.info}
            findingGroups={data.finding_groups.map(
                createInitialAsyncFindingGroup,
            )}
        />
    );
};
