import { ChevronDown, ChevronUp } from "lucide-react";
import { useRef, useState } from "react";

import { Markdown, MarkdownProps } from "@/components/markdown";
import { Button } from "@/components/ui/button";
import { useBoolean } from "@/hooks/use-boolean";
import { cn } from "@/lib/utils";

export const CollapsibleMarkdown = (props: MarkdownProps) => {
    const resizeObserver = useRef<ResizeObserver>(
        new ResizeObserver(([entry]) => {
            setIsContentClipped(
                entry.target.scrollHeight > entry.target.clientHeight,
            );
        }),
    );
    const [collapsed, actions] = useBoolean(true);
    const [isContentClipped, setIsContentClipped] = useState(true);

    return (
        <div className={cn("relative", isContentClipped && "pb-4")}>
            <div
                ref={(ref) => {
                    if (ref) {
                        resizeObserver.current.observe(ref);
                        return () => resizeObserver.current.disconnect();
                    }
                }}
                className={cn({
                    "line-clamp-3": collapsed,
                    "[mask-image:linear-gradient(180deg,#000,#000_1rem,transparent_3.5rem,transparent)]":
                        isContentClipped && collapsed,
                })}
            >
                <Markdown {...props} />
            </div>
            {isContentClipped || !collapsed ? (
                <Button
                    variant="ghost"
                    size="xs"
                    onClick={actions.toggle}
                    className={cn(
                        "mt-2 w-full gap-1.5",
                        isContentClipped && "absolute bottom-0 left-0 right-0",
                    )}
                >
                    {collapsed ? (
                        <>
                            <ChevronDown className="size-4" />
                            See More
                        </>
                    ) : (
                        <>
                            <ChevronUp className="size-4" />
                            Show Less
                        </>
                    )}
                </Button>
            ) : null}
        </div>
    );
};
