import { zodResolver } from "@hookform/resolvers/zod";
import { AddressElement } from "@stripe/react-stripe-js";
import {
    StripeAddressElementChangeEvent,
    StripeAddressElementOptions,
} from "@stripe/stripe-js";
import { PropsWithChildren } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { BillingDetailsData } from "@/api/rest";
import { AccountType, BillingAddress, User } from "@/api/types";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { StripeElements } from "@/container/paywall/stripe-elements";
import { UseAsyncState } from "@/hooks/use-async-state";
import { useUser } from "@/hooks/use-user";
import { config } from "@/utils/configuration";

let autocomplete: StripeAddressElementOptions["autocomplete"] = {
    mode: "automatic",
};
if (config.googleMapsApiKey) {
    autocomplete = {
        mode: "google_maps_api",
        apiKey: config.googleMapsApiKey,
    };
}

const formSchema = z.object({
    email: z.string().email(),
    billing_complete: z.literal<boolean>(true, {
        errorMap: () => ({ message: "Please provide a valid billing address" }),
    }),
    billing_details: z.object({
        name: z.string(),
        address: z.object({
            city: z.string(),
            country: z.string(),
            line1: z.string(),
            line2: z.string().nullable(),
            postal_code: z.string().nullable(),
            state: z.string().nullable(),
        }),
    }),
});

const getName = (user: User): string => {
    if (user.account_type === AccountType.organization)
        return user.account_name ?? "";
    return [user.first_name, user.last_name].filter(Boolean).join(" ");
};

type Props = {
    address?: BillingAddress;
    action: UseAsyncState<[BillingDetailsData], unknown>;
};

export const BillingInformationForm = (props: PropsWithChildren<Props>) => {
    const user = useUser();

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: user.username,
            billing_details: {
                name: getName(user),
                address: props.address,
            },
        },
    });

    const onAddressChange = ({
        complete,
        value,
    }: StripeAddressElementChangeEvent) => {
        form.setValue("billing_complete", complete);
        form.setValue("billing_details", value);
        if (complete) {
            form.trigger("billing_complete");
        }
    };

    const onSubmit = form.handleSubmit((data: z.infer<typeof formSchema>) =>
        props.action.submit({
            billing_email: data.email,
            billing_name: data.billing_details.name,
            billing_address: data.billing_details.address,
        }),
    );

    return (
        <StripeElements>
            <Form {...form}>
                <form onSubmit={onSubmit} className="space-y-10">
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Billing email</FormLabel>
                                <FormControl>
                                    <Input type="email" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="billing_details"
                        render={({ field }) => (
                            <FormItem>
                                <AddressElement
                                    onChange={onAddressChange}
                                    options={{
                                        mode: "billing",
                                        defaultValues: field.value,
                                        autocomplete,
                                    }}
                                />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="billing_complete"
                        render={() => (
                            <FormItem>
                                <FormMessage />
                                {props.children}
                            </FormItem>
                        )}
                    />
                </form>
            </Form>
        </StripeElements>
    );
};
