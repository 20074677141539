import { useLDClient } from "launchdarkly-react-client-sdk";
import { PropsWithChildren, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { toast } from "sonner";
import useSWR, { useSWRConfig } from "swr";

import { HttpStatusCode } from "@/api/http-status-codes";
import { BrightwaveAPI, HttpError } from "@/api/rest";
import { User } from "@/api/types";
import {
    WebsocketClient,
    WebsocketClientEvent,
} from "@/api/ws/websocket-client";
import { AppContext } from "@/context/app-context";
import { useDevMode } from "@/hooks/use-dev-mode";
import { TOKEN_LOCAL_STORAGE_NAME, useToken } from "@/hooks/use-token";
import { getCachedWebsocket } from "@/utils/get-cached-websocket";
import { log } from "@/utils/log";
import { getLDContext, isUserSuperAdmin } from "@/utils/user";

export const AppContextProvider = (props: PropsWithChildren) => {
    const [, setDevMode] = useDevMode();
    const { cache } = useSWRConfig();
    const { token, setToken, removeToken } = useToken();
    const api = new BrightwaveAPI(token ?? undefined);
    const [websocket, setWebsocket] = useState<WebsocketClient | null>(null);

    const { data: user, mutate } = useSWR<User | null>(
        [token, "user_info"],
        async ([t]) => {
            if (!t) return null;
            try {
                return await api.fetch_user_info();
            } catch (e) {
                log(e);
                // token invalid, remove from local storage
                if (
                    e instanceof HttpError &&
                    e.status === HttpStatusCode.Unauthorized
                ) {
                    removeToken();
                }
                return null;
            }
        },
        { suspense: true },
    );

    const logout = () => {
        api.logout();
        removeToken();
    };

    // subscribe & unsubscribe to 401 errors and auto-logout
    useEffect(() => api.onNotAuthenticated(removeToken), [api, removeToken]);

    // initialize websocket connection when token is set
    useEffect(() => {
        getCachedWebsocket(token).then(setWebsocket);
    }, [token]);

    // remove token when socket is closed due to auth revocation
    // no need to call logout because token is already invalid
    useEffect(() => {
        if (websocket) {
            websocket.addEventListener(
                WebsocketClientEvent.AUTH_REVOKED,
                removeToken,
            );
            return () => {
                websocket.removeEventListener(
                    WebsocketClientEvent.AUTH_REVOKED,
                    removeToken,
                );
            };
        }
    }, [removeToken, websocket]);

    // close websocket connection when token is removed
    useEffect(() => {
        if (websocket && !token) {
            websocket.disconnect();
        }
    }, [websocket, token]);

    useEffect(() => {
        if (!token) {
            // manually mutate cache because global mutate does not play nice with SWRInfinite
            // Avoiding unstable api: https://swr.vercel.app/docs/pagination.en-US#global-mutate-with-useswrinfinite
            Array.from(cache.keys()).forEach((k) => cache.delete(k));

            // clear other user related local caches
            localStorage.removeItem(TOKEN_LOCAL_STORAGE_NAME);
        }
    }, [token, cache]);

    useHotkeys(
        "mod+shift+d",
        (e) => {
            if (user && isUserSuperAdmin(user)) {
                e.preventDefault();
                setDevMode((dm) => {
                    toast.info(dm ? "DevMode disabled" : "DevMode enabled");
                    return !dm;
                });
            }
        },
        { enableOnFormTags: true },
    );

    // launch darkly context
    const ldClient = useLDClient();
    useEffect(() => {
        ldClient?.identify(
            user ? getLDContext(user) : { kind: "user", anonymous: true },
        );
    }, [user?.id]);

    return (
        <AppContext
            value={{
                api,
                websocket,
                user: user ?? null,
                mutateUser: mutate,
                login: setToken,
                logout,
            }}
        >
            {props.children}
        </AppContext>
    );
};
