import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { SetupIntent } from "@stripe/stripe-js";
import { FormEvent } from "react";
import useSWRImmutable from "swr/immutable";

import { AsyncButton } from "@/components/async-button";
import { ErrorAlert } from "@/components/error-alert";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { StripeElementsForPayments } from "@/container/paywall/stripe-elements";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useUser } from "@/hooks/use-user";
import { getName } from "@/utils/user";

const Form = (props: {
    onSuccess?: (sio: SetupIntent) => void;
    onCancel: () => void;
}) => {
    const user = useUser();
    const stripe = useStripe();
    const elements = useElements();

    const action = useAsyncState(
        async (e: FormEvent) => {
            e.preventDefault();
            if (!stripe || !elements) throw new Error("Not initialized");
            const { setupIntent, error } = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url:
                        window.location.origin + window.location.pathname,
                },
                redirect: "if_required",
            });
            if (error) throw new Error(error.message ?? "Unexpcected error");
            return setupIntent;
        },
        { onSuccess: props.onSuccess },
    );

    return (
        <form onSubmit={action.submit} className="rounded border">
            <div className="space-y-8 p-4">
                {action.error && <ErrorAlert error={action.error} />}
                <PaymentElement
                    options={{
                        defaultValues: {
                            billingDetails: {
                                name: getName(user),
                                email: user.username,
                            },
                        },
                    }}
                />
            </div>
            <div className="flex items-baseline justify-end gap-4 border-t bg-accent p-4">
                <Button type="button" variant="link" onClick={props.onCancel}>
                    Cancel
                </Button>
                <AsyncButton
                    loading={action.isSubmitting}
                    disabled={!stripe || !elements}
                >
                    Add Payment Method
                </AsyncButton>
            </div>
        </form>
    );
};

export const AddPaymentMethodSkeleton = (props: { onCancel: () => void }) => (
    <div className="rounded border">
        <div className="grid grid-cols-2 gap-4 space-y-4 p-4 py-10">
            <Skeleton className="col-span-2 h-10" />
            <Skeleton className="h-10" />
            <Skeleton className="h-10" />
            <Skeleton className="h-10" />
            <Skeleton className="h-10" />
        </div>
        <div className="flex items-center justify-end gap-4 border-t bg-accent p-4">
            <Button type="button" variant="link" onClick={props.onCancel}>
                Cancel
            </Button>
            <Skeleton className="h-10 w-52 bg-black" />
        </div>
    </div>
);

export const AddPaymentMethod = ({
    onSuccess,
    ...props
}: {
    onSuccess?: (sio: SetupIntent) => void;
    onCancel: () => void;
}) => {
    const api = useApi();
    const { data, error, mutate } = useSWRImmutable(
        "payment_method_client_secret",
        async () => await api.fetch_payment_method_client_secret(),
        { suspense: true },
    );
    if (error) return <ErrorAlert error={error} />;

    const successHandler = (sio: SetupIntent) => {
        mutate(); // get new client secret
        onSuccess?.(sio);
    };

    return (
        <StripeElementsForPayments clientSecret={data}>
            <Form {...props} onSuccess={successHandler} />
        </StripeElementsForPayments>
    );
};
