import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { AsyncButton } from "@/components/async-button";
import {
    PasswordStrengthLabels,
    PasswordStrengthProgress,
} from "@/components/password-strength";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseAsyncState } from "@/hooks/use-async-state";
import { passwordRequirements } from "@/utils/password";

type Props = {
    action: UseAsyncState<[string], unknown>;
};

const formSchema = z
    .object({
        password: z
            .string()
            .min(6, "Password does not satify all requirements"),
    })
    .superRefine(({ password }, checkPassComplexity) => {
        if (!passwordRequirements.every((req) => req.re.test(password))) {
            checkPassComplexity.addIssue({
                path: ["new_password"],
                code: z.ZodIssueCode.custom,
                message: "Password does not satify all requirements",
            });
        }
    });

export const ResetPasswordForm = (props: Props) => {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            password: "",
        },
    });

    const onSubmit = (values: z.infer<typeof formSchema>) => {
        props.action.submit(values.password);
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>New Password</FormLabel>
                            <FormControl>
                                <Input type="password" {...field} />
                            </FormControl>
                            <FormMessage />
                            <PasswordStrengthProgress
                                value={field.value}
                                requirements={passwordRequirements}
                                className="pt-2"
                            />
                            {field.value.length > 0 && (
                                <PasswordStrengthLabels
                                    value={field.value}
                                    requirements={passwordRequirements}
                                    className="pt-2"
                                />
                            )}
                        </FormItem>
                    )}
                />
                <AsyncButton
                    className="w-full"
                    type="submit"
                    loading={props.action.isSubmitting}
                >
                    Set New Password
                </AsyncButton>
            </form>
        </Form>
    );
};
