import { createContext } from "react";

import { UUID } from "@/api/types";

type TDocumentLinkContext = {
    onNavigate: (documentID: UUID, urlPrefix?: string) => void;
};

export const DocumentLinkContext = createContext<TDocumentLinkContext | null>(
    null,
);
