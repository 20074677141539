import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowRight } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { InviteAcceptance } from "@/api/rest";
import { InviteInfo, InviteType } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import {
    PasswordStrengthLabels,
    PasswordStrengthProgress,
} from "@/components/password-strength";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseAsyncState, useAsyncState } from "@/hooks/use-async-state";
import { passwordRequirements } from "@/utils/password";

const assertInviteType = (val: string | null): InviteType => {
    if (val != null && Object.keys(InviteType).includes(val))
        return val as InviteType;
    throw new Error("Invalid invite type");
};

const formSchema = z
    .object({
        username: z.string().email(),
        code: z
            .string()
            .min(8, "Must be 8 characters long")
            .max(8, "Must be 8 characters long"),
        password: z
            .string()
            .min(6, "Password does not satify all requirements"),
        invite_type: z.nativeEnum(InviteType),
    })
    .superRefine(({ password }, checkPassComplexity) => {
        if (!passwordRequirements.every((req) => req.re.test(password))) {
            checkPassComplexity.addIssue({
                path: ["password"],
                code: z.ZodIssueCode.custom,
                message: "Password does not satify all requirements",
            });
        }
    });

type Props = {
    inviteInfo: InviteInfo;
    action: UseAsyncState<[InviteAcceptance], unknown>;
};

export const SignupForm = (props: Props) => {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: props.inviteInfo.username,
            code: props.inviteInfo.code,
            password: "",
            invite_type: assertInviteType(props.inviteInfo.invite_type),
        },
    });

    const action = useAsyncState((values: z.infer<typeof formSchema>) =>
        props.action.submit({ ...values }),
    );

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(action.submit)}
                className="space-y-8"
            >
                <FormField
                    control={form.control}
                    name="username"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <Input type="email" {...field} disabled />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Password</FormLabel>
                            <FormControl>
                                <Input type="password" {...field} />
                            </FormControl>
                            <FormMessage />
                            <PasswordStrengthProgress
                                value={field.value}
                                requirements={passwordRequirements}
                                className="pt-2"
                            />
                            {field.value.length > 0 && (
                                <PasswordStrengthLabels
                                    value={field.value}
                                    requirements={passwordRequirements}
                                    className="pt-2"
                                />
                            )}
                        </FormItem>
                    )}
                />
                <AsyncButton
                    className="w-full"
                    type="submit"
                    variant="primary"
                    loading={action.isSubmitting}
                >
                    Create Account
                    <ArrowRight className="ml-2 size-4" />
                </AsyncButton>
            </form>
        </Form>
    );
};
