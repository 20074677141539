import { ReactNode } from "react";

import { ConfirmModal } from "@/components/modals/confirm";
import { Button, ButtonProps } from "@/components/ui/button";
import { UseAsyncState, useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";

interface ButtonWithConfirmationProps extends ButtonProps {
    title?: string;
    message: ReactNode;
    action: UseAsyncState<[], unknown>;
    confirmButtonVariant?: ButtonProps["variant"];
}

export const ButtonWithConfirmation = ({
    title,
    message,
    action,
    confirmButtonVariant,
    ...props
}: ButtonWithConfirmationProps) => {
    const [confirm, { open, close }] = useBoolean();
    const asyncAction = useAsyncState(action.submit, { onSuccess: close });
    return (
        <>
            <Button {...props} onClick={open} />
            <ConfirmModal
                opened={confirm}
                onClose={close}
                title={title}
                message={message}
                action={asyncAction}
                confirmButtonVariant={confirmButtonVariant}
            />
        </>
    );
};
