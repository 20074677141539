import { cva, VariantProps } from "class-variance-authority";
import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

const variants = cva("rounded px-2 py-1 text-xs", {
    variants: {
        variant: {
            default: "bg-zinc-100 text-zinc-400 dark:bg-zinc-900",
            loading: "bg-zinc-50 text-zinc-500 dark:bg-zinc-950/50",
            error: "bg-red-50 text-red-400 dark:bg-red-950/50 dark:text-red-800",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});

interface Props
    extends HTMLAttributes<HTMLSpanElement>,
        VariantProps<typeof variants> {}

export const CellState = ({ variant, className, ...props }: Props) => (
    <span className={cn(variants({ variant }), className)} {...props} />
);
