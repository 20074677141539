import { Slot } from "@radix-ui/react-slot";
import { VariantProps, cva } from "class-variance-authority";
import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export const CheckoutCardTitle = ({
    children,
    className,
    ...props
}: HTMLAttributes<HTMLParagraphElement>) => (
    <p
        {...props}
        className={cn("px-4 pt-2 font-headline text-lg font-medium", className)}
    >
        {children}
    </p>
);

interface ContentProps extends HTMLAttributes<HTMLDivElement> {
    asChild?: boolean;
}

export const CheckoutCardContent = ({
    children,
    className,
    asChild = false,
    ...props
}: ContentProps) => {
    const Comp = asChild ? Slot : "div";
    return (
        <Comp {...props} className={cn("grow px-4 pb-2", className)}>
            {children}
        </Comp>
    );
};

export const CheckoutCardFooter = ({
    children,
    className,
    ...props
}: HTMLAttributes<HTMLParagraphElement>) => (
    <p
        {...props}
        className={cn("border-t bg-accent px-4 py-2 text-sm", className)}
    >
        {children}
    </p>
);

const cardVariants = cva(
    "flex flex-col rounded border bg-background space-y-2",
    {
        variants: {
            variant: {
                default: "",
                selected: "ring-2 ring-slate-500 ring-offset-2",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    },
);

interface CardProps
    extends HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof cardVariants> {}

export const CheckoutCard = ({
    variant,
    children,
    className,
    ...props
}: CardProps) => (
    <div {...props} className={cn(cardVariants({ variant, className }))}>
        {children}
    </div>
);
