import { useState } from "react";

export const useBoolean = (initialValue: boolean = false) => {
    const [value, setValue] = useState(initialValue);

    return [
        value,
        {
            open: () => setValue(true),
            close: () => setValue(false),
            set: (value: boolean) => setValue(value),
            toggle: () => setValue((prev) => !prev),
        },
    ] as const;
};
