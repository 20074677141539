import { ChevronUp, ChevronDown } from "lucide-react";
import { Children, HTMLAttributes, useRef, useState } from "react";

import { Button } from "@/components/ui/button";
import { useBoolean } from "@/hooks/use-boolean";
import { cn } from "@/lib/utils";

interface Props extends HTMLAttributes<HTMLUListElement> {
    maxLines?: number;
}

export const CollapsibleList = ({
    maxLines = 3,
    children,
    className,
    ...props
}: Props) => {
    const childArr = Children.toArray(children);
    const [overflowX, setOverflowX] = useState(false);
    const overflowY = childArr.length > maxLines;
    const isExpandable = overflowY || overflowX;

    const [collapsed, actions] = useBoolean(true);
    const resizeObserver = useRef<ResizeObserver>(
        new ResizeObserver(([entry]) => {
            setOverflowX(entry.target.scrollWidth > entry.target.clientWidth);
        }),
    );

    const numDisplay = collapsed ? maxLines - 1 : childArr.length;
    const numMoreItems = childArr.length - numDisplay;

    const button = (
        <Button
            variant="ghost"
            size="xs"
            className="w-full gap-1.5"
            onClick={actions.toggle}
        >
            {collapsed ? (
                <>
                    <ChevronDown className="size-4" />
                    See More
                    {numMoreItems > 0 ? ` (+${numMoreItems})` : null}
                </>
            ) : (
                <>
                    <ChevronUp className="size-4" />
                    Show Less
                </>
            )}
        </Button>
    );

    return (
        <>
            <ul
                ref={(ref) => {
                    if (ref) {
                        resizeObserver.current.observe(ref);
                        return () => resizeObserver.current.disconnect();
                    }
                }}
                className={cn(
                    {
                        "whitespace-nowrap [mask-image:linear-gradient(90deg,#000_95%,transparent)]":
                            collapsed,
                        "[&_li:last-child]:h-0 [&_li:last-child]:[clip-path:content-box]":
                            childArr.length > 1 && collapsed && isExpandable,
                    },
                    className,
                )}
                {...props}
            >
                {childArr.slice(0, maxLines)}
            </ul>
            {isExpandable || !collapsed ? button : null}
        </>
    );
};
