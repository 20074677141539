import { EventHandler, SyntheticEvent } from "react";

export const findParentWithOverflowHidden = (
    el: HTMLElement | null,
): HTMLElement | null => {
    while (el !== null) {
        const styles = window.getComputedStyle(el);
        if (
            el === document.documentElement ||
            styles.getPropertyValue("overflow") !== "visible"
        ) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
};

export const stopPropagation = (e: Event | SyntheticEvent) =>
    e.stopPropagation();

export const wrapStopPropagation =
    (handler: EventHandler<SyntheticEvent>): EventHandler<SyntheticEvent> =>
    (e) => {
        e.stopPropagation();
        handler(e);
    };

export const preventDefault = (e: SyntheticEvent | Event) => e.preventDefault();

export const wrapPreventDefault =
    (
        handler: EventHandler<SyntheticEvent> | undefined,
    ): EventHandler<SyntheticEvent> =>
    (e) => {
        e.preventDefault();
        handler?.(e);
    };
