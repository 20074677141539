import { PropsWithChildren } from "react";
import useSWR from "swr";

import { ManageUserContext } from "@/context/manage-user-context";
import { useApi } from "@/hooks/use-api";

export const ManageUserContextProvider = (props: PropsWithChildren) => {
    const api = useApi();
    const { data: settings } = useSWR(
        ["account_settings"],
        async () => api.fetch_account_settings(),
        { suspense: true },
    );

    const activeUserCount = useSWR(
        ["active_user_count"],
        async () =>
            (
                await api.fetch_account_users({
                    active: true,
                    pagination: { size: 1 },
                })
            ).count,
        { suspense: true },
    );

    return (
        <ManageUserContext value={{ settings, activeUserCount }} {...props} />
    );
};
