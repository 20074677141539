import { useFavicon } from "@/hooks/use-favicon";
import { config } from "@/utils/configuration";

const getFavicon = (): string => {
    if (config.isDev) return "/logo_dev.svg";
    if (config.env === "staging") return "/logo_staging.svg";
    return "/logo_adaptive.svg";
};

export const Favicon = () => {
    useFavicon(getFavicon());
    return null;
};
