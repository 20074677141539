import { Table } from "@tanstack/react-table";
import { Plus, X } from "lucide-react";
import { HTMLAttributes, useEffect } from "react";

import { Column } from "@/api/types";
import { AddFilter } from "@/components/document-table/filter/add-filter";
import { BooleanColumnFilter } from "@/components/document-table/filter/boolean";
import { CategoricalColumnFilter } from "@/components/document-table/filter/categorical";
import { NumericalColumnFilter } from "@/components/document-table/filter/numerical";
import { TextColumnFilter } from "@/components/document-table/filter/text";
import { useFilter } from "@/components/document-table/filter/use-filter";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { Button } from "@/components/ui/button";
import { useGridView } from "@/hooks/use-grid-view-context";
import { cn } from "@/lib/utils";
import { arr_partition } from "@/utils/collection";
import { getColumId } from "@/utils/columns";
import { FilterType, getColumnFilterType } from "@/utils/filter";

interface Props extends HTMLAttributes<HTMLDivElement> {
    table: Table<DocumentWithAsyncFindings>;
}

export const FilterContent = ({ table, className, ...props }: Props) => {
    const isOpen = useFilter((s) => s.isOpen);
    const toggleFilters = useFilter((s) => s.toggle);
    const openFilter = useFilter((s) => s.openFilter);
    const columns = useGridView((s) => s.columns);
    const filters = table.getState().columnFilters;
    const filterIDs = new Set(filters.map((f) => f.id));

    const [applied, available] = arr_partition(columns, (col) =>
        filterIDs.has(getColumId(col)),
    );

    const clearAll = () => {
        table.resetColumnFilters();
        toggleFilters(false);
    };

    useEffect(() => {
        if (isOpen && filters.length === 0) {
            toggleFilters(false);
        }
    }, [isOpen, filters.length, toggleFilters]);

    if (!isOpen) {
        return null;
    }
    return (
        <div className={cn("flex items-center gap-3", className)} {...props}>
            {applied.map((column) => (
                <ColumnFilter
                    key={getColumId(column)}
                    column={column}
                    table={table}
                    open={openFilter === getColumId(column)}
                />
            ))}
            {available.length > 0 && (
                <AddFilter table={table}>
                    <Button variant="secondary" className="gap-2 rounded-full">
                        <Plus className="size-4" />
                        Add Filter
                    </Button>
                </AddFilter>
            )}
            <span className="grow" />
            {filters.length > 0 && (
                <Button variant="link" className="gap-2" onClick={clearAll}>
                    <X className="size-4" /> Clear All Filters
                </Button>
            )}
        </div>
    );
};

const ColumnFilter = (props: {
    column: Column;
    table: Table<DocumentWithAsyncFindings>;
    open?: boolean;
}) => {
    const filter_type = getColumnFilterType(props.column);
    switch (filter_type) {
        case FilterType.boolean:
            return <BooleanColumnFilter {...props} />;
        case FilterType.text:
            return <TextColumnFilter {...props} />;
        case FilterType.numerical:
            return <NumericalColumnFilter {...props} />;
        case FilterType.categorical:
            return <CategoricalColumnFilter {...props} />;
        case FilterType.none:
            return null;
        default:
            return filter_type satisfies never;
    }
};
