import { HTMLAttributes } from "react";

import { ActionConfig, ActionLaunchStatus } from "@/api/types";
import { ActionIcon } from "@/components/action-message/action-icon";
import { BetaBadge } from "@/components/beta-badge";
import { Badge } from "@/components/ui/badge";
import { useUser } from "@/hooks/use-user";
import { cn } from "@/lib/utils";
import { getActionsForUser } from "@/utils/actions";

const Card = ({ config }: { config: ActionConfig }) => (
    <div className="space-y-4 rounded bg-background p-4 shadow">
        <div className="flex items-center gap-2 font-headline text-sm font-bold">
            <ActionIcon type={config.type} />
            {config.label}
            <span className="grow" />
            {config.launchStatus === ActionLaunchStatus.BETA && <BetaBadge />}
            {config.launchStatus === ActionLaunchStatus.SUPERADMIN && (
                <Badge
                    variant="outline"
                    className="border-destructive text-destructive"
                >
                    INTERNAL
                </Badge>
            )}
        </div>
        <p>{config.description}</p>
    </div>
);

export const DiscoverNullstate = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => {
    const user = useUser();
    const actionConfigs = getActionsForUser(user);

    return (
        <div className={cn("space-y-3 p-6", className)} {...props}>
            <h3 className="pb-8 font-headline text-4xl font-bold">
                Deepen Your
                <br />
                Understanding
            </h3>
            <p>Here are some of the things you can do:</p>
            {actionConfigs
                .filter(
                    (config) =>
                        config.description !== undefined &&
                        config.launchStatus !== ActionLaunchStatus.SUPERADMIN,
                )
                .map((config) => (
                    <Card key={config.type} config={config} />
                ))}
        </div>
    );
};
