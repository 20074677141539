import { OnboardingStatusEnum, Sorting, User } from "@/api/types";
import { AdminTable, TableHeader } from "@/components/admin/admin-table";
import { ButtonWithConfirmation } from "@/components/admin/button-with-confirmation";
import { CopyToClipboard } from "@/components/admin/copy-to-clipboard";
import { OnboardingStatus } from "@/components/admin/onboarding-status";
import { UserTypeBadge } from "@/components/admin/user-type-badge";
import { TableCell, TableRow } from "@/components/ui/table";
import { InvitationCodesContainer } from "@/container/admin/invitation-codes";
import { UseAsyncState, useAsyncState } from "@/hooks/use-async-state";
import { useUser } from "@/hooks/use-user";
import { formatDateTime } from "@/utils/time";
import { getName, getOnboardingStatus } from "@/utils/user";

type UsersTableProps = {
    users: User[];
    isAccountOnboarded: boolean;
    hideID?: boolean;
    sortedBy?: Sorting | null;
    onSort?: (sortedBy: Sorting | null) => void;
    onDeactivateUser: UseAsyncState<[User], unknown>;
};

interface UserTableRowProps extends UsersTableProps {
    user: User;
}

const tableHeader: TableHeader[] = [
    { key: "username", label: "User", sortable: true },
    { key: "id", label: "ID" },
    { key: "created_at", label: "Created At", sortable: true },
    { key: "type", label: "Type" },
    { key: "status", label: "Status", sortable: true },
    { key: "action", label: null },
];

const UserTableRow = ({ user, ...props }: UserTableRowProps) => {
    const currentUser = useUser();
    const onboarding_status = getOnboardingStatus(user);
    const canDeactivate =
        props.isAccountOnboarded &&
        onboarding_status !== OnboardingStatusEnum.deactivated &&
        currentUser.id !== user.id;
    const canInviteUser =
        props.isAccountOnboarded &&
        onboarding_status === OnboardingStatusEnum.created;
    const deactivateAction = useAsyncState(
        async () => await props.onDeactivateUser.submit(user),
    );
    return (
        <TableRow>
            <TableCell>
                <CopyToClipboard
                    className="font-medium"
                    value={user.username}
                />
                <p className="text-xs text-gray-500">{getName(user)}</p>
            </TableCell>
            {!props.hideID && (
                <TableCell className="font-mono text-xs text-gray-500">
                    <CopyToClipboard value={user.id} />
                </TableCell>
            )}
            <TableCell>{formatDateTime(user.created_at)}</TableCell>
            <TableCell>
                <UserTypeBadge type={user.user_type} />
            </TableCell>
            <TableCell>
                <OnboardingStatus entity={user} />
            </TableCell>
            <TableCell className="flex justify-end">
                {canDeactivate && (
                    <ButtonWithConfirmation
                        message="Are you sure you want to deactivate this user?"
                        action={deactivateAction}
                        variant="link"
                        className="text-red-500"
                    >
                        Deactivate
                    </ButtonWithConfirmation>
                )}
                {canInviteUser && <InvitationCodesContainer user={user} />}
            </TableCell>
        </TableRow>
    );
};

export const UsersTable = (props: UsersTableProps) => {
    const th = tableHeader.filter((s) =>
        props.hideID ? s.key !== "id" : true,
    );

    return (
        <AdminTable
            header={th}
            sortedBy={props.sortedBy}
            onSort={props.onSort}
            className="border"
        >
            {props.users.length === 0 && (
                <TableRow>
                    <TableCell colSpan={th.length} className="text-sm italic">
                        No users found
                    </TableCell>
                </TableRow>
            )}
            {props.users.map((user) => (
                <UserTableRow key={user.id} user={user} {...props} />
            ))}
        </AdminTable>
    );
};
