export type PasswordRequirementType = {
    re: RegExp;
    label: string;
};

export const passwordRequirements: PasswordRequirementType[] = [
    { re: /[0-9]/, label: "Includes number" },
    { re: /[a-z]/, label: "Includes lowercase letter" },
    { re: /[A-Z]/, label: "Includes uppercase letter" },
    { re: /[$&+,:;=?@#|'<>.^*()%!-"]/, label: "Includes special character" },
];

export const validPassword = (
    requirements: PasswordRequirementType[],
    value: string,
): boolean => {
    const unmetRequirement = requirements.find((req) => !req.re.test(value));
    return unmetRequirement === undefined;
};

const add = (a: number, b: number): number => a + b;

export const getStrength = (
    requirements: PasswordRequirementType[],
    password: string,
) => {
    const multiplier = requirements
        .map((requirement) => (requirement.re.test(password) ? 0 : 1))
        .reduce(add, password.length > 5 ? 0 : 1);

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
};
