export const isTokenExpired = (token_str: string): boolean => {
    const exp = getExpirationFromJWT(token_str);

    // If there's no expiration information, assume it's expired for safety
    if (!exp) {
        return true;
    }

    // Convert the current time to a UNIX timestamp (in seconds)
    const currentTime = Math.floor(Date.now() / 1000);

    // Compare the expiration timestamp with the current time
    return exp <= currentTime;
};

export const getExpirationFromJWT = (token_str: string): number | null => {
    try {
        // Split token into parts [header, payload, signature]
        const parts = token_str.split(".");
        if (parts.length !== 3) {
            throw new Error("Invalid token format");
        }

        // Base64Url decode the payload
        const payload = JSON.parse(
            atob(parts[1].replace("-", "+").replace("_", "/")),
        );

        return payload.exp || null;
    } catch (e) {
        console.error("Error parsing JWT:", e);
        return null;
    }
};
