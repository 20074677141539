import { formatISO } from "date-fns";
import { KeyedMutator } from "swr";
import { v4 as uuidv4 } from "uuid";
import { createStore } from "zustand";

import { BrightwaveAPI } from "@/api/rest";
import {
    APAction,
    APActionBase,
    APActionChunk,
    APActionStatus,
    APActionStatusUpdate,
    APActionType,
    DiscoverPanelStatus,
    MessageRole,
    PageInfo,
    UUID,
} from "@/api/types";
import { WebsocketClient } from "@/api/ws/websocket-client";
import { actionSend } from "@/api/ws/websocket-outbound-events";
import { reduceActionChunk } from "@/utils/actions";
import { dropLast } from "@/utils/collection";
import { toTuple } from "@/utils/entity";
import { mapDelete, mapSet, mapUpdateIfExists } from "@/utils/es6-map";
import { setAdd, setRemove, setToggle } from "@/utils/es6-set";

interface Dependencies {
    api: BrightwaveAPI;
    pageSize?: number;
    mutateConversationID: KeyedMutator<UUID>;
}

interface Props {
    messageID: UUID;
    conversationID: UUID;
    websocket: WebsocketClient | null;
    isActionPanelOpen?: boolean;
}

interface SendActionPayload {
    message: string;
    selected_text: string;
    response_to: string;
    context?: string[];
}

interface Actions {
    send: (type: APActionType, payload: Partial<SendActionPayload>) => void;

    setWebsocket: (ws: WebsocketClient | null) => void;
    refreshConversationID: () => Promise<void>;
    fetchMoreActions: () => Promise<void>;

    actionAck: (action: APActionBase) => void;
    actionStatusUpdate: (action: APActionStatusUpdate) => void;
    actionChunk: (action: APActionChunk) => void;
    actionStreamComplete: (action: APAction) => void;
    actionUpdate: (action: APAction) => void;
    actionSuccess: (action: APAction) => void;
    actionError: (action: APActionBase) => void;

    removeAction: (id: UUID) => void;

    collapseAction: (id: UUID) => void;
    expandAction: (id: UUID) => void;

    toggleActionsFilter: (type: APActionType) => void;
    clearFilter: () => void;

    actionPanelOpen: () => void;
    actionPanelClose: () => void;
}

interface State extends Props, Actions {
    pageSize: number;
    historyCount: number;
    historyFetched: number;
    status: DiscoverPanelStatus;

    actionsFilter: Set<APActionType>;

    actions: Map<UUID, APActionBase>;
    collapsedActions: Set<UUID>;
    error: Error | null;
    isLoading: boolean;
    pageInfo: PageInfo | undefined;
}

export type ActionsStore = ReturnType<typeof createActionsStore>;

export const createActionsStore = ({
    api,
    mutateConversationID,
    pageSize = 10,
    ...props
}: Props & Dependencies) =>
    createStore<State>((set, get) => ({
        ...props,

        pageSize,
        historyCount: 0,
        historyFetched: 0,
        status: DiscoverPanelStatus.IDLE,

        actionsFilter: new Set(),

        actions: new Map(),
        collapsedActions: new Set(),
        error: null,
        isLoading: false,
        pageInfo: undefined,

        send(type, payload) {
            const id = uuidv4();
            const { websocket, conversationID, messageID } = get();
            set((state) => ({
                isActionPanelOpen: true,
                actionsFilter: new Set(),
                status: DiscoverPanelStatus.ACTION_SENT,
                actions: mapSet(state.actions, id, {
                    message_id: messageID,
                    conversation_id: conversationID,
                    id,
                    type,
                    role: MessageRole.user,
                    content: payload.message ?? payload.selected_text ?? "",
                    status: APActionStatus.pending,
                    response_to: payload.response_to,
                    created_at: formatISO(new Date()),
                }),
            }));
            websocket?.send(
                actionSend({
                    id,
                    type,
                    conversation_id: conversationID,
                    message_id: messageID,
                    ...payload,
                }),
            );
        },

        setWebsocket(websocket) {
            set({ websocket });
        },

        async refreshConversationID() {
            set({
                actions: new Map(),
                isLoading: false,
                pageInfo: undefined,
                conversationID: await mutateConversationID(
                    api.admin_new_default_conversation(get().messageID),
                ),
            });
        },

        async fetchMoreActions() {
            const { pageInfo, messageID, conversationID, pageSize, isLoading } =
                get();
            if (isLoading || pageInfo?.has_next_page === false) return;
            set({ isLoading: true });
            try {
                const isFirstPage = pageInfo === undefined;
                const pagination = {
                    size: pageSize,
                    cursor: pageInfo?.end_cursor,
                };
                const page = await api.fetch_action_history(
                    messageID,
                    conversationID,
                    pagination,
                );
                set((state) => ({
                    isLoading: false,
                    historyCount: isFirstPage ? page.count : state.historyCount,
                    historyFetched: state.historyFetched + page.items.length,
                    pageInfo: page.page_info,
                    actions: new Map([
                        ...page.items.reverse().map(toTuple),
                        ...state.actions.entries(),
                    ]),
                    collapsedActions: new Set([
                        ...state.collapsedActions.values(),
                        ...(isFirstPage
                            ? dropLast(page.items.map((i) => i.id))
                            : page.items.map((i) => i.id)),
                    ]),
                }));
            } catch (e) {
                set({
                    isLoading: false,
                    error: e instanceof Error ? e : Error(String(e)),
                });
            }
        },

        actionAck(action) {
            set((state) => ({
                status: DiscoverPanelStatus.RESPONSE_PENDING,
                actions: mapSet(state.actions, action.id, action),
            }));
        },

        actionStatusUpdate(action: APActionBase) {
            set((state) => ({
                actions: mapSet(state.actions, action.id, action),
            }));
        },

        actionChunk(chunk) {
            set((state) => {
                const updated = reduceActionChunk(
                    state.actions.get(chunk.id),
                    chunk,
                );
                if (updated != undefined) {
                    return {
                        status: DiscoverPanelStatus.RESPONDING,
                        actions: new Map(state.actions).set(
                            updated.id,
                            updated,
                        ),
                    };
                }
                return {};
            });
        },

        actionStreamComplete(action) {
            set((state) => ({
                actions: mapSet(state.actions, action.id, {
                    ...action,
                    status: APActionStatus.stream_complete,
                }),
            }));
        },

        actionSuccess(action) {
            set((state) => ({
                status: DiscoverPanelStatus.IDLE,
                actions: mapSet(state.actions, action.id, action),
            }));
        },

        actionUpdate(action) {
            set((state) => ({
                actions: mapUpdateIfExists(
                    state.actions,
                    action.id,
                    () => action,
                ),
            }));
        },

        actionError(action) {
            set((state) => ({
                status: DiscoverPanelStatus.IDLE,
                actions: mapSet(state.actions, action.id, action),
            }));
        },

        removeAction(id) {
            set((state) => ({ actions: mapDelete(state.actions, id) }));
        },

        collapseAction(id) {
            set((state) => ({
                collapsedActions: setAdd(state.collapsedActions, id),
            }));
        },

        expandAction(id) {
            set((state) => ({
                collapsedActions: setRemove(state.collapsedActions, id),
            }));
        },

        toggleActionsFilter(type) {
            set((state) => ({
                actionsFilter: setToggle(state.actionsFilter, type),
            }));
        },

        clearFilter() {
            set({ actionsFilter: new Set() });
        },

        actionPanelOpen() {
            set({ isActionPanelOpen: true });
        },

        actionPanelClose() {
            set({ isActionPanelOpen: false });
        },
    }));
