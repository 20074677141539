import { useEffect, useState } from "react";

export const useNetwork = () => {
    const [isOnline, setOnline] = useState(() => window.navigator.onLine);

    useEffect(() => {
        const onlineListener = () => setOnline(true);
        const offlineListener = () => setOnline(false);

        window.addEventListener("online", onlineListener);
        window.addEventListener("offline", offlineListener);

        return () => {
            window.removeEventListener("online", onlineListener);
            window.removeEventListener("offline", offlineListener);
        };
    });

    return isOnline;
};
