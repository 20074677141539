import { Table } from "@tanstack/react-table";

import { Column } from "@/api/types";
import { FilterPopover } from "@/components/document-table/filter/filter-popover";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { Slider } from "@/components/ui/slider";
import { getColumId } from "@/utils/columns";
import { getNumericalFilterRange } from "@/utils/filter";
import { numberToString } from "@/utils/math";

type Props = {
    column: Column;
    table: Table<DocumentWithAsyncFindings>;
    open?: boolean;
};

export const NumericalColumnFilter = (props: Props) => {
    const columnId = getColumId(props.column);
    const column = props.table.getColumn(columnId);
    const [minValue, maxValue] = getNumericalFilterRange(columnId, column);

    const clearFilter = () => column?.setFilterValue(undefined);
    const handleChange = ([min, max]: number[]) =>
        column?.setFilterValue({ min, max });
    const filterValue = column?.getFilterValue() as
        | { min: number; max: number }
        | undefined;

    const value =
        filterValue !== undefined
            ? [filterValue.min, filterValue.max]
            : [minValue, maxValue];

    const filterBadge =
        filterValue !== undefined ? (
            <span className="rounded-full bg-muted px-2.5 py-1.5 text-xs transition-colors empty:hidden group-hover:bg-background">
                {numberToString(filterValue.min)}
                {" – "}
                {numberToString(filterValue.max)}
            </span>
        ) : undefined;

    // no fitler values or only one value found -> can't filter by this
    if (
        (minValue === -Infinity && maxValue === Infinity) ||
        minValue === maxValue
    ) {
        return (
            <FilterPopover column={props.column} onClear={clearFilter}>
                Column does not contain values to filter
            </FilterPopover>
        );
    }

    return (
        <FilterPopover
            column={props.column}
            onClear={clearFilter}
            filterValue={filterBadge}
        >
            <div className="p-4">
                <Slider
                    value={value}
                    defaultValue={[minValue, maxValue]}
                    min={minValue}
                    max={maxValue}
                    onValueChange={handleChange}
                />
            </div>
            <div className="flex items-center justify-between gap-4 p-2 text-sm">
                <p>{numberToString(value[0])}</p>
                <p>{numberToString(value[1])}</p>
            </div>
        </FilterPopover>
    );
};
