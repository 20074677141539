import {
    format,
    formatDistanceToNow,
    formatRelative,
    isToday,
    isYesterday,
    parseISO,
} from "date-fns";

const getFormattedNumber = (value: number, unit: string): string | null =>
    value > 0 ? `${value}${unit}` : null;

export const formatDuration = (value: number | string): string => {
    if (typeof value === "string") {
        value = parseInt(value, 10);
    }
    if (value < 1000) return `0s`;

    value /= 1000; // convert to seconds

    const days = Math.floor(value / 86400);
    value -= days * 86400;
    const hours = Math.floor(value / 3600);
    value -= hours * 3600;
    const min = Math.floor(value / 60);
    value -= min * 60;
    const sec = Math.floor(value);

    return [
        getFormattedNumber(days, "d"),
        getFormattedNumber(hours, "h"),
        getFormattedNumber(min, "m"),
        getFormattedNumber(sec, "s"),
    ]
        .filter((s) => s != null) // filter nulls
        .slice(0, 2) // only take first two values for precision
        .join(" ");
};

const maybeParse = (date: Date | string): Date =>
    typeof date === "string" ? parseISO(date) : date;

export const formatDateTime = (date: Date | string): string =>
    format(maybeParse(date), "LLL dd, y 'at' p");

export const formatDate = (date: Date | string): string =>
    format(maybeParse(date), "LLL dd, y");

export const formatRelativeDateTime = (date: Date | string): string => {
    if (isToday(date)) return formatDistanceToNow(date, { addSuffix: true });
    if (isYesterday(date)) return formatRelative(date, new Date());
    return formatDateTime(date);
};
