import { ReactNode, use } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import { InviteAcceptance } from "@/api/rest";
import { InviteCodeStatus, InviteInfo, InviteType } from "@/api/types";
import { ErrorAlert } from "@/components/error-alert";
import { SignupForm } from "@/components/forms/signup";
import { Button } from "@/components/ui/button";
import { AppContext } from "@/context/app-context";
import { useAsyncState } from "@/hooks/use-async-state";
import { usePageTitle } from "@/hooks/use-page-title";
import { notEmptyOrNull } from "@/utils/string-helpers";

const useInviteInfo = (): InviteInfo | undefined => {
    const [params] = useSearchParams();
    const username = params.get("email");
    const code = params.get("code");
    const invite_type = params.get("invite_type");
    if (
        notEmptyOrNull(username) &&
        notEmptyOrNull(code) &&
        notEmptyOrNull(invite_type) &&
        invite_type in InviteType
    ) {
        return {
            username,
            code,
            invite_type: InviteType[invite_type as keyof typeof InviteType],
        };
    }
    return undefined;
};

const getSubject = (invite_type: InviteType | undefined): ReactNode => {
    switch (invite_type) {
        case InviteType.user:
            return "your account administrator";
        case InviteType.account:
            return (
                <a
                    href="mailto:support@brightwave.io"
                    className="underline underline-offset-4"
                >
                    support@brightwave.io
                </a>
            );
        default:
            return (
                <>
                    {getSubject(InviteType.user)}
                    {" or "}
                    {getSubject(InviteType.account)}
                </>
            );
    }
};

const getHeadline = (
    status: Exclude<InviteCodeStatus, InviteCodeStatus.valid>,
): string => {
    switch (status) {
        case InviteCodeStatus.accepted:
        case InviteCodeStatus.user_already_onboarded:
        case InviteCodeStatus.account_already_onboarded:
            return "Invite Already Accepted";
        case InviteCodeStatus.invalid:
            return "Invalid Invite Link";
        case InviteCodeStatus.expired:
            return "Invite Expired";
        case InviteCodeStatus.canceled:
            return "Invite Canceled";
        case InviteCodeStatus.new_code_created:
            return "Newer Invite Link Exists";
        default:
            return status satisfies never;
    }
};

const SignupError = (props: {
    status: Exclude<InviteCodeStatus, InviteCodeStatus.valid>;
    inviteType?: InviteType;
}) => {
    switch (props.status) {
        case InviteCodeStatus.accepted:
        case InviteCodeStatus.user_already_onboarded:
        case InviteCodeStatus.account_already_onboarded:
            // already onboarded. redirect to login
            return <Navigate to="/login" replace />;
        case InviteCodeStatus.invalid:
        case InviteCodeStatus.canceled:
        case InviteCodeStatus.expired:
            return (
                <>
                    <h2 className="font-headline text-2xl font-semibold text-destructive">
                        {getHeadline(props.status)}
                    </h2>
                    <p>
                        Please reach out to {getSubject(props.inviteType)} to
                        get an updated invite link.
                    </p>
                </>
            );
        case InviteCodeStatus.new_code_created:
            return (
                <>
                    <h2 className="font-headline text-2xl font-semibold text-destructive">
                        {getHeadline(props.status)}
                    </h2>
                    <p>
                        Looks like you have clicked on an old invite link.
                        <br />
                        Please check your inbox for the latest email.
                    </p>
                </>
            );
        default:
            return props.status satisfies never;
    }
};

export const SignupRoute = () => {
    usePageTitle("Signup");
    const { api, login } = use(AppContext);

    const invite_info = useInviteInfo();

    const { data: invite_code_status } = useSWRImmutable(
        invite_info,
        async (info: InviteInfo | undefined) => {
            if (!info) return InviteCodeStatus.invalid;
            return await api.check_invite_code(info);
        },
        { suspense: true },
    );

    const action = useAsyncState(async (invite_info: InviteAcceptance) => {
        const token = await api.onboard_accept(invite_info);
        login(token);
    });

    if (invite_code_status !== InviteCodeStatus.valid) {
        // handle error
        return (
            <div className="min-w-96 space-y-10 text-center">
                <SignupError
                    status={invite_code_status}
                    inviteType={invite_info?.invite_type}
                />
                <div className="pt-16">
                    <p className="font-headline text-lg font-semibold">
                        Already signed up?
                    </p>
                    <Button variant="link" asChild>
                        <Link to="/login">Go to the login page</Link>
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="min-w-96 space-y-10">
            <div className="space-y-4">
                <h2 className="text-center font-headline text-2xl font-semibold">
                    Welcome
                </h2>
                <p className="text-center text-sm font-light">
                    Select a password to create your account
                </p>
            </div>
            {action.error && <ErrorAlert error={action.error} />}
            <SignupForm action={action} inviteInfo={invite_info!} />
        </div>
    );
};
