import { APActionType, ScoredDocumentResult, UUID } from "@/api/types";

enum OutboundEventType {
    subscribe = "subscribe",
    unsubscribe = "unsubscribe",
    authenticate = "authenticate",
    context_load = "context_load",
    action_send = "action_send",
    explore_query = "explore_query",
}

export interface OutboundEventBase<Tk extends OutboundEventType, Tv> {
    type: Tk;
    payload: Tv;
}

interface AuthenticateEventBody {
    token: string;
}

interface ThreadSubscriptionEventBody {
    thread_id: UUID;
}

export interface ActionSendEventBody {
    message_id: UUID;
    conversation_id: UUID;
    id?: UUID;
    type: APActionType;
    message?: string;
    selected_text?: string;
    context?: string[];
}

export type AuthenticateEvent = OutboundEventBase<
    OutboundEventType.authenticate,
    AuthenticateEventBody
>;

export type ThreadSubscribeEvent = OutboundEventBase<
    OutboundEventType.subscribe,
    ThreadSubscriptionEventBody
>;

export type ThreadUnsubscribeEvent = OutboundEventBase<
    OutboundEventType.unsubscribe,
    ThreadSubscriptionEventBody
>;

export type ReportContextLoadEvent = OutboundEventBase<
    OutboundEventType.context_load,
    { message_id: UUID }
>;

export type ActionSendEvent = OutboundEventBase<
    OutboundEventType.action_send,
    ActionSendEventBody
>;

type HackwaveQueryEventBody = {
    id?: UUID;
    title: string;
    intent: string;
    documents: ScoredDocumentResult[];
};

export type HackwaveQueryEvent = OutboundEventBase<
    OutboundEventType.explore_query,
    HackwaveQueryEventBody
>;

export type OutboundEvent =
    | AuthenticateEvent
    | ThreadSubscribeEvent
    | ThreadUnsubscribeEvent
    | ReportContextLoadEvent
    | ActionSendEvent
    | HackwaveQueryEvent;

export const serialize = ({ type, payload }: OutboundEvent): string => {
    return JSON.stringify({ ...payload, event_type: type });
};

export const threadSubscribe = (thread_id: UUID): ThreadSubscribeEvent => ({
    type: OutboundEventType.subscribe,
    payload: { thread_id },
});

export const threadUnsubscribe = (thread_id: UUID): ThreadUnsubscribeEvent => ({
    type: OutboundEventType.unsubscribe,
    payload: { thread_id },
});

export const contextLoad = (message_id: UUID): ReportContextLoadEvent => ({
    type: OutboundEventType.context_load,
    payload: { message_id },
});

export const actionSend = (payload: ActionSendEventBody): ActionSendEvent => ({
    type: OutboundEventType.action_send,
    payload,
});
