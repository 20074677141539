import {
    Account,
    AccountType,
    ContractInterval,
    Currency,
    Price,
    User,
} from "@/api/types";

export const CHARS = {
    ellipsis: "\u2026",
};

export const isString = (s: unknown): s is string => typeof s === "string";

export const capitalize = (s: string): string => {
    if (s === "") return s;
    return s[0].toUpperCase() + s.slice(1);
};

export const capitalizeAll = (s: string) =>
    s.split(" ").map(capitalize).join(" ");

export const isEmptyOrNull = (
    s: string | null | undefined,
): s is null | undefined => {
    return s == null || s === "";
};

export const notEmptyOrNull = (s: string | null | undefined): s is string =>
    !isEmptyOrNull(s);

export const nullableTrim = (s: string): string | null => {
    const trimmed = s.trim();
    return isEmptyOrNull(trimmed) ? null : trimmed;
};

export const truncateWords = (s: string, n: number): string => {
    const words = getWords(s);
    if (words.length <= n) {
        return s;
    }
    return words.slice(0, n).join(" ") + "...";
};

export const truncate = (
    s: string,
    charLength: number = 80,
    ellipsis: string = "...",
): string =>
    s.length > charLength
        ? s.substring(0, charLength - ellipsis.length) + ellipsis
        : s;

export const truncateUrl = (s: string, length: number = 25): string => {
    const url = new URL(s);
    return `${url.hostname}${truncate(url.pathname, length)}`;
};

export const urlHostName = (s: string): string => {
    const url = new URL(s).hostname;
    if (url.startsWith("www.")) {
        return url.substring(4);
    }
    return url;
};

const getFirstNWords = (s: string, n: number): string => {
    const words = getWords(s);
    if (words.length <= n) {
        return s;
    }
    return words.slice(0, n).join(" ");
};

const getLastNWords = (s: string, n: number): string => {
    const words = getWords(s);
    if (words.length <= n) {
        return s;
    }
    return words.slice(words.length - n).join(" ");
};

export const getWords = (s: string): string[] =>
    s.split(" ").filter(notEmptyOrNull);

export const getWordCount = (s: string): number => getWords(s).length;

export const urlHighlight = (text: string): string => {
    const numWords = 4;
    if (getWordCount(text) > numWords * 2) {
        return [getFirstNWords(text, numWords), getLastNWords(text, numWords)]
            .map((s) => s.replace(/\.$/, "")) // remove period from the end of the words
            .map(encodeURIComponent)
            .join(",");
    }
    // remove period from the end of the string
    return encodeURIComponent(text.replace(/\.$/, ""));
};

export const leadingZeros = (n: number, digits: number): string =>
    String(n).padStart(digits, "0");

export const simpleHash = (str: string): string => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash;
    }
    return new Uint32Array([hash])[0].toString(36);
};

export const getUserName = (user: User): string => {
    if (user.first_name || user.last_name) {
        return [user.last_name, user.first_name].join(", ");
    }
    return user.username;
};

export const getAccountName = (
    account: Account,
    user: User | null = null,
): string | null => {
    switch (account.account_type) {
        case AccountType.admin:
            return account.name;
        case AccountType.organization:
            return account.name;
        case AccountType.individual:
            return user ? getUserName(user) : null;
    }
};

export const plural = (value: number, word: string): string =>
    value === 1 ? `${value} ${word}` : `${value} ${word}s`;

const pluralizeString = (word: string): string => {
    if (word.endsWith("s")) {
        return word;
    }
    if (word.endsWith("y")) {
        return word.slice(0, -1) + "ies";
    }
    return word + "s";
};

export const pluralize = (value: number, word: string): string =>
    value === 1 ? word : pluralizeString(word);

export const getReportDeeplink = (instruction: string): string => {
    const loc = window.location;
    return `${loc.protocol}//${loc.host}/dashboard?q=${encodeURIComponent(instruction)}`;
};

export const formatCurrency = (number: number, currency: Currency) => {
    const f = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency.toUpperCase(),
    });
    return f.format(number / 100);
};

export const formatPaymentPeriodForPrice = (price: Price) =>
    formatPaymentPeriod(
        price.currency,
        price.recurring_interval,
        price.recurring_interval_count,
    );

export const formatPaymentPeriod = (
    currency: Currency,
    interval: ContractInterval,
    interval_count: number = 1,
): string =>
    interval_count > 1
        ? `${currency.toUpperCase()} every ${plural(interval_count, interval)}`
        : `${currency.toUpperCase()} / ${interval}`;

export const hasLongWord = (str: string, threshold: number = 70) =>
    str.split(" ").some((w) => w.length > threshold);

export const formatNumber = (() => {
    const f = new Intl.NumberFormat();
    return (val: string) => {
        const parsed = parseFloat(val);
        if (Number.isNaN(parsed)) return val;
        return f.format(parsed);
    };
})();
