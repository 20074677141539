import { DocumentReferenceSkeleton } from "@/components/action-message/document-reference";
import { Headline } from "@/components/headline";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { leadingZeros, pluralize } from "@/utils/string-helpers";

export const ExecutiveSummarySkeleton = () => (
    <>
        <div className="space-y-8">
            <Headline level={4} separator>
                Synthesis
            </Headline>
            <div className="space-y-4">
                <Skeleton className="h-2.5 w-full" />
                <Skeleton className="h-2.5 w-full" />
                <Skeleton className="h-2.5 w-full" />
                <Skeleton className="h-2.5 w-full" />
                <Skeleton className="h-2.5 w-full" />
                <Skeleton className="h-2.5 w-full" />
                <Skeleton className="h-2.5 w-2/3" />
            </div>
            <div className="space-y-4">
                <Skeleton className="h-2.5 w-full" />
                <Skeleton className="h-2.5 w-full" />
                <Skeleton className="h-2.5 w-full" />
                <Skeleton className="h-2.5 w-full" />
                <Skeleton className="h-2.5 w-full" />
                <Skeleton className="h-2.5 w-1/2" />
            </div>
        </div>
        <div className="space-y-8">
            <Headline level={4} separator>
                Bottom Line
            </Headline>
            <ul className="space-y-8">
                {Array(4)
                    .fill(1)
                    .map((_, i) => (
                        <li key={i} className="flex gap-4">
                            <span className="font-headline text-2xl font-black">
                                {leadingZeros(i + 1, 2)}
                            </span>
                            <div className="grow space-y-4">
                                <Skeleton className="h-2.5 w-full" />
                                <Skeleton className="h-2.5 w-full" />
                                <Skeleton className="h-2.5 w-1/2" />
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    </>
);

export const HighlightsSkeleton = ({
    numItems = 6,
    withSource = false,
}: {
    numItems?: number;
    withSource?: boolean;
}) => (
    <div className="space-y-8">
        <Headline level={4} separator>
            Highlights
        </Headline>
        <div className="grid gap-4 @3xl:grid-cols-2 @5xl:grid-cols-3">
            {Array(numItems)
                .fill(1)
                .map((_, i) => (
                    <div key={i} className="flex flex-col rounded-md border">
                        <div className="space-y-4 p-4">
                            <Skeleton className="h-4 w-9/12" />
                            <Skeleton className="h-2.5 w-full" />
                            <Skeleton className="h-2.5 w-full" />
                            <Skeleton className="h-2.5 w-1/2" />
                        </div>
                        {withSource && (
                            <>
                                <Separator />
                                <DocumentReferenceSkeleton className="px-4" />
                            </>
                        )}
                    </div>
                ))}
        </div>
    </div>
);

export const SynthesisSkeleton = ({
    title,
    numItems = 2,
}: {
    title: string;
    numItems?: number;
}) => (
    <div className="grid gap-x-20 gap-y-12 @3xl:grid-cols-2">
        <Headline level={2} className="col-span-full" highlighted>
            {pluralize(numItems, title)}
        </Headline>
        {Array(numItems)
            .fill(1)
            .map((_, i) => (
                <div key={i} className="space-y-8 border-t pt-4">
                    <Skeleton className="h-8 w-9/12" />
                    <div className="space-y-4">
                        <Skeleton className="h-2.5 w-full" />
                        <Skeleton className="h-2.5 w-full" />
                        <Skeleton className="h-2.5 w-full" />
                        <Skeleton className="h-2.5 w-full" />
                        <Skeleton className="h-2.5 w-full" />
                        <Skeleton className="h-2.5 w-1/2" />
                    </div>
                </div>
            ))}
    </div>
);
