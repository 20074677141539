import { differenceInMilliseconds, formatDistance } from "date-fns";
import { HTMLAttributes, useEffect, useState } from "react";

interface Props extends HTMLAttributes<HTMLSpanElement> {
    ts: string | number | Date;
}

const SEC_30 = 30 * 1000;

export const RelativeTime = ({ ts, ...props }: Props) => {
    const [now, setNow] = useState(new Date());
    useEffect(() => {
        const handler = setInterval(() => setNow(new Date()), SEC_30);
        return () => clearInterval(handler);
    });
    return (
        <span {...props}>
            {differenceInMilliseconds(now, ts) < SEC_30
                ? "just now"
                : formatDistance(ts, now, { addSuffix: true })}
        </span>
    );
};
