export const ArcadeOnboarding = () => (
    <div
        className="relative h-0 w-full overflow-hidden rounded-md"
        style={{ paddingBottom: "calc(46.97390338700722% + 41px)" }}
    >
        <iframe
            src="https://demo.arcade.software/srl49u5UrFY3MHqNW1Ed?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=false"
            title="Brightwave"
            loading="lazy"
            allowFullScreen
            allow="clipboard-write"
            className="forced-colors:light absolute inset-0 h-full w-full"
            style={{ colorScheme: "light" }}
        />
    </div>
);
