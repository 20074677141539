import { ChevronDown } from "lucide-react";

import {
    GenericFindingContent,
    NumericalFinding as TNumericalFinding,
    UUID,
} from "@/api/types";
import { FindingActions } from "@/components/document-table/condensed/finding-actions";
import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { preventDefault } from "@/utils/dom";
import { EXPONENT_NAMES, getValueAndExponent } from "@/utils/math";

const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
});

export const NumericalFinding = ({
    finding,
    documentID,
}: {
    finding: TNumericalFinding;
    documentID?: UUID;
}) => {
    const [value, exponent] = getValueAndExponent(
        finding.value,
        finding.exponent,
    );

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant="ghost"
                    className="gap-2"
                    role="button"
                    size="sm"
                >
                    <div className="flex items-baseline gap-1.5 font-normal">
                        <span className="font-headline text-lg font-medium">
                            {formatter.format(value)}
                        </span>
                        <Exponent exponent={exponent} />
                        <span>{finding.unit}</span>
                    </div>
                    <ChevronDown className="size-4" />
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className="w-[35vw] space-y-2 overflow-y-scroll p-2"
                style={{
                    maxHeight: "var(--radix-popper-available-height)",
                }}
                collisionPadding={5}
                onOpenAutoFocus={preventDefault}
                data-bw-actions
            >
                <p>{finding.text}</p>
                <FindingActions
                    finding={finding as GenericFindingContent}
                    documentID={documentID}
                />
            </PopoverContent>
        </Popover>
    );
};

const Exponent = ({ exponent }: { exponent: number }) => {
    if (exponent === 0) return null;
    if (EXPONENT_NAMES.has(exponent)) {
        return <span>{EXPONENT_NAMES.get(exponent)}</span>;
    }
    return (
        <span>
            &times; 10<sup>{exponent}</sup>
        </span>
    );
};
