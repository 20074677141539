import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";

type Props = {
    value?: Date;
    onValueChange?: (value: Date) => void;
    maxDate?: Date;
};

export const DatePicker = ({ value, onValueChange, maxDate }: Props) => {
    const [date, setDate] = useState<Date | undefined>(value);

    useEffect(() => {
        if (
            onValueChange &&
            date !== undefined &&
            date?.getTime() !== value?.getTime()
        ) {
            onValueChange(date);
        }
    }, [value, date, onValueChange]);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={"outline"}
                    className={cn(
                        "flex w-full justify-start text-left font-normal",
                        !date && "text-muted-foreground",
                    )}
                >
                    <CalendarIcon className="mr-2 size-4" />
                    {date ? format(date, "PPP") : <span>Pick a date</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    mode="single"
                    selected={date}
                    onSelect={setDate}
                    initialFocus
                    toDate={maxDate}
                />
            </PopoverContent>
        </Popover>
    );
};
