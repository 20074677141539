import { UploadCloud } from "lucide-react";

import { Headline } from "@/components/headline";
import { MAX_FILE_SIZE_MB } from "@/conf/report";
import { useActiveDrag } from "@/hooks/use-active-drag";
import { useFileUploadContext } from "@/hooks/use-file-upload-context";
import { cn } from "@/lib/utils";

export const Dropzone = () => {
    const isDragActive = useActiveDrag();
    const isDragAccept = useFileUploadContext((s) => s.isDragAccept);
    const isDragReject = useFileUploadContext((s) => s.isDragReject);
    const { getRootProps, getInputProps } = useFileUploadContext(
        (s) => s.dropzone,
    );
    const remainingCount = useFileUploadContext((s) => s.maxFiles);
    const isDisabled = remainingCount <= 0;

    return (
        <div
            className={cn(
                "absolute inset-0 z-[100] hidden flex-col p-2 backdrop-blur animate-in zoom-in-95",
                isDragActive && "flex",
            )}
        >
            <div
                className={cn(
                    "flex grow flex-col justify-center rounded-md border bg-background/90",
                    {
                        "border-green-600 bg-green-50/90 dark:border-green-800 dark:bg-green-950/80":
                            isDragAccept && !isDisabled,
                        "border-red-600 bg-red-50/90 dark:border-red-800 dark:bg-red-950/80":
                            isDragReject || isDisabled,
                    },
                )}
            >
                <div
                    {...getRootProps()}
                    className={cn(
                        "flex grow flex-col items-center justify-center gap-8 p-4",
                        isDisabled ? "cursor-not-allowed" : "cursor-pointer",
                    )}
                >
                    <UploadCloud className="size-20" />
                    <Headline className="block text-nowrap @container-normal">
                        {isDisabled ? "File limit reached" : "Drop PDFs"}
                    </Headline>
                    <p className="text-gray-500 dark:text-gray-400">
                        Upload up to {remainingCount} PDF files with max{" "}
                        {MAX_FILE_SIZE_MB}MB each
                    </p>
                    <input {...getInputProps()} />
                </div>
            </div>
        </div>
    );
};
