import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { AsyncButton } from "@/components/async-button";
import {
    PasswordStrengthLabels,
    PasswordStrengthProgress,
} from "@/components/password-strength";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseAsyncState } from "@/hooks/use-async-state";
import { passwordRequirements } from "@/utils/password";

type Props = {
    action: UseAsyncState<[string, string], boolean>;
};

const formSchema = z
    .object({
        current_password: z.string().min(1, "Current password is required"),
        new_password: z
            .string()
            .min(6, "Password does not satify all requirements"),
    })
    .superRefine(({ new_password }, checkPassComplexity) => {
        if (!passwordRequirements.every((req) => req.re.test(new_password))) {
            checkPassComplexity.addIssue({
                path: ["new_password"],
                code: z.ZodIssueCode.custom,
                message: "Password does not satify all requirements",
            });
        }
    });

export const ChangePasswordForm = (props: Props) => {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            current_password: "",
            new_password: "",
        },
    });

    const submit = async (data: z.infer<typeof formSchema>) => {
        await props.action.submit(data.current_password, data.new_password);
        form.reset();
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(submit)} className="space-y-8">
                <FormField
                    control={form.control}
                    name="current_password"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Current Password</FormLabel>
                            <FormControl>
                                <Input type="password" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="new_password"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>New Password</FormLabel>
                            <FormControl>
                                <Input type="password" {...field} />
                            </FormControl>
                            <FormMessage />
                            <PasswordStrengthProgress
                                value={field.value}
                                requirements={passwordRequirements}
                                className="pt-2"
                            />
                            {field.value.length > 0 && (
                                <PasswordStrengthLabels
                                    value={field.value}
                                    requirements={passwordRequirements}
                                    className="pt-2"
                                />
                            )}
                        </FormItem>
                    )}
                />
                <AsyncButton loading={props.action.isSubmitting}>
                    Update Password
                </AsyncButton>
            </form>
        </Form>
    );
};
