import { Quote } from "lucide-react";
import { MouseEvent, PropsWithChildren, useEffect, useRef } from "react";

import { CitationNumber } from "@/components/citation-number";
import { DebugID } from "@/components/debug/debug-id";
import { useCitationContext } from "@/hooks/use-citation-context";
import { cn } from "@/lib/utils";
import { first } from "@/utils/collection";
import { findParentWithOverflowHidden } from "@/utils/dom";
import { fromDOMRect, translateY } from "@/utils/geometry";

type Props = {
    id: string;
    citation_id: string;
};

export const CitationDirective = ({
    id,
    citation_id,
    children,
}: PropsWithChildren<Props>) => {
    const ref = useRef<HTMLSpanElement>(null);
    const citation = useCitationContext((s) => s.citations.get(citation_id));
    const isSelected = useCitationContext((s) => s.citation?.unique_id === id);
    const setCitation = useCitationContext((s) => s.setCitation);
    const clearCitation = useCitationContext((s) => s.clearCitation);
    const setAnchor = useCitationContext((s) => s.setAnchor);

    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
        const entry = first(entries);
        if (entry && !entry.isIntersecting) {
            clearCitation();
        }
    };

    useEffect(() => {
        const el = ref.current;
        if (el && isSelected) {
            const updateAnchor = () => {
                const rect = fromDOMRect(el.getBoundingClientRect());
                setAnchor(translateY(rect, window.scrollY));
            };
            updateAnchor();
            const parent = findParentWithOverflowHidden(el);
            if (parent) {
                const intersectionObserver = new IntersectionObserver(
                    intersectionCallback,
                    {
                        root: parent,
                        rootMargin: "10%",
                    },
                );
                intersectionObserver.observe(el);

                const resizeObserver = new ResizeObserver(updateAnchor);
                resizeObserver.observe(parent);

                parent.addEventListener("scroll", updateAnchor);

                return () => {
                    parent.removeEventListener("scroll", updateAnchor);
                    resizeObserver.disconnect();
                    intersectionObserver.disconnect();
                };
            }
        }
    }, [isSelected, ref, setAnchor]);

    const handleClick = (e: MouseEvent) => {
        e.stopPropagation();
        if (isSelected) {
            clearCitation();
        } else {
            setCitation(id, citation_id);
        }
    };

    return (
        <span className="group">
            <span
                className={cn(
                    "from-transparent from-60% to-brightwave to-60% font-medium group-hover:bg-gradient-to-b dark:to-brightwave-800",
                    isSelected && "bg-gradient-to-b",
                )}
            >
                {children}
            </span>
            <CitationNumber
                ref={ref}
                data-number={citation?.display_index}
                className={cn(
                    "cursor-pointer select-none bg-brightwave-300 text-sm hover:bg-brightwave data-[number]:after:content-[attr(data-number)] dark:bg-brightwave-300 dark:text-background",
                    isSelected && "bg-brightwave",
                )}
                onClick={handleClick}
            >
                {citation?.display_index === undefined && (
                    <Quote className="inline-block size-3 -translate-y-[2px] -scale-100 fill-foreground stroke-none" />
                )}
            </CitationNumber>
            <DebugID label="Citation ID" id={citation_id} className="mx-1" />
        </span>
    );
};
