import { PropsWithChildren } from "react";

import { DocumentStatus } from "@/api/types";
import { CellState } from "@/components/analyze/cell-state";

type Props = PropsWithChildren<{ status: DocumentStatus }>;

export const DocumentStatusGate = (props: Props) => {
    switch (props.status) {
        case DocumentStatus.initial:
        case DocumentStatus.processing:
        case DocumentStatus.processing_completed:
        case DocumentStatus.post_processing:
            return (
                <CellState variant="loading">Processing Document...</CellState>
            );
        case DocumentStatus.failed:
        case DocumentStatus.skipped:
            return (
                <CellState variant="error">
                    Could not process document
                </CellState>
            );
        case DocumentStatus.ready:
            return props.children;
        default:
            return props.status satisfies never;
    }
};
