const avgReducer = (avg: number, val: number, _: unknown, list: number[]) =>
    avg + val / list.length;

export const avg = (values: number[]): number =>
    values.length ? values.reduce(avgReducer, 0) : 0;

export const add = (a: number, b: number): number => a + b;

export const clamp = (value: number, min: number, max: number) => {
    if (value < min) {
        return min;
    } else if (value > max) {
        return max;
    } else {
        return value;
    }
};

export const EXPONENT_NAMES = new Map([
    [12, "Trillion"],
    [9, "Billion"],
    [6, "Million"],
    [3, "Thousand"],
    [-3, "Milli"],
    [-6, "Micro"],
    [-9, "Nano"],
    [-12, "Pico"],
]);

export const getValueAndExponent = (
    value: number,
    exponent: number | null,
): [number, number] => {
    if (exponent === null || exponent === 0) return [round(value), 0];

    const expInfo = Array.from(EXPONENT_NAMES.entries()).find(
        ([e]) => exponent >= e,
    );

    if (expInfo === undefined) {
        return [round(value), exponent];
    }

    return [round(normalizeNumber(value, exponent - expInfo[0])), expInfo[0]];
};

export const round = (value: number, decimals: number = 2): number =>
    Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);

export const normalizeNumber = (value: number, exponent: number): number =>
    value * Math.pow(10, exponent);

export const inRange =
    (min: number, max: number) =>
    (value: number): boolean =>
        min <= value && value <= max;

export const numberToString = (value: number): string => {
    if (1 < value && value < 1000) {
        return round(value).toString();
    }

    const expInfo = Array.from(EXPONENT_NAMES.entries()).find(
        ([e]) => value >= Math.pow(10, e),
    );
    if (expInfo === undefined) {
        return "";
    }

    return `${round(value / Math.pow(10, expInfo[0])).toString()} ${expInfo[1]}`;
};
