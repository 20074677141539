import { createContext } from "react";
import { KeyedMutator } from "swr";

import { BrightwaveAPI } from "@/api/rest";
import { User } from "@/api/types";
import { WebsocketClient } from "@/api/ws/websocket-client";
import { emptyFunction } from "@/utils/empty-function";

export type TAppContext = {
    api: BrightwaveAPI;
    websocket: WebsocketClient | null;
    user: User | null;
    mutateUser: KeyedMutator<User | null>;
    login: (token: string) => void;
    logout: () => void;
};

export const AppContext = createContext<TAppContext>({
    api: new BrightwaveAPI(),
    websocket: null,
    user: null,
    mutateUser: async () => null,
    login: emptyFunction,
    logout: emptyFunction,
});
