import { use } from "react";
import { useStore } from "zustand";

import { FileUploadContext } from "@/context/file-upload-context";
import { FileUploadStore } from "@/stores/file-upload";

export const useFileUploadContext = <T>(
    selector: (state: ReturnType<FileUploadStore["getState"]>) => T,
): T => {
    const store = use(FileUploadContext);
    if (!store) {
        throw new Error(
            "Missing FileUploadContextProvider in the component tree",
        );
    }
    return useStore(store, selector);
};
