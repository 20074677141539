import { useEffect, useRef } from "react";

const MIME_TYPES: Record<string, string> = {
    ico: "image/x-icon",
    png: "image/png",
    svg: "image/svg+xml",
    gif: "image/gif",
};

const getLinkElement = (url: string): HTMLLinkElement => {
    const el = document.querySelector<HTMLLinkElement>('link[rel*="icon"]');
    if (el) {
        return el;
    }
    const urlParts = url.split(".");
    const element = document.createElement("link");
    element.rel = "icon";
    element.type = MIME_TYPES[urlParts[urlParts.length - 1].toLowerCase()];
    element.href = url;
    document.querySelector("head")!.appendChild(element);
    return element;
};

export function useFavicon(url: string) {
    const link = useRef<HTMLLinkElement>(getLinkElement(url));

    useEffect(() => {
        const urlParts = url.split(".");
        link.current.setAttribute(
            "type",
            MIME_TYPES[urlParts[urlParts.length - 1].toLowerCase()],
        );
        link.current.setAttribute("href", url);
    }, [url]);
}
