/* eslint react-compiler/react-compiler: 0 */
// Disabled eslint because once react compiler and react 19 are released
// refs are props and forwardRefs depreacted rendering this file obsolete

import { ForwardedRef, useEffect, useRef } from "react";

export const useForwardRef = <T>(
    ref: ForwardedRef<T> | undefined,
    initialValue: T | null = null,
) => {
    const targetRef = useRef<T | null>(initialValue);

    useEffect(() => {
        if (!ref) return;

        if (typeof ref === "function") {
            ref(targetRef.current);
        } else {
            ref.current = targetRef.current;
        }
    }, [ref]);

    return targetRef;
};
