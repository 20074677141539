import { createStore } from "zustand";

import { APActionCitation, DocumentInfo, UUID } from "@/api/types";
import { Rect } from "@/utils/geometry";

export interface Props {
    citations: Map<UUID, APActionCitation>;
    documents: Map<UUID, DocumentInfo>;
}

interface State extends Props {
    citation: { unique_id: string; citation_id: UUID } | undefined;
    setCitation: (unique_id: string, citation_id: UUID) => void;
    clearCitation: () => void;

    anchor: Rect | undefined;
    setAnchor: (pos: Rect) => void;

    updateCitations: (citations: Map<UUID, APActionCitation>) => void;
    updateDocuments: (documents: Map<UUID, DocumentInfo>) => void;
}

export type CitationsStore = ReturnType<typeof createCitationsStore>;

export const createCitationsStore = (props: Props) =>
    createStore<State>((set) => ({
        ...props,

        citation: undefined,
        setCitation(unique_id, citation_id) {
            set({ citation: { unique_id, citation_id } });
        },

        clearCitation() {
            set({ citation: undefined });
        },

        anchor: undefined,
        setAnchor(anchor) {
            set({ anchor });
        },

        updateCitations(citations) {
            set({ citations });
        },

        updateDocuments(documents) {
            set({ documents });
        },
    }));
