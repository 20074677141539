import { ExternalLink } from "lucide-react";
import { use } from "react";
import { toast } from "sonner";
import useSWRInfinite from "swr/infinite";

import {
    Currency,
    Invoice,
    InvoiceStatus as InvoiceStatusEnum,
} from "@/api/types";
import { ButtonWithConfirmation } from "@/components/admin/button-with-confirmation";
import { InvoiceStatus } from "@/components/billing/invoice-status";
import { PaymentMethod } from "@/components/billing/payment-method";
import { ErrorAlert } from "@/components/error-alert";
import { InfiniteScroll } from "@/components/infinite-scroll";
import { Skeleton } from "@/components/ui/skeleton";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { BillingSettingsContext } from "@/container/settings/billing-context";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { last } from "@/utils/collection";
import { getCursorKeyFn } from "@/utils/pagination";
import { formatCurrency } from "@/utils/string-helpers";
import { formatDate } from "@/utils/time";

const PayNowButton = ({
    invoice,
    onSuccess,
}: {
    invoice: Invoice;
    onSuccess: (i: Invoice) => void;
}) => {
    const api = useApi();
    const { defaultPaymentMethod } = use(BillingSettingsContext);
    const action = useAsyncState(
        async () =>
            await api.pay_invoice(invoice.id, defaultPaymentMethod!.details.id),
        {
            onSuccess: (i: Invoice) => {
                onSuccess(i);
                toast.success("Thank you for your payment!");
            },
            onError: () => {
                toast.error(
                    "Payment failed. Please try another payment method.",
                );
            },
        },
    );

    if (!defaultPaymentMethod) return null;

    const amount = formatCurrency(invoice.total, Currency.usd);

    return (
        <ButtonWithConfirmation
            action={action}
            variant="outline"
            title="Confirm Payment"
            message={
                <>
                    <p>
                        Amount: <span className="font-medium">{amount}</span>
                    </p>
                    <p className="font-medium">Payment Method</p>
                    <PaymentMethod
                        method={defaultPaymentMethod}
                        className="rounded border bg-accent p-2"
                    />
                </>
            }
        >
            Pay now
        </ButtonWithConfirmation>
    );
};

const Loader = () => (
    <div className="divide-y border-x border-b">
        <div className="flex h-14 w-full items-center px-4">
            <Skeleton className="h-3 w-full" />
        </div>
        <div className="flex h-14 w-full items-center px-4">
            <Skeleton className="h-3 w-full" />
        </div>
        <div className="flex h-14 w-full items-center px-4">
            <Skeleton className="h-3 w-full" />
        </div>
    </div>
);

const PAGE_SIZE = 10;

type Props = {
    onPaymentSuccess: () => void;
};

export const Invoices = ({ onPaymentSuccess }: Props) => {
    const api = useApi();
    const { data, error, isLoading, isValidating, setSize, mutate } =
        useSWRInfinite(
            getCursorKeyFn(PAGE_SIZE, "invoices"),
            async () => await api.fetch_invoices(),
            { suspense: true },
        );

    const onPayment = () => {
        mutate();
        onPaymentSuccess();
    };

    const invoices = (data ?? []).flatMap((p) => p.items);
    const hasNextPage = data && last(data)?.page_info.has_next_page;

    return (
        <InfiniteScroll
            hasNextPage={hasNextPage}
            onNextPage={() => setSize((s) => s + 1)}
            isLoading={isLoading || (hasNextPage && isValidating)}
            loader={<Loader />}
        >
            <Table className="border">
                <TableHeader>
                    <TableRow>
                        <TableHead>Date</TableHead>
                        <TableHead>Amount</TableHead>
                        <TableHead></TableHead>
                        <TableHead>Invoice Number</TableHead>
                        <TableHead className="w-full"></TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {invoices.map((invoice) => (
                        <TableRow
                            key={invoice.id}
                            className="whitespace-nowrap"
                        >
                            <TableCell>
                                {formatDate(invoice.created_at)}
                            </TableCell>
                            <TableCell className="flex items-baseline justify-end gap-2">
                                {formatCurrency(invoice.total, Currency.usd)}
                                <span className="text-xs text-gray-500">
                                    USD
                                </span>
                            </TableCell>
                            <TableCell>
                                <InvoiceStatus status={invoice.status} />
                            </TableCell>
                            <TableCell>{invoice.number}</TableCell>
                            <TableCell className="flex justify-end gap-4">
                                <a
                                    href={invoice.hosted_invoice_url}
                                    target="_blank"
                                    className="flex items-center gap-2 text-blue-500 underline-offset-4 hover:underline"
                                >
                                    Invoice <ExternalLink className="size-4" />
                                </a>
                                {invoice.status === InvoiceStatusEnum.open && (
                                    <PayNowButton
                                        invoice={invoice}
                                        onSuccess={onPayment}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                    {invoices.length === 0 && (
                        <TableRow>
                            <TableCell
                                colSpan={4}
                                className="text-center italic"
                            >
                                No invoices
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {error && <ErrorAlert error={error} />}
        </InfiniteScroll>
    );
};
