import { Table } from "@tanstack/react-table";

import { Column } from "@/api/types";
import { FilterPopover } from "@/components/document-table/filter/filter-popover";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";

type Props = {
    column: Column;
    table: Table<DocumentWithAsyncFindings>;
    open?: boolean;
};

export const CategoricalColumnFilter = (props: Props) => {
    // const columnId = getColumId(props.column);
    // const column = props.table.getColumn(columnId);

    return (
        <FilterPopover column={props.column}>
            Categorical not implemented
        </FilterPopover>
    );
};
