import { BookOpenCheck, X } from "lucide-react";
import { useState } from "react";

import { ColumnType } from "@/api/types";
import { ProbesLibrary } from "@/components/document-table/columns/probes-library";
import { SystemColumns } from "@/components/document-table/columns/system-columns";
import { Button } from "@/components/ui/button";
import {
    SheetClose,
    SheetDescription,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

export const ExistingColumns = () => {
    const [tab, setTab] = useState<ColumnType>(ColumnType.system);

    return (
        <>
            <SheetHeader className="mb-4 min-h-12 flex-row items-center justify-between space-y-0 border-b px-2">
                <SheetTitle className="px-2">Add Analysis</SheetTitle>
                <SheetDescription className="sr-only">
                    Add a Brightwave analysis or create your custom analysis
                </SheetDescription>
                <SheetClose asChild>
                    <Button variant="ghost" size="icon-sm">
                        <X className="size-4" />
                    </Button>
                </SheetClose>
            </SheetHeader>
            <Tabs
                value={tab}
                onValueChange={(val) => setTab(val as ColumnType)}
                className="px-4"
            >
                <TabsList className="flex">
                    <TabsTrigger value={ColumnType.system} className="flex-1">
                        Presets
                    </TabsTrigger>
                    <TabsTrigger
                        value={ColumnType.user_defined}
                        className="flex-1 gap-2"
                    >
                        <BookOpenCheck className="size-4" />
                        Your Library
                    </TabsTrigger>
                </TabsList>
            </Tabs>
            <Content type={tab} />
        </>
    );
};

const Content = (props: { type: ColumnType }) => {
    switch (props.type) {
        case ColumnType.system:
            return <SystemColumns />;
        case ColumnType.user_defined:
            return <ProbesLibrary />;
        default:
            return props.type satisfies never;
    }
};
