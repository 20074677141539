import { Header } from "@tanstack/react-table";

import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { cn } from "@/lib/utils";
import { stopPropagation, wrapStopPropagation } from "@/utils/dom";

export const ColumnResizeHandle = ({
    header,
}: {
    header: Header<DocumentWithAsyncFindings, unknown>;
}) => (
    <div
        className={cn(
            "absolute bottom-0 right-0 top-0 flex w-1 cursor-ew-resize items-center justify-center bg-transparent group-hover:bg-brightwave",
            header.column.getIsResizing() && "bg-brightwave",
        )}
        onDoubleClick={header.column.resetSize}
        onMouseDown={wrapStopPropagation(header.getResizeHandler())}
        onTouchStart={wrapStopPropagation(header.getResizeHandler())}
        onKeyDown={stopPropagation}
    />
);
