import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { User } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseAsyncState } from "@/hooks/use-async-state";

type Props = {
    user: User;
    action: UseAsyncState<[Partial<User>], void>;
};

const formSchema = z.object({
    first_name: z.string().trim().min(1, "First name can not be empty"),
    last_name: z.string().trim().min(1, "Last name can not be empty"),
});

export const UserProfileForm = ({ user, action }: Props) => {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            first_name: user.first_name ?? "",
            last_name: user.last_name ?? "",
        },
    });

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(action.submit)}
                className="space-y-8"
            >
                <FormField
                    control={form.control}
                    name="first_name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>First Name</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="last_name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Last Name</FormLabel>
                            <FormControl>
                                <Input {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <AsyncButton loading={action.isSubmitting}>
                    Save Changes
                </AsyncButton>
            </form>
        </Form>
    );
};
