import { HTMLAttributes } from "react";

import { MessageCard } from "@/components/action-message/message-card";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

interface ActionMessageResponseSkeletonProps
    extends HTMLAttributes<HTMLDivElement> {
    multiLine?: boolean;
}

export const ActionMessageResponseSkeleton = ({
    multiLine = false,
    className,
    ...props
}: ActionMessageResponseSkeletonProps) => (
    <div className={cn("space-y-3", className)} {...props}>
        {multiLine && (
            <>
                <Skeleton className="h-3 w-full" />
                <Skeleton className="h-3 w-full" />
                <Skeleton className="h-3 w-full" />
            </>
        )}
        <Skeleton className="h-3 w-1/2" />
    </div>
);

export const FollowUpQuestionSkeleton = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => (
    <div className={cn("space-y-4", className)} {...props}>
        <Skeleton className="h-3 w-full" />
        <Skeleton className="h-3 w-7/12" />
    </div>
);

export const CollapsedActionSkeleton = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => (
    <MessageCard
        className={cn("flex items-center gap-2 space-y-0 px-2 py-3", className)}
        {...props}
    >
        <Skeleton className="size-6 shrink-0 rounded-full" />
        <Skeleton className="h-3 w-56" />
        <Skeleton className="mx-4 h-3 w-full" />
        <Skeleton className="h-3 w-48" />
    </MessageCard>
);
