import { toast } from "sonner";
import useSWRImmutable from "swr/immutable";

import { ThreadConfig as TThreadConfig } from "@/api/types";
import { ThreadConfigForm } from "@/components/forms/thread-config";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";

export const ThreadConfigSettings = () => {
    const api = useApi();
    const { data: thread_config, mutate } = useSWRImmutable(
        "settings/thread-config",
        async () => await api.fetch_settings_thread_config(),
        { suspense: true },
    );
    const { data: modelOptions } = useSWRImmutable(
        "settings/model-config",
        async () => await api.fetch_settings_model_options(),
        { suspense: true },
    );

    const updateAction = useAsyncState(
        (data: TThreadConfig) =>
            mutate(api.update_settings_thread_config(data)),
        { onSuccess: () => toast.success("Settings updated") },
    );

    return (
        <div className="col-span-2 space-y-4">
            <h1 className="text-headline text-2xl font-medium">
                Thread Config
            </h1>
            <ThreadConfigForm
                settings={thread_config}
                modelOptions={modelOptions.available}
                action={updateAction}
            />
        </div>
    );
};
