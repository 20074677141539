import { ReactNode } from "react";

import { AsyncButton } from "@/components/async-button";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { ButtonProps } from "@/components/ui/button";
import { UseAsyncState } from "@/hooks/use-async-state";

interface ConfirmModalProps {
    opened: boolean;
    onClose: () => void;
    title?: string;
    message: ReactNode;
    action: UseAsyncState<[], unknown>;
    confirmButtonVariant?: ButtonProps["variant"];
}

export const ConfirmModal = (props: ConfirmModalProps) => (
    <AlertDialog
        open={props.opened}
        onOpenChange={(isOpen: boolean) => !isOpen && props.onClose()}
    >
        <AlertDialogContent>
            <AlertDialogHeader>
                <AlertDialogTitle>
                    {props.title ?? "Are you sure?"}
                </AlertDialogTitle>
                {typeof props.message === "string" ? (
                    <AlertDialogDescription>
                        {props.message}
                    </AlertDialogDescription>
                ) : (
                    props.message
                )}
            </AlertDialogHeader>
            <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction variant={props.confirmButtonVariant} asChild>
                    <AsyncButton action={props.action}>Continue</AsyncButton>
                </AlertDialogAction>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
);
