export type ObjMap<T> = {
    [key: string]: T;
};

export const update = <T>(
    om: ObjMap<T>,
    key: string,
    updater: (a: T | undefined) => T,
): ObjMap<T> => ({ ...om, [key]: updater(om[key]) });

export const set = <T>(om: ObjMap<T>, key: string, val: T): ObjMap<T> => ({
    ...om,
    [key]: val,
});

export const setAll = <T>(om: ObjMap<T>, tuples: [string, T][]): ObjMap<T> => ({
    ...om,
    ...Object.fromEntries(tuples),
});

export const remove = <T>(om: ObjMap<T>, key: string): ObjMap<T> =>
    Object.entries(om)
        .filter(([k]) => k !== key)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const has = <T>(om: ObjMap<T>, key: string): boolean => key in om;

export const isEmpty = <T>(om: ObjMap<T>): boolean =>
    Object.keys(om).length === 0;

export const get = <T>(om: ObjMap<T>, key: string): T | undefined => om[key];

export const getOrElse = <T>(om: ObjMap<T>, key: string, def: T): T =>
    om[key] ?? def;

export const map = <TIn, TOut>(
    om: ObjMap<TIn>,
    f: (a: TIn) => TOut,
): ObjMap<TOut> =>
    Object.fromEntries(Object.entries(om).map(([k, v]) => [k, f(v)]));

export const mapKey = <T>(
    om: ObjMap<T>,
    f: (key: string) => string,
): ObjMap<T> =>
    Object.fromEntries(Object.entries(om).map(([k, v]) => [f(k), v]));

export const fromTuples = <Tk extends string, Tv>(
    itr: [Tk, Tv][],
): ObjMap<Tv> => itr.reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});

export const count = <T>(om: ObjMap<T>): number => Object.keys(om).length;

export const filter = <T>(
    om: ObjMap<T>,
    predicate: (val: T, key: string) => boolean,
): ObjMap<T> =>
    Object.fromEntries(
        Object.entries(om).filter(([key, val]) => predicate(val, key)),
    );

export const updateKey = <T>(
    om: ObjMap<T>,
    oldKey: string,
    newKey: string,
): ObjMap<T> =>
    Object.fromEntries(
        Object.entries(om).map(([key, val]) => [
            key == oldKey ? newKey : key,
            val,
        ]),
    );
