import { format } from "date-fns";
import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export const Copyright = ({
    className,
    ...props
}: HTMLAttributes<HTMLParagraphElement>) => (
    <p className={cn("text-sm text-gray-500", className)} {...props}>
        Copyright ©{" "}
        <a
            href="http://brightwave.io"
            target="_blank"
            className="hover:underline"
        >
            Brightwave
        </a>{" "}
        {format(new Date(), "yyyy")}
    </p>
);
