import { Moon, Sun, SunMoon } from "lucide-react";
import { useTheme } from "next-themes";
import { PropsWithChildren } from "react";

import { Button } from "@/components/ui/button";
import { usePageTitle } from "@/hooks/use-page-title";
import { cn } from "@/lib/utils";

const ThemeButton = ({
    variant,
    ...props
}: PropsWithChildren<{ variant: string }>) => {
    const { setTheme, theme } = useTheme();
    return (
        <Button
            variant="outline"
            className={cn(
                "flex h-auto grow basis-0 flex-col gap-2 py-3",
                theme === variant && "ring-2 ring-slate-500 ring-offset-2",
            )}
            onClick={() => setTheme(variant)}
        >
            {props.children}
        </Button>
    );
};

export const AppearanceSettings = () => {
    usePageTitle(["Appearance", "Settings"]);

    return (
        <div className="col-span-2 space-y-8">
            <h1 className="text-headline text-2xl font-medium">Appearance</h1>
            <div className="flex gap-4">
                <ThemeButton variant="light">
                    <Sun className="size-6 text-yellow-500" />
                    Light
                </ThemeButton>
                <ThemeButton variant="dark">
                    <Moon className="size-6 fill-slate-500 stroke-slate-500 stroke-1" />
                    Dark
                </ThemeButton>
                <ThemeButton variant="system">
                    <SunMoon className="size-6" />
                    System
                </ThemeButton>
            </div>
        </div>
    );
};
