import * as Sentry from "@sentry/react";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { ThemeProvider } from "next-themes";
import { StrictMode, Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
    RouterProvider,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

import { ErrorBoundary } from "@/components/error-boundary";
import { Favicon } from "@/components/favicon";
import { PageLoader } from "@/components/page-loader";
import { Toaster } from "@/components/ui/sonner";
import { AppContextProvider } from "@/context/app-context-provider";
import { router } from "@/router";
import { ErrorPage } from "@/routes/error";
import { config } from "@/utils/configuration";
import { notEmptyOrNull } from "@/utils/string-helpers";

import "@/styles/fonts.css";
import "@/styles/global.css";
import "katex/dist/katex.min.css";

/**
 * SENTRY CONFIGURATION
 */
if (!config.isDev && config.sentryDsn) {
    Sentry.init({
        dsn: config.sentryDsn,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration({
                maskAllText: false,
                maskAllInputs: true,
            }),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            /^https:\/\/api\.staging\.brightwave\.io\//,
            /^https:\/\/api\.brightwave\.io\//,
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        environment: config.sentryEnv,
    });
}

/**
 * LAUNCH DARKLY STUFF
 */
const launchDarklyEnabled = notEmptyOrNull(config.launchDarklyClientId);

const app = (
    <Suspense fallback={<PageLoader />}>
        <AppContextProvider>
            <RouterProvider router={router} />
        </AppContextProvider>
        <Toaster richColors />
        <Favicon />
    </Suspense>
);

/**
 * Render App
 */
createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <ThemeProvider storageKey="color-scheme" attribute="class" enableSystem>
            <ErrorBoundary
                fallback={({ error }) => (
                    <ErrorPage
                        error={error instanceof Error ? error : undefined}
                    />
                )}
            >
                {launchDarklyEnabled ? (
                    <LDProvider clientSideID={config.launchDarklyClientId}>
                        {app}
                    </LDProvider>
                ) : (
                    app
                )}
            </ErrorBoundary>
        </ThemeProvider>
    </StrictMode>,
);
