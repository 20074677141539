import {
    WebsocketClient,
    WebsocketClientEvent,
} from "@/api/ws/websocket-client";
import { Token } from "@/hooks/use-token";

const socketCache = new Map<string, Promise<WebsocketClient>>();

export const getCachedWebsocket = (
    token: Token,
): Promise<WebsocketClient | null> => {
    if (!token) {
        return Promise.resolve(null);
    }

    if (!socketCache.has(token)) {
        socketCache.set(
            token,
            new Promise((resolve) => {
                const ws_client = new WebsocketClient(token);
                ws_client.addEventListener(
                    WebsocketClientEvent.AUTH_SUCCESS,
                    () => resolve(ws_client),
                );
            }),
        );
    }

    return socketCache.get(token)!;
};
