import { createContext } from "react";
import { KeyedMutator } from "swr";

import { PaymentMethod } from "@/api/types";

type TContext = {
    defaultPaymentMethod: PaymentMethod | null;
    mutateDefaultPaymentMethod: KeyedMutator<PaymentMethod>;
};

export const BillingSettingsContext = createContext<TContext>({
    defaultPaymentMethod: null,
    mutateDefaultPaymentMethod: async () => undefined,
});
