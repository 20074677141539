import { Table } from "@tanstack/react-table";

import { Column } from "@/api/types";
import { FilterPopover } from "@/components/document-table/filter/filter-popover";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { Badge } from "@/components/ui/badge";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { getColumId } from "@/utils/columns";

const getFilterValue = (raw: boolean | undefined): string => {
    if (raw === true) return "true";
    if (raw === false) return "false";
    return "none";
};

type Props = {
    column: Column;
    table: Table<DocumentWithAsyncFindings>;
};

export const BooleanColumnFilter = (props: Props) => {
    const column = props.table.getColumn(getColumId(props.column));
    const clearFilter = () => column?.setFilterValue(undefined);
    const rawFilterValue = column?.getFilterValue() as boolean | undefined;
    const filterValue = getFilterValue(rawFilterValue);

    const handleChange = (value: string) => {
        switch (value) {
            case "true":
                column?.setFilterValue(true);
                break;
            case "false":
                column?.setFilterValue(false);
                break;
            default:
                column?.setFilterValue(undefined);
                break;
        }
    };
    return (
        <FilterPopover
            column={props.column}
            onClear={clearFilter}
            filterValue={<FilterBadge filterValue={filterValue} />}
        >
            <RadioGroup
                className="p-2"
                value={filterValue}
                onValueChange={handleChange}
            >
                <Label className="flex items-center space-x-2">
                    <RadioGroupItem value="true" />
                    <Badge variant="boolean_true" size="tag">
                        Yes
                    </Badge>
                </Label>
                <Label className="flex items-center space-x-2">
                    <RadioGroupItem value="false" />
                    <Badge variant="boolean_false" size="tag">
                        No
                    </Badge>
                </Label>
            </RadioGroup>
        </FilterPopover>
    );
};

export const BooleanFilterContent = (props: {
    value: boolean | undefined;
    onChange: (value: boolean | undefined) => void;
}) => {
    const filterValue = getFilterValue(props.value);
    const handleChange = (value: string) => {
        switch (value) {
            case "true":
                return props.onChange(true);
            case "false":
                return props.onChange(false);
            default:
                return props.onChange(undefined);
        }
    };
    return (
        <RadioGroup
            className="p-2"
            value={filterValue}
            onValueChange={handleChange}
        >
            <Label className="flex items-center space-x-2">
                <RadioGroupItem value="true" />
                <Badge variant="boolean_true" size="tag">
                    Yes
                </Badge>
            </Label>
            <Label className="flex items-center space-x-2">
                <RadioGroupItem value="false" />
                <Badge variant="boolean_false" size="tag">
                    No
                </Badge>
            </Label>
        </RadioGroup>
    );
};

const FilterBadge = ({ filterValue }: { filterValue: string }) => {
    switch (filterValue) {
        case "true":
            return (
                <Badge
                    variant="boolean_true"
                    size="tag"
                    className="rounded-full"
                >
                    Yes
                </Badge>
            );
        case "false":
            return (
                <Badge
                    variant="boolean_false"
                    size="tag"
                    className="rounded-full"
                >
                    No
                </Badge>
            );
        default:
            return undefined;
    }
};
