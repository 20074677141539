import nullthrows from "nullthrows";

export const isEmpty = <T>(c: T[] | null | undefined): boolean =>
    c == null || c.length === 0;

export const first = <T>(list: readonly T[]): T | null =>
    list.length ? list[0] : null;

export const firstX = <T>(list: T[]): T => nullthrows(first(list));

export const lastX = <T>(list: T[]): T => nullthrows(last(list));

export const last = <T>(list: T[]): T | null =>
    list.length ? list[list.length - 1] : null;

export const concat = <T>(
    a: T[] | undefined | null,
    b: T[] | undefined | null,
): T[] | undefined => {
    if (a == null) return b ?? undefined;
    if (b == null) return a ?? undefined;
    return [...a, ...b];
};

export const arr_partition = <A>(
    items: A[],
    predicate: (item: A, index: number, list: A[]) => boolean,
): [A[], A[]] =>
    items.reduce(
        (acc, item, i) => {
            if (predicate(item, i, items)) {
                acc[0].push(item);
            } else {
                acc[1].push(item);
            }
            return acc;
        },
        [[], []] as [A[], A[]],
    );

export const dict_from_keys = <Tk extends string | number | symbol, Tv>(
    arr: Tv[],
    key: (item: Tv) => Tk,
): Record<Tk, Tv[]> =>
    arr.reduce(
        (acc: Record<Tk, Tv[]>, item: Tv) => {
            acc[key(item)] = acc[key(item)]
                ? [...acc[key(item)], item]
                : [item];
            return acc;
        },
        {} as Record<Tk, Tv[]>,
    );

export const unique = <T>(arr: Iterable<T>): T[] => [...new Set(arr)];

export const uniqueBy = <T, K>(arr: T[], key: (item: T) => K): T[] => {
    const seen = new Set<K>();
    return arr.filter((item) => {
        const k = key(item);
        if (seen.has(k)) {
            return false;
        }
        seen.add(k);
        return true;
    });
};

export const take = <T>(arr: T[], n: number): T[] => arr.slice(0, n);

export const dropFirst = <T>(itr: Iterable<T>): T[] => Array.from(itr).slice(1);

export const dropLast = <T>(itr: Iterable<T>): T[] => {
    const res = Array.from(itr);
    res.splice(-1);
    return res;
};

export const equals = <T>(a: T[], b: T[]): boolean => {
    if (a === b) return true;
    if (a.length !== b.length) return false;
    for (let i = a.length - 1; i >= 0; --i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};

// returns items that are in a but not in b
export const difference = <T>(a: T[], b: T[]): T[] => {
    const _b = new Set(b);
    return a.filter((item) => !_b.has(item));
};

// returns items that are in a and b
export const intersection = <T>(a: T[], b: T[]): T[] => {
    const _b = new Set(b);
    return a.filter((item) => _b.has(item));
};
