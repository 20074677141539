import {
    ComponentPropsWithRef,
    MouseEventHandler,
    useEffect,
    useRef,
    useState,
} from "react";
import { createPortal } from "react-dom";
import useSWRImmutable from "swr/immutable";

import { APActionCitation, UUID } from "@/api/types";
import { DocumentReference } from "@/components/action-message/document-reference";
import { DocumentLink } from "@/components/document/document-link";
import { Markdown } from "@/components/markdown";
import { PopoverPosition } from "@/components/popover-position";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { useApi } from "@/hooks/use-api";
import { useCitationContext } from "@/hooks/use-citation-context";
import { Rect } from "@/utils/geometry";
import { isEmptyOrNull } from "@/utils/string-helpers";

type Props = {
    citation: APActionCitation;
    anchor: Rect;
} & ComponentPropsWithRef<"div">;

const CitationSkeleton = () => (
    <div className="mb-3 mt-1 space-y-3">
        <Skeleton className="h-2 w-full bg-muted-foreground" />
        <Skeleton className="h-2 w-full bg-muted-foreground" />
        <Skeleton className="h-2 w-full bg-muted-foreground" />
        <Skeleton className="h-2 w-1/2 bg-muted-foreground" />
    </div>
);

const CitationContent = (props: { citationID: UUID }) => {
    const api = useApi();
    const [retryCounter, setRetryCounter] = useState(0);
    const { data, isLoading, error, isValidating } = useSWRImmutable(
        [props.citationID, retryCounter, "citation"],
        async ([id, retryCounter]) =>
            await api.fetch_citation(id, retryCounter > 0),
    );

    const retry: MouseEventHandler = (e) => {
        e.stopPropagation();
        setRetryCounter((s) => s + 1);
    };

    if (error || (data !== undefined && isEmptyOrNull(data.citation))) {
        return (
            <p className="px-2 text-destructive">
                Failed to load citation
                <Button
                    onClick={retry}
                    variant="link"
                    className="text-background"
                >
                    Retry
                </Button>
            </p>
        );
    }

    if (data === undefined || isLoading || isValidating) {
        return <CitationSkeleton />;
    }

    return (
        <Markdown className="prose-sm p-2 pt-1 text-background prose-headings:text-background prose-p:text-background prose-strong:bg-brightwave-800 prose-strong:text-background prose-table:text-background prose-th:text-background dark:prose-strong:bg-brightwave-300">
            {data.citation}
        </Markdown>
    );
};

const CitationImpl = ({ ref, citation, anchor }: Props) => {
    const document = useCitationContext((s) =>
        s.documents.get(citation.document_id),
    );

    return (
        <PopoverPosition
            ref={ref}
            anchor={anchor}
            className="selection-dark w-1/3 rounded border border-secondary-foreground bg-stone-800 p-2 text-secondary shadow-md dark:bg-stone-200"
        >
            <CitationContent citationID={citation.id} />
            {document && (
                <>
                    <Separator className="bg-muted-foreground" />
                    <DocumentLink documentID={document.id} asChild>
                        <DocumentReference info={document} dark />
                    </DocumentLink>
                </>
            )}
        </PopoverPosition>
    );
};

export const Citation = () => {
    const ref = useRef<HTMLDivElement>(null);
    const citation = useCitationContext((s) =>
        s.citation ? s.citations.get(s.citation.citation_id) : undefined,
    );
    const anchor = useCitationContext((s) => s.anchor);
    const clearCitation = useCitationContext((s) => s.clearCitation);

    // close citation overlay when clicked outside
    useEffect(() => {
        if (citation) {
            const listener = (e: MouseEvent) => {
                const target = e.target;
                if (
                    citation &&
                    target instanceof Element &&
                    !ref.current?.contains(target)
                ) {
                    clearCitation();
                }
            };
            document.documentElement.addEventListener("click", listener);
            return () =>
                document.documentElement.removeEventListener("click", listener);
        }
    }, [citation]);

    if (!citation || !anchor) return null;

    return createPortal(
        <CitationImpl ref={ref} citation={citation} anchor={anchor} />,
        document.body,
    );
};
