import { ComponentPropsWithRef, ReactNode } from "react";

import { HoverTooltip } from "@/components/hover-tooltip";
import { Button, ButtonProps } from "@/components/ui/button";

type Props = ButtonProps & {
    tooltip: ReactNode;
} & ComponentPropsWithRef<"button">;

export const ButtonWithTooltip = ({
    ref,
    tooltip,
    children,
    ...props
}: Props) => {
    return (
        <HoverTooltip tooltip={tooltip}>
            <Button ref={ref} {...props}>
                {children}
                <span className="sr-only">{tooltip}</span>
            </Button>
        </HoverTooltip>
    );
};
