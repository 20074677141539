import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";

export const BetaBadge = () => (
    <Dialog>
        <DialogTrigger asChild>
            <Badge variant="beta" className="cursor-pointer">
                BETA
            </Badge>
        </DialogTrigger>
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Beta Feature</DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
                <p>
                    This feature is under active development and may change in
                    the future.
                </p>
                <p>
                    If you encounter any issues with this functionality please
                    contact{" "}
                    <a
                        href="mailto:support@brightwave.io"
                        className="underline-offset-4 hover:underline"
                    >
                        support@brightwave.io
                    </a>
                </p>
            </div>
            <DialogFooter>
                <DialogClose asChild>
                    <Button>Close</Button>
                </DialogClose>
            </DialogFooter>
        </DialogContent>
    </Dialog>
);
