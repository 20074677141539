import { useEffect } from "react";

import { config } from "@/utils/configuration";
import { isEmptyOrNull } from "@/utils/string-helpers";

const SEPARATOR = " · ";

export const usePageTitle = (title: string | string[]) => {
    useEffect(() => {
        const fragments = Array.isArray(title) ? title : [title];
        fragments.push("Brightwave");
        let prefix = "";
        if (config.isDev) {
            prefix = "[DEV] ";
        } else if (config.env === "staging") {
            prefix = "[STAGING] ";
        }

        document.title =
            prefix +
            fragments
                .map((s) => s.trim())
                .filter((s) => !isEmptyOrNull(s))
                .join(SEPARATOR);
    }, [title]);
};
