import { useLayoutEffect, useState } from "react";

export type SelectionDetails = {
    text: string;
    range: Range;
    anchorNode: Node;
    selectedParagraph?: boolean;
};

const selectableNode = (node: Node | null | undefined): boolean =>
    node?.parentElement?.closest("[data-bw-actions='true']") != null;

export const useSelection = () => {
    const [selection, setSelection] = useState<SelectionDetails | null>(null);

    const onSelectionChange = () => {
        // clear selection if selection is removed
        const s = document.getSelection();
        if (!s || s.isCollapsed) {
            setSelection(null);
        }
    };

    const updateSelection = (e: MouseEvent | TouchEvent) => {
        const selection = document.getSelection();
        const anchor = selection?.anchorNode;
        if (
            selection != null &&
            !selection.isCollapsed &&
            anchor &&
            selectableNode(anchor)
        ) {
            const range = selection.getRangeAt(0);
            setSelection((s) => ({
                text: selection.toString().trim(),
                range,
                selectedParagraph: s?.selectedParagraph || e.detail === 3,
                anchorNode: anchor,
            }));
        }
    };

    useLayoutEffect(() => {
        document.addEventListener("mouseup", updateSelection);
        document.addEventListener("touchend", updateSelection);
        document.addEventListener("selectionchange", onSelectionChange);
        return () => {
            document.removeEventListener("mouseup", updateSelection);
            document.addEventListener("touchend", updateSelection);
            document.removeEventListener("selectionchange", onSelectionChange);
        };
    }, [onSelectionChange]);

    return selection;
};
