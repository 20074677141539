import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export const NotFound = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => (
    <div {...props} className={cn("space-y-8 p-10 text-center", className)}>
        <h1 className="font-headline text-4xl font-black uppercase text-red-500">
            404
            <span className="block font-headline text-3xl font-black uppercase text-foreground">
                Not found
            </span>
        </h1>
        <p>The page you requested does not exist.</p>
    </div>
);
