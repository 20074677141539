import { CursorPaginationParams, PagePaginationParams } from "@/api/rest";
import { CursorPage, DefaultPage } from "@/api/types";

export type KeyFnResult<T> = {
    pagination: T;
    identifier: string;
} | null;

export const getCursorKeyFn =
    <T>(pageSize: number, identifier: string) =>
    (
        pageIndex: number,
        previousPageData: CursorPage<T> | null,
    ): KeyFnResult<CursorPaginationParams> => {
        // first page
        if (pageIndex === 0) {
            return { pagination: { size: pageSize }, identifier };
        }
        // last page
        if (previousPageData && !previousPageData.page_info.has_next_page) {
            return null;
        }
        // next page
        return {
            pagination: {
                size: pageSize,
                cursor: previousPageData!.page_info.end_cursor,
            },
            identifier,
        };
    };

export const getPageKeyFn =
    <T>(pageSize: number, identifier: string) =>
    (
        pageIndex: number,
        previousPageData: DefaultPage<T> | null,
    ): KeyFnResult<PagePaginationParams> => {
        // first page
        if (pageIndex === 0) {
            return { pagination: { size: pageSize }, identifier };
        }
        // last page
        if (
            previousPageData &&
            previousPageData.pages === previousPageData.page
        ) {
            return null;
        }
        // next page
        return {
            pagination: {
                size: pageSize,
                page: previousPageData!.page + 1,
            },
            identifier,
        };
    };

export const getEmptyCursorPage = <T>(): CursorPage<T> => ({
    items: [],
    count: 0,
    page_info: {
        has_next_page: false,
        end_cursor: null,
    },
});
