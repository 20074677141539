import { getYear } from "date-fns";
import { HTMLAttributes, useEffect, useState } from "react";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { notEmptyOrNull } from "@/utils/string-helpers";

interface Props extends HTMLAttributes<HTMLDivElement> {
    value?: string; // YYYY-\QQ
    onValueChange?: (val: string) => void;
    minYear: number;
}

export const FiscalQuarterSelect = ({
    value,
    onValueChange,
    minYear,
    className,
    ...props
}: Props) => {
    const current = getYear(new Date());
    const years = Array.from(Array(current - minYear + 1)).map(
        (_, i) => current - i,
    );

    const [year, setYear] = useState<string | undefined>(() => {
        if (notEmptyOrNull(value)) {
            return value.split("-")[0];
        }
        return undefined;
    });

    const [quarter, setQuarter] = useState<string | undefined>(() => {
        if (notEmptyOrNull(value)) {
            return value.split("-")[1];
        }
        return undefined;
    });

    useEffect(() => {
        if (year !== undefined && quarter !== undefined) {
            const newVal = `${year}-${quarter}`;
            if (value !== newVal) {
                onValueChange?.(`${year}-${quarter}`);
            }
        }
    }, [year, quarter, value, onValueChange]);

    return (
        <div className={cn("flex gap-2", className)} {...props}>
            <Select value={quarter} onValueChange={setQuarter}>
                <SelectTrigger>
                    <SelectValue placeholder="Quarter" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="Q1">Q1</SelectItem>
                    <SelectItem value="Q2">Q2</SelectItem>
                    <SelectItem value="Q3">Q3</SelectItem>
                    <SelectItem value="Q4">Q4</SelectItem>
                </SelectContent>
            </Select>
            <Select value={year} onValueChange={setYear}>
                <SelectTrigger>
                    <SelectValue placeholder="Year" />
                </SelectTrigger>
                <SelectContent>
                    {years.map((y) => (
                        <SelectItem key={y} value={String(y)}>
                            {y}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    );
};
