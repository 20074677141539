import { PropsWithChildren, useEffect, useState } from "react";
import useSWRImmutable from "swr/immutable";
import { v4 as uuidv4 } from "uuid";

import { SubscriptionDetails } from "@/components/billing/subscription-details";
import { SubscriptionContainer } from "@/container/paywall/subscription";
import { BillingInformation } from "@/container/settings/billing/billing-information";
import { Invoices } from "@/container/settings/billing/invoices";
import { PaymentMethods } from "@/container/settings/billing/payment-methods";
import { BillingSettingsContext } from "@/container/settings/billing-context";
import { useApi } from "@/hooks/use-api";
import { usePageTitle } from "@/hooks/use-page-title";
import { useUser } from "@/hooks/use-user";

const BillingSettingsContextProvider = (props: PropsWithChildren) => {
    const api = useApi();
    const { data, mutate: mutateDefaultPaymentMethod } = useSWRImmutable(
        "default_payment_method_provider",
        async () => await api.fetch_default_payment_method(),
    );
    return (
        <BillingSettingsContext
            value={{
                defaultPaymentMethod: data ?? null,
                mutateDefaultPaymentMethod,
            }}
            {...props}
        />
    );
};

export const BillingSettings = () => {
    usePageTitle(["Billing", "Settings"]);
    const user = useUser();
    const [fetchKey, setFetchKey] = useState<string | null>(null);
    const setRandomFetchKey = () => setFetchKey(uuidv4());
    useEffect(setRandomFetchKey, [setFetchKey]);

    return (
        <BillingSettingsContextProvider>
            <div className="col-span-2 space-y-8 lg:col-span-3">
                <h1 className="text-headline text-2xl font-medium">Billing</h1>
                <div className="space-y-4">
                    <h1 className="text-headline text-xl font-medium">
                        Current Plan
                    </h1>
                    {fetchKey && (
                        <SubscriptionContainer fetchKey={fetchKey}>
                            {({ subscription }) => (
                                <SubscriptionDetails
                                    user={user}
                                    subscription={subscription}
                                />
                            )}
                        </SubscriptionContainer>
                    )}
                </div>
                <div className="space-y-4">
                    <h1 className="text-headline text-xl font-medium">
                        Billing Information
                    </h1>
                    <BillingInformation />
                </div>
                <div className="space-y-4">
                    <h1 className="text-headline text-xl font-medium">
                        Payment Methods
                    </h1>
                    <PaymentMethods />
                </div>
                <div className="space-y-4">
                    <h1 className="text-headline text-xl font-medium">
                        Invoices
                    </h1>
                    <Invoices onPaymentSuccess={setRandomFetchKey} />
                </div>
            </div>
        </BillingSettingsContextProvider>
    );
};
