import { createStore } from "zustand";

import { ContextStatus, UUID } from "@/api/types";
import { ContextUpdateMethod } from "@/api/ws/websocket-types";
import { mapDeleteAll, mapSetAll } from "@/utils/es6-map";

export enum DocumentContextState {
    adding,
    removing,
    ready,
    error,
}

interface State {
    status: ContextStatus;
    documents: Map<UUID, DocumentContextState>;

    setLoading: () => void;
    setLoaded: (document_ids: UUID[]) => void;
    setError: () => void;
    reset: () => void;

    setUpdating: (method: ContextUpdateMethod, document_ids: UUID[]) => void;
    updateSuccess: (method: ContextUpdateMethod, document_ids: UUID[]) => void;
    updateError: (document_ids: UUID[]) => void;
}

export type ContextStore = ReturnType<typeof createContextStore>;

export const createContextStore = () =>
    createStore<State>()((set) => ({
        status: ContextStatus.UNINITIALIZED,
        documents: new Map(),

        setLoading() {
            set({ status: ContextStatus.LOADING });
        },

        setLoaded(document_ids: UUID[]) {
            set((s) => ({
                status: ContextStatus.LOADED,
                documents: mapSetAll(
                    s.documents,
                    document_ids.map((id) => [id, DocumentContextState.ready]),
                ),
            }));
        },

        setUpdating(method: ContextUpdateMethod, document_ids: UUID[]) {
            const state = getDocumentContextStateForMethod(method);
            set((s) => ({
                status: ContextStatus.UPDATING,
                documents: mapSetAll(
                    s.documents,
                    document_ids.map((id) => [id, state]),
                ),
            }));
        },

        updateSuccess(method: ContextUpdateMethod, document_ids: UUID[]) {
            switch (method) {
                case ContextUpdateMethod.add:
                    return set((s) => {
                        const documents = mapSetAll(
                            s.documents,
                            document_ids.map((id) => [
                                id,
                                DocumentContextState.ready,
                            ]),
                        );
                        return {
                            status: allSettled(documents)
                                ? ContextStatus.LOADED
                                : s.status,
                            documents,
                        };
                    });
                case ContextUpdateMethod.remove:
                    return set((s) => {
                        const documents = mapDeleteAll(
                            s.documents,
                            document_ids,
                        );
                        return {
                            status: allSettled(documents)
                                ? ContextStatus.LOADED
                                : s.status,
                            documents,
                        };
                    });
                default:
                    return method satisfies never;
            }
        },

        updateError(document_ids: UUID[]) {
            return set((s) => {
                // set documents to error if they exist in context
                const documents = new Map(s.documents);
                for (const id of document_ids) {
                    if (documents.has(id)) {
                        documents.set(id, DocumentContextState.error);
                    }
                }

                return {
                    status: allSettled(documents)
                        ? ContextStatus.LOADED
                        : s.status,
                    documents,
                };
            });
        },

        setError() {
            set({ status: ContextStatus.FAILED });
        },

        reset() {
            set({ status: ContextStatus.UNINITIALIZED, documents: new Map() });
        },
    }));

const allSettled = (documents: Map<string, DocumentContextState>): boolean =>
    Array.from(documents.values()).every(
        (s) => s >= DocumentContextState.ready,
    );

const getDocumentContextStateForMethod = (
    method: ContextUpdateMethod,
): DocumentContextState => {
    switch (method) {
        case ContextUpdateMethod.add:
            return DocumentContextState.adding;
        case ContextUpdateMethod.remove:
            return DocumentContextState.removing;
        default:
            return method satisfies never;
    }
};
