import { isPast, parseISO } from "date-fns";
import { ArrowDownCircle, ArrowRightCircle } from "lucide-react";
import { HTMLAttributes } from "react";

import { AccountType, CustomerStatus, Subscription, User } from "@/api/types";
import {
    CheckoutCard,
    CheckoutCardContent,
    CheckoutCardFooter,
    CheckoutCardTitle,
} from "@/components/billing/checkout-card";
import { SubscriptionPrice } from "@/components/paywall/subscription-price";
import { cn } from "@/lib/utils";
import { plural } from "@/utils/string-helpers";
import { formatDate } from "@/utils/time";

const TrialFooter = ({
    user,
    subscription,
}: {
    user: User;
    subscription: Subscription;
}) => {
    if (user.status === CustomerStatus.trial_pending) return null;
    const trialExpired =
        subscription.trial_end !== null &&
        isPast(parseISO(subscription.trial_end));

    return (
        <CheckoutCardFooter>
            <span className="mr-2 text-gray-500">
                {trialExpired ? "ended" : "ends"}
            </span>
            {subscription.trial_end && formatDate(subscription.trial_end)}
        </CheckoutCardFooter>
    );
};

interface Props extends HTMLAttributes<HTMLDivElement> {
    user: User;
    subscription: Subscription;
}

export const SubscriptionDetails = ({
    subscription,
    user,
    className,
    ...props
}: Props) => {
    const userActive = user.status === CustomerStatus.active;
    const showTrial =
        subscription.trial_days != null &&
        subscription.trial_days > 0 &&
        !userActive;
    const userTrialing =
        user.status === CustomerStatus.trial_pending ||
        user.status === CustomerStatus.trialing;
    const trialExpired =
        subscription.trial_end !== null &&
        isPast(parseISO(subscription.trial_end));
    const highlightTrial = showTrial && userTrialing && !trialExpired;
    const highlightSubscription = showTrial && !highlightTrial;

    return (
        <div
            {...props}
            className={cn(
                "flex flex-col flex-wrap gap-4 sm:flex-row",
                className,
            )}
        >
            {showTrial && (
                <>
                    <CheckoutCard
                        variant={highlightTrial ? "selected" : undefined}
                        className="grow"
                    >
                        <CheckoutCardTitle>Trial</CheckoutCardTitle>
                        <CheckoutCardContent className="font-headline text-3xl font-light">
                            {plural(subscription.trial_days ?? 0, "day")}
                        </CheckoutCardContent>
                        <TrialFooter user={user} subscription={subscription} />
                    </CheckoutCard>
                    <ArrowRightCircle className="hidden size-4 self-center text-gray-500 sm:block" />
                    <ArrowDownCircle className="size-4 self-center text-gray-500 sm:hidden" />
                </>
            )}
            <CheckoutCard
                variant={highlightSubscription ? "selected" : undefined}
                className="grow"
            >
                <CheckoutCardTitle>Subscription</CheckoutCardTitle>
                <CheckoutCardContent className="flex items-baseline justify-between">
                    <span className="text-sm text-gray-500">
                        {user.account_type === AccountType.organization
                            ? "Organization"
                            : "Individual"}
                    </span>
                    <SubscriptionPrice
                        items={subscription.items}
                        className="font-headline text-xl font-light"
                    />
                </CheckoutCardContent>
                {userActive && subscription.current_period_end && (
                    <CheckoutCardFooter>
                        <span className="mr-2 text-gray-500">
                            next billing date
                        </span>
                        {formatDate(subscription.current_period_end)}
                    </CheckoutCardFooter>
                )}
            </CheckoutCard>
        </div>
    );
};
