import { Check, X } from "lucide-react";
import { PropsWithChildren } from "react";

import { cn } from "@/lib/utils";
import { PasswordRequirementType, getStrength } from "@/utils/password";

type PasswordStrengthProps = {
    value: string;
    requirements: PasswordRequirementType[];
    className?: string;
};

const getColor = (value: string, strength: number, i: number, max: number) => {
    const gray = "bg-gray-200 dark:bg-gray-700";
    const color =
        strength > 80
            ? "bg-green-500"
            : strength > 50
              ? "bg-yellow-500"
              : "bg-red-500";

    if (value.length > 0 && i === 0) return color;
    return strength >= ((i + 1) / max) * 100 ? color : gray;
};

export const PasswordStrengthProgress = (props: PasswordStrengthProps) => {
    const max = props.requirements.length;
    if (max < 1) return null;
    const strength = getStrength(props.requirements, props.value);

    const bars = Array(max)
        .fill(0)
        .map((_, i) => (
            <div
                key={i}
                className={cn(
                    "h-1 grow rounded-full",
                    getColor(props.value, strength, i, max),
                )}
            />
        ));

    return <div className={cn("flex space-x-1", props.className)}>{bars}</div>;
};

const PasswordRequirement = (
    props: PropsWithChildren<{ checked: boolean; className?: string }>,
) => (
    <div
        className={cn(
            "flex items-center text-sm",
            props.checked ? "text-green-500" : "text-red-500",
            props.className,
        )}
    >
        {props.checked ? (
            <Check className="mr-2 h-4 w-4" />
        ) : (
            <X className="mr-2 h-4 w-4" />
        )}
        {props.children}
    </div>
);

export const PasswordStrengthLabels = (props: PasswordStrengthProps) => {
    const checks = props.requirements.map((req, i) => (
        <PasswordRequirement key={i} checked={req.re.test(props.value)}>
            <span>{req.label}</span>
        </PasswordRequirement>
    ));
    return (
        <div className={cn("space-y-1", props.className)}>
            <PasswordRequirement checked={props.value.length > 5}>
                <span>Has at least 6 characters</span>
            </PasswordRequirement>
            {checks}
        </div>
    );
};
