import { UUID } from "@/api/types";

export enum NuxType {
    ONBOARDING_DIALOG = "99ed9409-09e2-42a1-a7ea-afa5d4e805b6",
    REPORT_ONBOARDING = "3d2a0355-effc-45bd-b532-10a1c3bbf1d4",
    REPORT_BUILDER_USE_WEB_SEARCH = "22b093f3-a63b-4c4f-8ad3-eb499291162e",

    V2_ONBOARDING = "bfbd433b-91b7-4aa7-a5b8-a84e49271f61",
}

const lookup = Object.entries(NuxType).reduce(
    (acc, [k, v]) => acc.set(v, k),
    new Map(),
);

export const getNuxLabel = (nux_id: UUID): string | null =>
    lookup.has(nux_id) ? lookup.get(nux_id) : null;
