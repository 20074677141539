export enum AsyncState {
    initial,
    queued,
    fetching,
    error,
    success,
}

export type AsyncValue<T> =
    | { state: AsyncState.initial }
    | { state: AsyncState.queued }
    | { state: AsyncState.fetching }
    | { state: AsyncState.success; value: T }
    | { state: AsyncState.error; error: Error };
