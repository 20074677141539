import { FileStatus, UploadFile, UploadFileOk } from "@/api/types";
import { MAX_FILE_SIZE_MB } from "@/conf/report";

export const getErrorMessage = (code: string | undefined): string => {
    switch (code) {
        case "file-invalid-type":
            return "File type not supported";
        case "file-too-large":
            return `File too large (max. ${MAX_FILE_SIZE_MB}MB)`;
        case "file-too-small":
            return "File too small";
        case "too-many-files":
            return "Too many files";
        case "upload-failed":
            return "Failed to upload file";
        case "processing-error":
            return "Error processing file";
        default:
            return "Unknown error";
    }
};

export const isFileUploaded = (f: UploadFile): f is UploadFileOk =>
    f.status >= FileStatus.uploaded && f.status <= FileStatus.ready;
