import { ChevronDown } from "lucide-react";
import { PropsWithChildren } from "react";

import { Badge } from "@/components/ui/badge";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { preventDefault } from "@/utils/dom";
import { isEmptyOrNull } from "@/utils/string-helpers";

type Props = PropsWithChildren<{
    value: boolean;
    text?: string | null;
}>;

export const BooleanFinding = (props: Props) => {
    if (props.value === false) {
        return (
            <Badge variant="boolean_false" size="tag">
                No
            </Badge>
        );
    }
    if (isEmptyOrNull(props.text)) {
        return (
            <Badge variant="boolean_true" size="tag" role="button">
                Yes
            </Badge>
        );
    }

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Badge variant="boolean_true" size="tag" role="button">
                    Yes
                    <ChevronDown className="ml-2 size-4" />
                </Badge>
            </PopoverTrigger>
            <PopoverContent
                className="w-[35vw] space-y-2 overflow-y-scroll p-2"
                style={{ maxHeight: "var(--radix-popper-available-height)" }}
                collisionPadding={5}
                onOpenAutoFocus={preventDefault}
                data-bw-actions
            >
                <p>{props.text}</p>
                {props.children}
            </PopoverContent>
        </Popover>
    );
};
