import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export const MessageCard = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn("space-y-4 rounded bg-background p-4", className)}
        {...props}
    />
);
