import {
    ColumnFiltersState,
    ColumnOrderState,
    ColumnSizingState,
    OnChangeFn,
    SortingState,
    VisibilityState,
} from "@tanstack/react-table";
import { ColumnFilter } from "@tanstack/react-table";
import stableHash from "stable-hash";
import { createStore, StateCreator } from "zustand";

import {
    AddColumnPayload,
    BrightwaveAPI,
    RemoveColumnPayload,
    UpdateProbePayload,
} from "@/api/rest";
import {
    AsyncFindingGroup,
    ColumnDefinition,
    ContextItem,
    DocumentInfo,
    DocumentStatus,
    FindingGroupInfo,
    FindingGroupType,
    FullDocumentCollection,
    UUID,
    Column,
    ColumnType,
    DocumentFindingGroup,
    FindingGroupStatus,
} from "@/api/types";
import { HIDDEN_COLUMNS, StaticColumn } from "@/conf/grid-view";
import { AsyncState } from "@/utils/async-value";
import { difference, unique, uniqueBy } from "@/utils/collection";
import { getColumId } from "@/utils/columns";
import { encodeContextItem } from "@/utils/context-items";
import { toTuple } from "@/utils/entity";
import {
    mapAddEntity,
    mapDelete,
    mapDeleteAll,
    mapMap,
    mapMerge,
    mapSet,
    mapSetAll,
    mapUpdate,
    mapUpdateIfExists,
} from "@/utils/es6-map";
import {
    createInitialAsyncFindingGroup,
    createQueuedAsyncFindingGroup,
    createSuccessAsyncFindingGroup,
} from "@/utils/finding-group";
import { nonNull } from "@/utils/fn";
import { log } from "@/utils/log";
import * as objectMap from "@/utils/object-map";
import { onChangeMiddleware } from "@/utils/zustand";

export type VersionedState<T> = {
    version: number;
    state: T;
};

interface Dependencies {
    api: BrightwaveAPI;
    persistedClientState: VersionedState<Record<string, unknown>> | null;
    availableColumns: ColumnDefinition[];
}

interface Props {
    report: FullDocumentCollection;
}

interface Actions {
    updateReport: (report: FullDocumentCollection) => void;
    updateReportTitle: (title: string) => Promise<void>;

    addItems: (items: ContextItem[]) => Promise<void>;
    deleteItems: (ids: UUID[]) => Promise<void>;

    updateDocumentStatus: (id: UUID, status: DocumentStatus) => void;
    invalidateColumn: (
        findingGroupType: FindingGroupType,
        probeID?: UUID,
    ) => Promise<void>;
    invalidateDocument: (document_id: UUID) => Promise<void>;

    addFindingGroup: (
        finding_group: FindingGroupInfo | DocumentFindingGroup,
    ) => void;
    updateFindingGroup: (finding_group: FindingGroupInfo) => void;
    fetchFindings: (finding_group_id: UUID) => void;
    retryFailedFindingGroup: (finding_group_id: UUID) => Promise<void>;

    addColumn: (column: AddColumnPayload) => Promise<void>;
    updateColumn: (probe_id: UUID, probe: UpdateProbePayload) => Promise<void>;
    deleteColumn: (column: RemoveColumnPayload) => Promise<void>;

    setColumnSizing: (columnSizing: ColumnSizingState) => void;
    setColumnOrder: OnChangeFn<ColumnOrderState>;

    setSorting: OnChangeFn<SortingState>;

    setColumnFilters: OnChangeFn<ColumnFiltersState>;
    addColumnFilter: (filter: ColumnFilter) => void;

    clearColumnFocus: () => void;
}

type SerializableClientState = {
    columnSizing: ColumnSizingState;
    columnOrder: ColumnOrderState;
    sorting: SortingState;
    columnFilters: ColumnFiltersState;
};

type EntityMaps = {
    documents: Map<UUID, DocumentInfo>;
    finding_groups: Map<UUID, AsyncFindingGroup>;
    documents_to_finding_groups: Map<UUID, UUID[]>;
};

interface State extends Props, EntityMaps, SerializableClientState, Actions {
    columns: Column[];
    availableColumns: Map<FindingGroupType, ColumnDefinition>;
    columnFocus: string | null;
    columnVisibility: VisibilityState;
}

export type GridViewStore = ReturnType<typeof createGridViewStore>;

const VERSION = 8;

const DEFAULT_STATE: SerializableClientState = {
    columnOrder: [
        FindingGroupType.executive_summary,
        FindingGroupType.risks,
        FindingGroupType.opportunities,
    ],
    columnSizing: {},
    sorting: [
        {
            id: StaticColumn.content_date,
            desc: true,
        },
        {
            id: StaticColumn.ticker,
            desc: false,
        },
    ],
    columnFilters: [],
};

const getInitialState = (
    report: FullDocumentCollection,
    persistedClientState: VersionedState<Record<string, unknown>> | null,
): EntityMaps & SerializableClientState => {
    const clientState =
        persistedClientState !== null
            ? migrate(persistedClientState)
            : DEFAULT_STATE;
    const entityMaps = getEntityMaps(report);
    const columnKeys = new Set(report.columns.map(getColumId));

    return {
        ...entityMaps,
        ...clientState,

        columnOrder: unique([
            ...clientState.columnOrder.filter((id) => columnKeys.has(id)), // remove old keys
            ...columnKeys, // add new keys
        ]),
        columnSizing: objectMap.filter(clientState.columnSizing, (_, id) =>
            columnKeys.has(id),
        ),
    };
};

const initializer =
    ({
        api,
        report,
        persistedClientState,
        availableColumns,
        ...props
    }: Props & Dependencies): StateCreator<State> =>
    (set, get) => ({
        report,
        columns: report.columns,
        availableColumns: new Map(availableColumns.map((c) => [c.type, c])),

        ...getInitialState(report, persistedClientState),
        ...props,

        columnFocus: null,
        columnVisibility: [...HIDDEN_COLUMNS].reduce(
            (o, c) => objectMap.set(o, c, false),
            {},
        ),

        updateReport(report) {
            set((s) => {
                if (stableHash(s.report) !== stableHash(report)) {
                    return { report, ...getEntityMaps(report) };
                }
                return {};
            });
        },

        async updateReportTitle(title) {
            const report = get().report;
            // optimistic update
            set({ report: { ...report, title } });
            try {
                // async update
                const update = await api.update_report_title(report.id, title);
                set((s) => ({ report: { ...s.report, ...update } }));
            } catch (e) {
                // rollback
                set({ report });
                // throw
                throw e;
            }
        },

        async addItems(items: ContextItem[]) {
            if (items.length === 0) return;
            const report = await api.report_add_documents(
                get().report.id,
                items.map(encodeContextItem),
            );
            const em = getEntityMaps(report); //TODO check
            const new_document_ids = difference(
                Array.from(em.documents.keys()),
                Array.from(get().documents.keys()),
            );

            // update state
            set((s) => ({
                documents: new Map([
                    ...s.documents.entries(),
                    ...em.documents.entries(),
                ]),
                finding_groups: new Map([
                    ...s.finding_groups.entries(),
                    ...em.finding_groups.entries(),
                ]),
                documents_to_finding_groups: mapMerge(
                    s.documents_to_finding_groups,
                    em.documents_to_finding_groups,
                    (a, b) => unique([...a, ...b]),
                ),
            }));

            // fetch new finding groups
            const findingGroups = await api.fetch_document_finding_groups(
                report.id,
                new_document_ids,
            );
            for (let i = findingGroups.length - 1; i >= 0; --i) {
                get().updateFindingGroup(
                    createInitialAsyncFindingGroup(findingGroups[i]),
                );
            }
        },

        async deleteItems(ids) {
            const reportID = get().report.id;
            await Promise.all(
                ids.map((id) => api.report_remove_documents(reportID, id)),
            );
            set((s) => ({
                documents: mapDeleteAll(s.documents, ids),
                documents_to_finding_groups: mapDeleteAll(
                    s.documents_to_finding_groups,
                    ids,
                ),
                finding_groups: mapDeleteAll(
                    s.finding_groups,
                    ids.flatMap(
                        (id) => s.documents_to_finding_groups.get(id) ?? [],
                    ),
                ),
            }));
        },

        async invalidateDocument(document_id: UUID) {
            await api.report_invalidate_document(report.id, document_id);
            // remove all the finding groups that are associated with this document
            set((s) => {
                const ids =
                    s.documents_to_finding_groups.get(document_id) ?? [];
                return {
                    documents_to_finding_groups: mapDelete(
                        s.documents_to_finding_groups,
                        document_id,
                    ),
                    finding_groups: mapDeleteAll(s.finding_groups, ids),
                };
            });
        },

        async invalidateColumn(
            findingGroupType: FindingGroupType,
            probeID?: UUID,
        ) {
            await api.report_invalidate_by_finding_group(
                report.id,
                findingGroupType,
                probeID,
            );
            // remove all the finding groups that are associated with this column
            set((s) => {
                const ids = Array.from(s.finding_groups.entries())
                    .map(([id, fg]) => {
                        if (
                            findingGroupType ===
                            FindingGroupType.user_defined_text
                        ) {
                            if (fg.probe?.id === probeID) {
                                return id;
                            }
                        } else if (fg.type === findingGroupType) {
                            return id;
                        }
                        return null;
                    })
                    .filter(nonNull);
                return {
                    documents_to_finding_groups: mapMap(
                        s.documents_to_finding_groups,
                        (fg_ids) => fg_ids.filter((id) => !ids.includes(id)),
                    ),
                    finding_groups: mapDeleteAll(s.finding_groups, ids),
                };
            });
        },

        updateDocumentStatus(id, status) {
            set((s) => ({
                documents: mapUpdateIfExists(s.documents, id, (info) => ({
                    ...info,
                    status,
                })),
            }));
        },

        addFindingGroup(finding_group) {
            set((s) => ({
                finding_groups: mapAddEntity(
                    s.finding_groups,
                    createQueuedAsyncFindingGroup(finding_group),
                ),
                documents_to_finding_groups: mapUpdate(
                    s.documents_to_finding_groups,
                    finding_group.document_id,
                    (val) => unique([...(val ?? []), finding_group.id]),
                ),
            }));
        },

        updateFindingGroup(finding_group) {
            set((s) => ({
                finding_groups: mapUpdateIfExists(
                    s.finding_groups,
                    finding_group.id,
                    (fg) => {
                        return {
                            ...fg,
                            ...finding_group,
                        } as AsyncFindingGroup;
                    },
                ),
            }));
        },

        fetchFindings(finding_group_id) {
            set((s) => ({
                finding_groups: mapUpdateIfExists(
                    s.finding_groups,
                    finding_group_id,
                    (fg) => ({
                        ...fg,
                        findings: { state: AsyncState.fetching },
                    }),
                ),
            }));

            api.fetch_analysis_by_id(finding_group_id, {
                load_pills: true,
            }).then(
                (res) =>
                    set((s) => ({
                        finding_groups: mapSet(
                            s.finding_groups,
                            finding_group_id,
                            createSuccessAsyncFindingGroup(res),
                        ),
                    })),
                (error) =>
                    set((s) => ({
                        finding_groups: mapUpdateIfExists(
                            s.finding_groups,
                            finding_group_id,
                            (fg) => ({
                                ...fg,
                                findings: {
                                    state: AsyncState.error,
                                    error,
                                },
                            }),
                        ),
                    })),
            );
        },

        async retryFailedFindingGroup(finding_group_id) {
            await api.retry_failed_finding_group(
                get().report.id,
                finding_group_id,
            );
            set((s) => ({
                finding_groups: mapUpdateIfExists(
                    s.finding_groups,
                    finding_group_id,
                    (fg) => ({
                        ...fg,
                        findings: { state: AsyncState.fetching },
                    }),
                ),
            }));
        },

        async addColumn(column) {
            const result = await api.report_add_column(get().report.id, column);
            set((s) => ({
                columns: result.columns,
                columnOrder: [...s.columnOrder, getColumId(result.added)],
                columnFocus: getColumId(result.added),
            }));

            api.fetch_finding_groups_for_column(
                get().report.id,
                result.added.finding_group_type,
                result.added.column_type === ColumnType.user_defined
                    ? result.added.details.id
                    : undefined,
            ).then((finding_group_infos) =>
                set((s) => ({
                    finding_groups: mapSetAll(
                        s.finding_groups,
                        finding_group_infos
                            .map(createSuccessAsyncFindingGroup)
                            .map(toTuple),
                    ),
                    documents_to_finding_groups: mapMerge(
                        s.documents_to_finding_groups,
                        new Map(
                            finding_group_infos.map((finding_group) => [
                                finding_group.document_id,
                                [finding_group.id],
                            ]),
                        ),
                        (a, b) => unique([...a, ...b]),
                    ),
                })),
            );
        },

        async updateColumn(probe_id, payload) {
            const result = await api.report_update_column(
                get().report.id,
                probe_id,
                payload,
            );
            const removedID = getColumId(result.removed);
            set((s) => ({
                columns: result.columns,
                columnOrder: s.columnOrder.map((c) =>
                    c === removedID ? getColumId(result.added) : c,
                ),
                columnFocus: getColumId(result.added),
            }));
        },

        async deleteColumn(column) {
            const payload: RemoveColumnPayload =
                column.column_type === ColumnType.user_defined
                    ? {
                          column_type: ColumnType.user_defined,
                          id: column.id,
                      }
                    : {
                          column_type: ColumnType.system,
                          finding_group_type: column.finding_group_type,
                      };
            const result = await api.report_remove_column(
                get().report.id,
                payload,
            );
            const removedID = getColumId(result.removed);
            set((s) => {
                const removeIds = new Set(
                    Array.from(s.finding_groups.values())
                        .filter(
                            column.column_type === ColumnType.user_defined
                                ? (fg) => fg.probe?.id === column.id
                                : (fg) => fg.type === column.finding_group_type,
                        )
                        .map((fg) => fg.id),
                );
                return {
                    columns: result.columns,
                    columnOrder: s.columnOrder.filter((c) => c !== removedID),
                    finding_groups: mapDeleteAll(s.finding_groups, removeIds),
                    documents_to_finding_groups: mapMap(
                        s.documents_to_finding_groups,
                        (fg_ids) => fg_ids.filter((id) => !removeIds.has(id)),
                    ),
                };
            });
        },

        setColumnSizing(columnSizing) {
            set({ columnSizing });
        },

        setColumnOrder(updaterOrValue) {
            if (typeof updaterOrValue === "function") {
                set((s) => ({
                    columnOrder: updaterOrValue(s.columnOrder),
                }));
            } else {
                set({ columnOrder: updaterOrValue });
            }
        },

        setSorting(updaterOrValue) {
            if (typeof updaterOrValue === "function") {
                set((s) => ({ sorting: updaterOrValue(s.sorting) }));
            } else {
                set({ sorting: updaterOrValue });
            }
        },

        clearColumnFocus() {
            set({ columnFocus: null });
        },

        setColumnFilters(updaterOrValue) {
            if (typeof updaterOrValue === "function") {
                set((s) => ({
                    columnFilters: updaterOrValue(s.columnFilters),
                }));
            } else {
                set({ columnFilters: updaterOrValue });
            }
        },

        addColumnFilter(filter) {
            set((s) => ({
                columnFilters: uniqueBy(
                    [...s.columnFilters, filter],
                    (f) => f.id,
                ),
            }));
        },
    });

/**
 * Create Grid View Store with the following middlewares:
 * - onChange: Sync versioned client state to the server if if changes
 *       This persists the users settings (column order, column size, filter, ...)
 * - onChange: Fetch findings for any finding group that has queued findings
 */
export const createGridViewStore = (props: Dependencies & Props) =>
    createStore<State>()(
        onChangeMiddleware(
            onChangeMiddleware(
                initializer(props),
                (state) =>
                    Array.from(state.finding_groups.values()).filter(
                        (fg) =>
                            fg.status == FindingGroupStatus.completed &&
                            fg.findings.state === AsyncState.queued,
                    ),
                (queuedFindingGroups, state) => {
                    for (const fg of queuedFindingGroups) {
                        state.fetchFindings(fg.id);
                        log(
                            "[gridview] %cfetch-findings",
                            "font-weight:bold",
                            fg.id,
                        );
                    }
                },
                (a, b) => stableHash(a) === stableHash(b),
            ),
            getVersionedState,
            (versionedState) => {
                props.api.update_grid_view_state(
                    props.report.id,
                    versionedState,
                );
                log(
                    "[gridview] %csync-client-state",
                    "font-weight:bold",
                    versionedState,
                );
            },
            (a, b) => stableHash(a) === stableHash(b),
        ),
    );

const getEntityMaps = (report: FullDocumentCollection): EntityMaps => ({
    documents: new Map(report.documents.map((doc) => toTuple(doc.info))),
    finding_groups: new Map(
        report.documents.flatMap((doc) =>
            doc.finding_groups.map(createInitialAsyncFindingGroup).map(toTuple),
        ),
    ),
    documents_to_finding_groups: new Map(
        report.documents.map((doc) => [
            doc.info.id,
            unique(doc.finding_groups.map((fg) => fg.id)),
        ]),
    ),
});

export const getSerializableClientState = (
    s: State,
): SerializableClientState => ({
    columnSizing: s.columnSizing,
    columnOrder: s.columnOrder,
    sorting: s.sorting,
    columnFilters: s.columnFilters,
});

export const getVersionedState = (
    s: State,
): VersionedState<Record<string, unknown>> => ({
    version: VERSION,
    state: getSerializableClientState(s),
});

const migrate = ({
    version,
    state,
}: VersionedState<Record<string, unknown>>): SerializableClientState => {
    if (version < VERSION) {
        const defaultKeys = new Set(Object.keys(DEFAULT_STATE));
        return {
            ...DEFAULT_STATE,
            ...objectMap.filter(state, (_, key) => defaultKeys.has(key)),
            columnOrder: ((state?.columnOrder ?? []) as ColumnOrderState).map(
                (col) =>
                    col.startsWith(FindingGroupType.user_defined_text)
                        ? col.split("::")[1]
                        : col,
            ),
            columnSizing: objectMap.mapKey(
                (state.columnSizing ?? {}) as ColumnSizingState,
                (key) =>
                    key.startsWith(FindingGroupType.user_defined_text)
                        ? key.split("::")[1]
                        : key,
            ),
            sorting: uniqueBy(
                [
                    ...DEFAULT_STATE.sorting,
                    ...((state?.sorting ?? []) as SortingState),
                ],
                (colSort) => colSort.id,
            ),
        };
    }
    return state as SerializableClientState;
};
