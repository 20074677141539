import { Table } from "@tanstack/react-table";
import { ChangeEvent, useState } from "react";

import { Column } from "@/api/types";
import { FilterPopover } from "@/components/document-table/filter/filter-popover";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import { Input } from "@/components/ui/input";
import { getColumId } from "@/utils/columns";
import {
    isEmptyOrNull,
    notEmptyOrNull,
    truncate,
} from "@/utils/string-helpers";

type Props = {
    column: Column;
    table: Table<DocumentWithAsyncFindings>;
    open?: boolean;
};

export const TextColumnFilter = (props: Props) => {
    const column = props.table.getColumn(getColumId(props.column));
    const filterValue = column?.getFilterValue() as
        | { contains: string }
        | undefined;
    const [value, setValue] = useState(filterValue?.contains ?? "");
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        column?.setFilterValue({ contains: e.target.value });
    };
    const clearFilter = () => column?.setFilterValue(undefined);
    const handleClose = () => {
        if (isEmptyOrNull(value)) {
            clearFilter();
        }
    };

    const filterBadge = notEmptyOrNull(filterValue?.contains) ? (
        <span className="rounded-full bg-muted px-2.5 py-1.5 text-xs transition-colors empty:hidden group-hover:bg-background">
            {truncate(filterValue.contains, 15)}
        </span>
    ) : undefined;

    return (
        <FilterPopover
            column={props.column}
            onClear={clearFilter}
            filterValue={filterBadge}
            className="space-y-2"
            onClose={handleClose}
        >
            <Input value={value} onChange={handleChange} autoFocus />
        </FilterPopover>
    );
};
