import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

import {
    ColumnDefinition,
    ColumnType,
    FindingGroupLaunchStatus,
} from "@/api/types";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import { DISABLED_FINDING_GROUP_TYPES } from "@/conf/grid-view";
import { useAsyncState } from "@/hooks/use-async-state";
import { useGridView } from "@/hooks/use-grid-view-context";
import { useUser } from "@/hooks/use-user";
import { cn } from "@/lib/utils";
import { hasBetaAccess } from "@/utils/user";

export const SystemColumns = () => {
    const isBetaUser = hasBetaAccess(useUser());
    const availableCols = useGridView(
        useShallow(
            (s) =>
                new Map(
                    Array.from(s.availableColumns.values())
                        .filter(
                            (c) =>
                                c.is_system &&
                                !DISABLED_FINDING_GROUP_TYPES.has(c.type) &&
                                (isBetaUser ||
                                    c.launch_status ==
                                        FindingGroupLaunchStatus.launched),
                        )
                        .sort((a, b) => a.type.localeCompare(b.type))
                        .map((col) => [col.type, col]),
                ),
        ),
    );

    const addedColTypes = useGridView(
        useShallow(
            (s) =>
                new Set(
                    Array.from(s.columns.values()).map(
                        (col) => col.finding_group_type,
                    ),
                ),
        ),
    );

    return (
        <div className="flex grow flex-col gap-1 overflow-y-scroll p-4">
            {Array.from(availableCols.values()).map((col) => (
                <SystemColumn
                    key={col.type}
                    column={col}
                    checked={addedColTypes.has(col.type)}
                />
            ))}
        </div>
    );
};

const SystemColumn = (props: {
    column: ColumnDefinition;
    checked: boolean;
}) => {
    const addColumn = useGridView((s) => s.addColumn);
    const deleteColumn = useGridView((s) => s.deleteColumn);

    const action = useAsyncState(
        async () => {
            if (props.checked) {
                await deleteColumn({
                    column_type: ColumnType.system,
                    finding_group_type: props.column.type,
                });
                return false;
            } else {
                await addColumn({
                    column_type: ColumnType.system,
                    finding_group_type: props.column.type,
                });
                return true;
            }
        },
        {
            onSuccess: (isChecked) =>
                toast.success(
                    `Analysis "${props.column.description}" ${isChecked ? "added" : "removed"}`,
                ),
        },
    );

    return (
        <label
            className={cn(
                "group flex items-center gap-4 rounded-lg border px-4 py-3 pr-2 hover:bg-muted/50",
                action.isSubmitting && "opacity-50",
            )}
            onClick={action.getEventHandler()}
            role="button"
        >
            <Checkbox checked={props.checked} />
            <div className="flex grow items-center gap-2">
                <ProbeTypeIcon
                    type={props.column.content_type}
                    className="shrink-0"
                />
                <p className="font-headline font-medium">
                    {props.column.description}
                </p>
            </div>
            <LaunchStatusBadge status={props.column.launch_status} />
        </label>
    );
};

const LaunchStatusBadge = (props: { status: FindingGroupLaunchStatus }) => {
    switch (props.status) {
        case FindingGroupLaunchStatus.deprecated:
        case FindingGroupLaunchStatus.launched:
            return null;
        case FindingGroupLaunchStatus.beta:
            return (
                <Badge variant="beta" className="text-green-500">
                    BETA
                </Badge>
            );
        case FindingGroupLaunchStatus.internal:
            return (
                <Badge
                    variant="outline"
                    className="border-destructive text-destructive"
                >
                    INTERNAL
                </Badge>
            );
        default:
            return props.status satisfies never;
    }
};
