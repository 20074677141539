import { APActionType, ContextStatus, DiscoverPanelStatus } from "@/api/types";
import { ActionIcon } from "@/components/action-message/action-icon";
import { Button } from "@/components/ui/button";
import { PopoverClose } from "@/components/ui/popover";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useContextContext } from "@/hooks/use-context-context";
import { getActionLabel } from "@/utils/actions";

type ActionButtonProps = {
    type: APActionType;
    highlightText?: string;
    context?: string[];
};

export const ActionButton = ({
    type,
    highlightText: selected_text,
    context,
}: ActionButtonProps) => {
    const sendAction = useActionsContext((s) => s.send);
    const contextReady = useContextContext(
        (s) => s.status >= ContextStatus.LOADED,
    );
    const actionsReady = useActionsContext(
        (s) => s.status === DiscoverPanelStatus.IDLE,
    );
    const disabled = !contextReady || !actionsReady;
    return (
        <PopoverClose asChild>
            <Button
                size="xs"
                variant="secondary"
                disabled={disabled}
                onClick={() => sendAction(type, { selected_text, context })}
            >
                <ActionIcon
                    type={type}
                    className="mr-1 bg-transparent"
                    iconProps={{ className: "text-muted-foreground" }}
                />
                {getActionLabel(type)}
            </Button>
        </PopoverClose>
    );
};
