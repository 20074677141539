import { LDContext } from "launchdarkly-react-client-sdk";

import {
    Account,
    AccountType,
    OnboardingStatusEnum,
    User,
    UserType,
} from "@/api/types";
import { notEmptyOrNull } from "@/utils/string-helpers";

export const getOnboardingStatus = (
    entity: Account | User,
): OnboardingStatusEnum => {
    if (
        "account_deactivated_at" in entity &&
        entity.account_deactivated_at != null
    )
        return OnboardingStatusEnum.deactivated;
    if (entity.deactivated_at != null) return OnboardingStatusEnum.deactivated;
    if (entity.onboarded_at != null) return OnboardingStatusEnum.onboarded;
    return OnboardingStatusEnum.created;
};

export const isUserTypeAdmin = (user: User | null): boolean =>
    user != null && user.user_type === UserType.admin;

export const isUserSuperAdmin = (user: User): boolean =>
    user.account_type === AccountType.admin;

export const hasBetaAccess = (user: User | null): boolean =>
    user != null && user?.beta_access === true;

export const getName = ({ first_name, last_name }: User): string =>
    [last_name, first_name]
        .filter(notEmptyOrNull)
        .map((s) => s.trim())
        .filter(notEmptyOrNull)
        .join(", ");

export const getFirstLastName = ({ first_name, last_name }: User): string =>
    [first_name, last_name]
        .filter(notEmptyOrNull)
        .map((s) => s.trim())
        .filter(notEmptyOrNull)
        .join(" ");

export const deactivatedAt = (entity: Account | User): string | null => {
    if (
        "account_deactivated_at" in entity &&
        entity.account_deactivated_at != null
    ) {
        return entity.account_deactivated_at;
    }
    return entity.deactivated_at;
};

export const canSeeBillingSettings = (user: User | null): user is User => {
    if (user == null || !user.paid) return false;
    if (user.account_type === AccountType.individual) return true;
    if (user.account_type === AccountType.organization)
        return user.user_type === UserType.admin;
    return false;
};

export const canSeeManageUsersSettings = (user: User | null): boolean =>
    user != null &&
    user.account_type === AccountType.organization &&
    user.user_type === UserType.admin;

export const getGreeting = (user: User | null): string =>
    user && notEmptyOrNull(user.first_name)
        ? `Hello ${user.first_name}.`
        : "Hello.";

export const getLDContext = (user: User): LDContext => {
    const accountCtx =
        user.account_type === AccountType.organization
            ? {
                  key: user.account_id,
                  name: user.account_name,
              }
            : undefined;
    const userCtx = {
        key: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.username,
        type: user.user_type,
        paid: user.paid,
        status: user.status,
    };
    if (accountCtx !== undefined) {
        return {
            kind: "multi",
            user: userCtx,
            account: accountCtx,
        };
    }
    return {
        kind: "user",
        ...userCtx,
    };
};
