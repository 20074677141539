import { PropsWithChildren } from "react";

import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";

type Props = {
    title: string;
    opened: boolean;
    onClose: () => void;
};

export const ModalWithTitle = (props: PropsWithChildren<Props>) => {
    const onOpenChange = (opened: boolean) => {
        if (!opened) {
            props.onClose();
        }
    };
    return (
        <Dialog open={props.opened} onOpenChange={onOpenChange}>
            <DialogContent>
                <DialogTitle>{props.title}</DialogTitle>
                {props.children}
            </DialogContent>
        </Dialog>
    );
};
