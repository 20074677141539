import { useState } from "react";

import { setAdd, setAddAll, setRemove, setToggle } from "@/utils/es6-set";

export const useSet = <Tv>(iterable?: Iterable<Tv> | null) => {
    const [state, setState] = useState(new Set(iterable));

    return [
        state,
        {
            add(item: Tv) {
                setState((set) => setAdd(set, item));
            },
            addAll(items: Tv[]) {
                setState((set) => setAddAll(set, items));
            },
            remove(item: Tv) {
                setState((set) => setRemove(set, item));
            },
            toggle(item: Tv, state?: boolean) {
                setState((set) => setToggle(set, item, state));
            },
            has(item: Tv) {
                return state.has(item);
            },
            clear() {
                setState(new Set());
            },
        },
    ] as const;
};
