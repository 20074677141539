import { Plus } from "lucide-react";
import { use, useState } from "react";
import useSWRInfinite from "swr/infinite";

import { CreateUserData } from "@/api/rest";
import { Sorting, SortingDirection, User } from "@/api/types";
import { CreateUserForm } from "@/components/admin/create-user-form";
import { UsersTable } from "@/components/admin/users-table";
import { ErrorAlert } from "@/components/error-alert";
import { InfiniteScroll } from "@/components/infinite-scroll";
import { ModalWithTitle } from "@/components/modals/with-title";
import { Button } from "@/components/ui/button";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ManageUserContext } from "@/context/manage-user-context";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";
import { usePageTitle } from "@/hooks/use-page-title";
import { useUser } from "@/hooks/use-user";
import { last } from "@/utils/collection";
import { getCursorKeyFn } from "@/utils/pagination";
import { serializeSorting } from "@/utils/sorting";

const activeToBool = (val: string): boolean | null => {
    if (val === "active") return true;
    if (val === "inactive") return false;
    return null;
};

const pageSize = 25;

export const ManageUsersSettings = () => {
    usePageTitle(["Manage Users", "Settings"]);

    const api = useApi();
    const user = useUser();
    const { settings, activeUserCount } = use(ManageUserContext);

    const [createUserModal, createUserModalActions] = useBoolean();
    const [sortedBy, setSortedBy] = useState<Sorting | null>({
        sorted_by: "created_at",
        direction: SortingDirection.ASC,
    });
    const [active, setActive] = useState<string>("active");

    const { data, isValidating, error, setSize, mutate } = useSWRInfinite(
        getCursorKeyFn<User>(
            pageSize,
            `${serializeSorting(sortedBy)}_${active}_${user.account_id}_users_list`,
        ),
        async ({ pagination }) =>
            await api.fetch_account_users({
                sorting: sortedBy,
                active: activeToBool(active),
                pagination,
            }),
    );

    const deactivateUser = useAsyncState(
        async (user: User) => await api.deactivate_user(user.id),
        {
            onSuccess: () => {
                mutate();
                activeUserCount.mutate();
            },
        },
    );

    const users = (data ?? []).flatMap((page) => page.items);

    const createUser = useAsyncState(
        async (invite_info: CreateUserData) =>
            await api.create_user(invite_info, { send_invite: true }),
        {
            onSuccess: () => {
                mutate();
                createUserModalActions.close();
            },
        },
    );

    const canCreateUser = activeUserCount.data < settings.seats.total;

    return (
        <div className="col-span-2 space-y-8 lg:col-span-3 xl:col-span-4">
            <h1 className="text-headline text-2xl font-medium">Manage Users</h1>
            <div className="flex justify-between gap-4">
                <Tabs value={active} onValueChange={setActive}>
                    <TabsList>
                        <TabsTrigger value="all">All</TabsTrigger>
                        <TabsTrigger value="active">Active</TabsTrigger>
                        <TabsTrigger value="inactive">Deactivated</TabsTrigger>
                    </TabsList>
                </Tabs>
                <div className="flex items-center gap-4">
                    <span className="text-sm text-gray-500">
                        {activeUserCount.data}
                        {" / "}
                        {settings.seats.total} seats used
                    </span>
                    <Button
                        variant="outline"
                        onClick={createUserModalActions.open}
                        disabled={!canCreateUser}
                    >
                        <Plus className="mr-2 h-4 w-4" />
                        Add User
                    </Button>
                </div>
            </div>
            <InfiniteScroll
                lookahead="10%"
                isLoading={isValidating}
                hasNextPage={
                    (data && last(data)?.page_info.has_next_page) ?? false
                }
                onNextPage={() => setSize((s) => s + 1)}
            >
                <UsersTable
                    hideID
                    users={users}
                    isAccountOnboarded={true}
                    onDeactivateUser={deactivateUser}
                    sortedBy={sortedBy}
                    onSort={setSortedBy}
                />
                {error && <ErrorAlert error={error} />}
            </InfiniteScroll>
            <ModalWithTitle
                opened={createUserModal}
                onClose={createUserModalActions.close}
                title="Create User"
            >
                <CreateUserForm action={createUser} />
            </ModalWithTitle>
        </div>
    );
};
