import { useEffect, useState } from "react";

type Options = {
    delay: number;
};

export const useDebouncedValue = <T>(
    value: T,
    { delay }: Options = { delay: 700 },
): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};
