import { Table } from "@tanstack/react-table";
import { PropsWithChildren } from "react";

import { Column, ProbeType } from "@/api/types";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { useFilter } from "@/components/document-table/filter/use-filter";
import { DocumentWithAsyncFindings } from "@/components/document-table/power-table";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { useBoolean } from "@/hooks/use-boolean";
import { useColumnType } from "@/hooks/use-column-type";
import { useGridView } from "@/hooks/use-grid-view-context";
import { getColumId, getColumnTitle } from "@/utils/columns";
import { getNumericalFilterRange } from "@/utils/filter";

type Props = PropsWithChildren<{
    table: Table<DocumentWithAsyncFindings>;
    onFilterAdd?: () => void;
}>;

export const AddFilter = (props: Props) => {
    const [open, openActions] = useBoolean();
    const allColumns = useGridView((s) => s.columns);
    const setOpenFilter = useFilter((s) => s.setOpenFilter);
    const addColumnFilter = useGridView((s) => s.addColumnFilter);
    const addFilter = (column: Column, value?: unknown) => {
        const id = getColumId(column);
        addColumnFilter({ id, value });
        setOpenFilter(id);
        props.onFilterAdd?.();
    };
    const filters = props.table.getState().columnFilters;
    const filterIDs = new Set(filters.map((f) => f.id));
    const columnDefinitions = useGridView((s) => s.availableColumns);
    const availableColumns = allColumns
        .filter((col) => !filterIDs.has(getColumId(col)))
        .map((column) => ({
            title: getColumnTitle(column, columnDefinitions),
            column,
        }))
        .sort((a, b) => a.title.localeCompare(b.title));

    return (
        <Popover open={open} onOpenChange={openActions.set}>
            <PopoverTrigger asChild>{props.children}</PopoverTrigger>
            <PopoverContent
                className="flex flex-col p-0"
                collisionPadding={5}
                style={{
                    maxHeight: "var(--radix-popper-available-height)",
                }}
            >
                <Command>
                    <CommandInput />
                    <CommandList className="p-1">
                        <CommandEmpty>No filters found.</CommandEmpty>
                        {availableColumns.map(({ title, column }) => (
                            <ColumnRow
                                key={getColumId(column)}
                                table={props.table}
                                title={title}
                                column={column}
                                onSelect={(value) => {
                                    openActions.close();
                                    addFilter(column, value);
                                }}
                            />
                        ))}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};

const ColumnRow = (props: {
    title: string;
    column: Column;
    table: Table<DocumentWithAsyncFindings>;
    onSelect: (value?: unknown) => void;
}) => {
    const type = useColumnType(props.column);
    const handleClick = () => {
        switch (type) {
            case ProbeType.boolean:
                props.onSelect(true);
                break;
            case ProbeType.number:
                const columnId = getColumId(props.column);
                const range = getNumericalFilterRange(
                    columnId,
                    props.table.getColumn(columnId),
                );
                props.onSelect(
                    range !== undefined
                        ? { min: range[0], max: range[1] }
                        : undefined,
                );
                break;
            default:
                props.onSelect({ contains: "" });
                break;
        }
    };

    return (
        <CommandItem className="gap-2" onSelect={handleClick}>
            <ProbeTypeIcon type={type} />
            {props.title}
        </CommandItem>
    );
};
