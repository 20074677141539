import { useNavigate } from "react-router-dom";

import { MessageForm } from "@/components/action-panel/message-form";
import { Headline } from "@/components/headline";
import { useApi } from "@/hooks/use-api";
import { AppLayout } from "@/layouts/app-layout";

export const ExploreContainer = () => {
    const api = useApi();
    const navigate = useNavigate();
    const onSubmit = async (query: string) => {
        const res = await api.create_search_action(query);
        navigate(`/explore/${res.id}`);
        return;
    };
    return (
        <AppLayout className="mx-auto flex min-h-[72vh] max-w-screen-md flex-col justify-center gap-10">
            <Headline className="mx-8 bg-gradient-to-br from-foreground to-blue-500 bg-clip-text py-2.5 text-transparent">
                Insight at your fingertips
            </Headline>
            <MessageForm
                onMessage={onSubmit}
                placeholder="Ask anything..."
                autoFocus
            />
        </AppLayout>
    );
};
