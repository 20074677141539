import { PropsWithChildren, ReactNode, use } from "react";
import { Outlet } from "react-router-dom";

import { AccountType, AuthLevel, User, UserType } from "@/api/types";
import { AppContext } from "@/context/app-context";

const maxAuthLevel = (max: AuthLevel, level: AuthLevel): AuthLevel =>
    level > max ? max : level;

const getAuthLevelFormUserType = (user_type: UserType): AuthLevel => {
    switch (user_type) {
        case UserType.admin:
            return AuthLevel.admin;
        case UserType.creator:
            return AuthLevel.creator;
        case UserType.viewer:
            return AuthLevel.viewer;
        default:
            return user_type satisfies never;
    }
};

const getAuthLevel = (user: User | null): AuthLevel => {
    if (!user) return AuthLevel.logged_out;
    if (user.account_type === AccountType.admin) return AuthLevel.superadmin;
    const authLevel = getAuthLevelFormUserType(user.user_type);

    if (user.account_type === AccountType.individual)
        return maxAuthLevel(AuthLevel.creator, authLevel);

    return authLevel;
};

type MinProps = { min: AuthLevel };
type PredicateProps = { predicate: (user: User | null) => boolean };
type MaxProps = { max: AuthLevel };

type Props = (MinProps | MaxProps | PredicateProps) & {
    fallback?: ReactNode;
};

export const AuthGate = (props: PropsWithChildren<Props>) => {
    const { user } = use(AppContext);

    if ("predicate" in props) {
        return props.predicate(user)
            ? (props.children ?? <Outlet />)
            : props.fallback;
    }

    const authLevel = getAuthLevel(user);

    if ("min" in props && props.min <= authLevel) {
        return props.children ?? <Outlet />;
    }

    if ("max" in props && props.max >= authLevel) {
        return props.children ?? <Outlet />;
    }

    return props.fallback ?? null;
};
