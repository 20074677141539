import { UserType } from "@/api/types";
import { Badge } from "@/components/ui/badge";

export const UserTypeBadge = (props: { type: UserType }) => {
    switch (props.type) {
        case UserType.admin:
            return (
                <Badge className="bg-orange-500/15 text-orange-700 hover:bg-orange-500/20 dark:text-orange-300">
                    Admin
                </Badge>
            );
        case UserType.creator:
            return (
                <Badge className="bg-blue-500/15 text-blue-700 hover:bg-blue-500/20 dark:text-blue-300">
                    Creator
                </Badge>
            );
        case UserType.viewer:
            return (
                <Badge className="bg-gray-500/15 text-gray-700 hover:bg-gray-500/20 dark:text-gray-300">
                    Creator
                </Badge>
            );
        default:
            return props.type satisfies never;
    }
};
