import { PropsWithChildren, useEffect, useRef } from "react";
import { useStore } from "zustand";

import { APActionCitation, DocumentInfo } from "@/api/types";
import { CitatonContext } from "@/context/citation-context";
import { CitationsStore, createCitationsStore } from "@/stores/citations";
import { toTuple } from "@/utils/entity";

type Props = {
    citations?: APActionCitation[];
    documents?: DocumentInfo[];
};

export const CitationContextProvider = ({
    citations,
    documents,
    children,
}: PropsWithChildren<Props>) => {
    const citationMap = new Map(citations?.map(toTuple));
    const documentMap = new Map(documents?.map(toTuple));

    // eslint-disable-next-line react-compiler/react-compiler
    const store = useRef<CitationsStore>(
        createCitationsStore({
            citations: citationMap,
            documents: documentMap,
        }),
    ).current;

    const updateCitations = useStore(store, (s) => s.updateCitations);
    useEffect(() => {
        updateCitations(citationMap);
    }, [updateCitations, citationMap]);

    const updateDocuments = useStore(store, (s) => s.updateDocuments);
    useEffect(() => {
        updateDocuments(documentMap);
    }, [updateDocuments, documentMap]);

    return <CitatonContext value={store}>{children}</CitatonContext>;
};
