import { v4 as uuidv4 } from "uuid";

import { EventHandler } from "@/api/ws/websocket-client";
import { InboundWebsocketEvents } from "@/api/ws/websocket-types";

export type HandlersByEvent = {
    [Event in keyof InboundWebsocketEvents]?: {
        [handler_id: string]: EventHandler<InboundWebsocketEvents[Event]>;
    };
};

type HandlerIDToEventKey = {
    [handler_id: string]: keyof InboundWebsocketEvents;
};

export class HandlerMap {
    private handlers_by_event: HandlersByEvent = {};
    private handler_id_to_event_key: HandlerIDToEventKey = {};

    public constructor() {}

    public add_handler<Tk extends keyof InboundWebsocketEvents>(
        event: Tk,
        handler: EventHandler<InboundWebsocketEvents[Tk]>,
        handler_id?: string,
    ): string {
        const safe_handler_id = handler_id || uuidv4();

        this.handlers_by_event[event] = {
            ...this.handlers_by_event[event],
            [safe_handler_id]: handler as EventHandler<
                InboundWebsocketEvents[keyof InboundWebsocketEvents]
            >,
        };
        this.handler_id_to_event_key[safe_handler_id] = event;

        return safe_handler_id;
    }

    public remove_handler(handler_id: string): void {
        if (!(handler_id in this.handler_id_to_event_key)) {
            throw new Error(`Unknown handler_id ${handler_id}`);
        }

        const event = this.handler_id_to_event_key[handler_id];
        delete this.handler_id_to_event_key[handler_id];
        const handler = this.handlers_by_event[event];
        if (handler != undefined) {
            delete handler[handler_id];

            if (Object.values(handler).length <= 0) {
                delete this.handlers_by_event[event];
            }
        }
    }

    public by_event(): HandlersByEvent {
        return this.handlers_by_event;
    }
}
