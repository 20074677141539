import { zodResolver } from "@hookform/resolvers/zod";
import { HTMLAttributes } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseAsyncState } from "@/hooks/use-async-state";

const formSchema = z.object({
    text: z.string().min(1),
});

type FormSchema = z.infer<typeof formSchema>;

interface Props extends HTMLAttributes<HTMLFormElement> {
    text?: string;
    placeholder?: string;
    action: UseAsyncState<[string], unknown>;
    onCancel?: () => void;
}

export const InlineTextForm = ({
    text,
    placeholder,
    onCancel,
    action,
    className,
    ...props
}: Props) => {
    const form = useForm<FormSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: { text: text },
    });

    const handleSubmit = form.handleSubmit(
        ({ text }) => action.submit(text),
        onCancel,
    );

    return (
        <Form {...form}>
            <form onSubmit={handleSubmit} className="w-full" {...props}>
                <FormField
                    control={form.control}
                    name="text"
                    render={({ field }) => (
                        <FormItem className="w-full">
                            <FormControl>
                                <Input
                                    type="text"
                                    placeholder={placeholder}
                                    className={className}
                                    autoFocus
                                    {...field}
                                    onBlur={handleSubmit}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />
            </form>
        </Form>
    );
};
