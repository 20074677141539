import { RefreshCw } from "lucide-react";
import { toast } from "sonner";

import {
    AsyncFindingGroup,
    FindingGroupStatus,
    DocumentInfo,
} from "@/api/types";
import { CellState } from "@/components/analyze/cell-state";
import { AsyncButton } from "@/components/async-button";
import { Debugger } from "@/components/debug/debugger";
import { CondensedFindingGroup } from "@/components/document-table/condensed/finding-group";
import { useAsyncState } from "@/hooks/use-async-state";
import { useGridView } from "@/hooks/use-grid-view-context";
import { AsyncState } from "@/utils/async-value";

export const FindingGroupContent = ({
    documentInfo,
    findingGroup,
}: {
    documentInfo: DocumentInfo;
    findingGroup: AsyncFindingGroup;
}) => {
    const retryFailedFindingGroup = useGridView(
        (s) => s.retryFailedFindingGroup,
    );
    const retry = useAsyncState(
        async () => await retryFailedFindingGroup(findingGroup.id),
        { onError: () => toast.error("Failed to regenerate the analysis") },
    );

    switch (findingGroup.status) {
        case FindingGroupStatus.processing:
            return <CellState variant="loading">Analyzing...</CellState>;
        case FindingGroupStatus.cancelled:
        case FindingGroupStatus.failed:
            return (
                <AsyncButton
                    action={retry}
                    variant="ghost-destructive"
                    size="xs"
                    className="text-destructive"
                >
                    <RefreshCw className="mr-2 size-4" />
                    Analysis Failed. Retry...
                </AsyncButton>
            );
        case FindingGroupStatus.completed:
            switch (findingGroup.findings.state) {
                case AsyncState.initial:
                case AsyncState.queued:
                case AsyncState.fetching:
                    return (
                        <CellState variant="loading">
                            Loading...
                            <Debugger>
                                <span className="ml-2 font-mono font-bold">
                                    {AsyncState[findingGroup.findings.state]}
                                </span>
                            </Debugger>
                        </CellState>
                    );
                case AsyncState.error:
                    return (
                        <CellState variant="loading">
                            Failed to load analysis
                        </CellState>
                    );
                case AsyncState.success:
                    return (
                        <CondensedFindingGroup
                            documentInfo={documentInfo}
                            groupInfo={findingGroup}
                            findings={findingGroup.findings.value}
                        />
                    );
            }
        default:
            return findingGroup.status satisfies never;
    }
};
