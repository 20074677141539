import { useLayoutEffect, useState } from "react";

import { useDebouncedValue } from "@/hooks/use-debounced-value";
import { useDevMode } from "@/hooks/use-dev-mode";
import { SelectionDetails, useSelection } from "@/hooks/use-selection";
import { last } from "@/utils/collection";
import { findParentWithOverflowHidden } from "@/utils/dom";
import { Rect, containsRect, fromDOMRect, translateY } from "@/utils/geometry";
import { isEmptyOrNull } from "@/utils/string-helpers";

const insideBoundingClientRect =
    (boundingClientRect: DOMRect) => (domRect: DOMRect) =>
        containsRect(fromDOMRect(boundingClientRect), fromDOMRect(domRect));

// traverse up in the dom tree, collecting all action contexts in a set
const collectActionContext = (selection: SelectionDetails): string[] => {
    let el = selection.range.commonAncestorContainer.parentElement;
    // todo handle selected paragraph and nodes inside the selection when neccessary
    if (el == null) {
        return [];
    }
    const set: Set<string> = new Set();
    do {
        if (!isEmptyOrNull(el.dataset.actionContext)) {
            set.add(el.dataset.actionContext);
        }
        el = el.parentElement;
    } while (el != null);
    return Array.from(set.values());
};

export type UseContextMenu = {
    selection: string;
    actionContext: string[];
    anchor: Rect;
    deselect: () => void;
};

export const useContextMenu = (
    deps?: ReadonlyArray<unknown>,
): UseContextMenu | undefined => {
    const [devMode] = useDevMode();
    const selection = useDebouncedValue(useSelection(), {
        delay: 200,
    });

    const [anchor, setAnchor] = useState<Rect | null>(null);

    useLayoutEffect(() => {
        const anchorElement = selection?.anchorNode.parentElement;
        if (!selection || !anchorElement) return;
        const updateAnchor = () => {
            const domRect = last(
                selection.selectedParagraph &&
                    selection.range.startContainer.parentElement
                    ? Array.from(selection.range.getClientRects()).filter(
                          insideBoundingClientRect(
                              selection.range.startContainer.parentElement.getBoundingClientRect(),
                          ),
                      )
                    : Array.from(selection.range.getClientRects()),
            );
            if (!domRect) return;
            const rect = fromDOMRect(domRect);
            setAnchor(translateY(rect, window.scrollY));
        };

        updateAnchor();

        const parent = findParentWithOverflowHidden(anchorElement);
        if (parent) {
            const resizeObserver = new ResizeObserver(updateAnchor);
            resizeObserver.observe(parent);

            parent.addEventListener("scroll", updateAnchor);

            return () => {
                parent.removeEventListener("scroll", updateAnchor);
                resizeObserver.disconnect();
            };
        }
    }, [selection, devMode, ...(deps || [])]);

    if (anchor == null || selection == null) {
        return undefined;
    }

    return {
        anchor,
        selection: selection.text,
        actionContext: collectActionContext(selection),
        deselect: () => {
            selection?.range.collapse();
        },
    };
};
