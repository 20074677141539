import { Column, ColumnType, ProbeType } from "@/api/types";
import { useGridView } from "@/hooks/use-grid-view-context";

export const useColumnType = (column: Column): ProbeType | undefined => {
    const availableColumns = useGridView((s) => s.availableColumns);

    switch (column.column_type) {
        case ColumnType.system:
            return availableColumns.get(column.finding_group_type)
                ?.content_type;
        case ColumnType.user_defined:
            return column.details.type;
    }
};
