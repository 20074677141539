import { AlertCircle, ArrowRight } from "lucide-react";
import { NavLink } from "react-router-dom";

import { Subscription } from "@/api/types";
import { Button } from "@/components/ui/button";
import { formatDate } from "@/utils/time";

export const TrialBanner = (props: { subscription: Subscription }) => {
    return (
        <div className="fixed left-0 right-0 top-0 z-50 flex h-10 items-center gap-3 bg-yellow-100 px-4 text-yellow-700 print:hidden">
            <AlertCircle className="size-4" />
            {props.subscription.trial_end && (
                <span>
                    Your trial will end on{" "}
                    {formatDate(props.subscription.trial_end)}.
                </span>
            )}
            <Button variant="link" className="text-yellow-700" asChild>
                <NavLink to="/settings/billing">
                    Add your payment information to avoid losing access to
                    Brightwave
                    <ArrowRight className="ml-2 size-4" />
                </NavLink>
            </Button>
        </div>
    );
};
