import { APActionType, GenericFindingContent, UUID } from "@/api/types";
import { ActionButton } from "@/components/document-table/action-button";
import { Separator } from "@/components/ui/separator";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";
import { getFindingContentForAction } from "@/utils/finding-group";
import { isEmptyOrNull, notEmptyOrNull } from "@/utils/string-helpers";

export const FindingActions = (props: {
    finding: GenericFindingContent;
    documentID?: UUID;
}) => {
    const chatEnabled = useFeatureFlagBool(FeatureFlagBool.chat_enabled, true);
    if (!chatEnabled) return null;
    const content = getFindingContentForAction(props.finding);
    if (isEmptyOrNull(content)) {
        return null;
    }
    const context = notEmptyOrNull(props.documentID)
        ? [`document:${props.documentID}`]
        : undefined;

    return (
        <>
            <Separator />
            <div className="flex justify-end gap-2">
                <ActionButton
                    type={APActionType.tell_me_more}
                    highlightText={content}
                    context={context}
                />
                <ActionButton
                    type={APActionType.implications}
                    highlightText={content}
                    context={context}
                />
            </div>
        </>
    );
};
