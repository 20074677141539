import { Children, HTMLAttributes, ReactNode } from "react";

import { cn } from "@/lib/utils";

interface PaginationProps extends HTMLAttributes<HTMLDivElement> {
    steps: number;
    activeStep: number;
}

export const StepperPagination = ({
    steps,
    activeStep,
    className,
    ...props
}: PaginationProps) => (
    <div className={cn("flex space-x-4", className)} {...props}>
        {Array(steps)
            .fill(0)
            .map((_, index) => (
                <div
                    key={index}
                    className={cn(
                        "h-2 w-2 rounded transition-all duration-150",
                        index === activeStep
                            ? "w-6 bg-foreground"
                            : "bg-foreground/30",
                    )}
                />
            ))}
    </div>
);

type StepperProps = {
    currentSlide: number;
    children: ReactNode;
};

export const Stepper = (props: StepperProps) => {
    const currentSlide = Math.min(
        Children.count(props.children) - 1,
        props.currentSlide,
    );
    const transform = `translate3d(${-100 * currentSlide}%,0,0)`;
    return (
        <div className="overflow-hidden">
            <div
                className="flex transform-gpu duration-150"
                style={{ transform }}
            >
                {Children.map(props.children, (child, i) => (
                    <div key={i} className="shrink-0 grow-0 basis-full">
                        {child}
                    </div>
                ))}
            </div>
        </div>
    );
};
