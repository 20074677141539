import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";
import { HTMLAttributes, PropsWithChildren } from "react";

import { Sorting, SortingDirection } from "@/api/types";
import { Button } from "@/components/ui/button";
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";

export type TableHeader = {
    key: string;
    label: string | null;
    sortable?: boolean;
    defaultDirection?: SortingDirection;
};

const Sortable = ({
    th,
    sorting,
    onSort,
}: {
    th: TableHeader;
    sorting?: Sorting | null;
    onSort?: (sorting: Sorting | null) => void;
}) => {
    if (!th.sortable || !onSort) return null;
    if (sorting && th.key === sorting.sorted_by) {
        switch (sorting.direction) {
            case "asc":
                return (
                    <Button
                        variant="ghost"
                        onClick={() =>
                            onSort({
                                sorted_by: th.key,
                                direction: SortingDirection.DESC,
                            })
                        }
                    >
                        {th.label} <ArrowDown className="ml-2 h-4 w-4" />
                    </Button>
                );
            case "desc":
                return (
                    <Button
                        variant="ghost"
                        onClick={() =>
                            onSort({
                                sorted_by: th.key,
                                direction: SortingDirection.ASC,
                            })
                        }
                    >
                        {th.label} <ArrowUp className="ml-2 h-4 w-4" />
                    </Button>
                );
        }
    }
    return (
        <Button
            variant="ghost"
            onClick={() =>
                onSort({
                    sorted_by: th.key,
                    direction: th.defaultDirection ?? SortingDirection.ASC,
                })
            }
        >
            {th.label} <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
    );
};

export type Props = {
    header?: TableHeader[];
    sortedBy?: Sorting | null;
    onSort?: (sorting: Sorting | null) => void;
};

export const AdminTable = ({
    header,
    sortedBy,
    onSort,
    children,
    ...props
}: PropsWithChildren<Props> & HTMLAttributes<HTMLTableElement>) => (
    <Table {...props}>
        {header && (
            <TableHeader>
                <TableRow>
                    {header.map((th) => (
                        <TableHead key={th.key}>
                            {th.sortable ? (
                                <Sortable
                                    th={th}
                                    sorting={sortedBy}
                                    onSort={onSort}
                                />
                            ) : (
                                th.label
                            )}
                        </TableHead>
                    ))}
                </TableRow>
            </TableHeader>
        )}
        <TableBody>{children}</TableBody>
    </Table>
);
