import { PropsWithChildren, ReactNode } from "react";
import { Outlet } from "react-router-dom";

import { CustomerStatus } from "@/api/types";
import { useUser } from "@/hooks/use-user";

type Props = {
    fallback?: ReactNode;
};

export const Paywall = (props: PropsWithChildren<Props>) => {
    const user = useUser();

    if (user.paid) {
        switch (user.status) {
            case CustomerStatus.subscription_confirmation_needed:
            case CustomerStatus.subscription_pending:
            case CustomerStatus.trial_pending:
            case CustomerStatus.billing_details_required:
            case CustomerStatus.payment_required:
            case CustomerStatus.lapsed:
                return props.fallback ?? null;

            case CustomerStatus.active:
            case CustomerStatus.trialing:
                return props.children ?? <Outlet />;
            default:
                return user.status satisfies never;
        }
    }

    return props.children ?? <Outlet />;
};
