import { HTMLAttributes } from "react";

import {
    APActionType,
    ContextStatus,
    FindingGroupType,
    DiscoverPanelStatus,
    DocumentFindingGroup,
    FindingType,
    GenericFindingContent,
    FindingContentType,
} from "@/api/types";
import { ActionIcon } from "@/components/action-message/action-icon";
import { FindingContent } from "@/components/document-table/finding-content";
import { Headline } from "@/components/headline";
import { Button } from "@/components/ui/button";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useContextContext } from "@/hooks/use-context-context";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";
import { cn } from "@/lib/utils";
import { getActionLabel } from "@/utils/actions";
import { unique } from "@/utils/collection";
import { getFindingContentForAction } from "@/utils/finding-group";
import { leadingZeros, pluralize } from "@/utils/string-helpers";

const ExecutiveSummaryKeyTakeaways = (props: {
    findings: GenericFindingContent[];
}) => {
    if (props.findings.length === 0) {
        return null;
    }
    return (
        <ul className="space-y-8">
            {props.findings.map((finding, i) => (
                <li key={finding.id} className="flex gap-4">
                    <span className="font-headline text-2xl font-black">
                        {leadingZeros(i + 1, 2)}
                    </span>
                    <FindingContent finding={finding} className="text-base" />
                </li>
            ))}
        </ul>
    );
};

interface ActionButtons extends HTMLAttributes<HTMLDivElement> {
    payload: string;
    context?: string[];
    actionTypes: APActionType[];
}

const ActionButtons = ({
    payload,
    actionTypes,
    context,
    className,
    ...props
}: ActionButtons) => {
    const sendAction = useActionsContext((s) => s.send);
    const contextReady = useContextContext(
        (s) => s.status >= ContextStatus.LOADED,
    );
    const actionsReady = useActionsContext(
        (s) => s.status === DiscoverPanelStatus.IDLE,
    );
    const disabled = !contextReady || !actionsReady;
    return (
        <div
            className={cn(
                "flex divide-x divide-border overflow-hidden rounded-lg border",
                className,
            )}
            {...props}
        >
            {unique(actionTypes).map((type) => (
                <Button
                    key={type}
                    variant="ghost"
                    className="flex-1 gap-1.5 rounded-none"
                    onClick={() =>
                        sendAction(type, { selected_text: payload, context })
                    }
                    disabled={disabled}
                >
                    <ActionIcon type={type} className="bg-transparent" />
                    {getActionLabel(type)}
                </Button>
            ))}
        </div>
    );
};

type Props = {
    findingGroup: DocumentFindingGroup;
};

export const DocumentFindingContent = ({ findingGroup }: Props) => {
    if (findingGroup.findings.length === 0) {
        return null;
    }

    switch (findingGroup.type) {
        case FindingGroupType.executive_summary:
            const keyTakeaways = findingGroup.findings.filter(
                (f) =>
                    f.finding_type ===
                    FindingType.executive_summary_key_takeaway,
            );
            return (
                <div className="grid gap-x-14 gap-y-10 @3xl:grid-cols-2 @5xl:gap-x-32">
                    <div className="space-y-8">
                        <Headline level={4} separator>
                            Synthesis
                        </Headline>
                        {findingGroup.findings
                            .filter(
                                (f) =>
                                    f.finding_type ===
                                    FindingType.executive_summary,
                            )
                            .map((finding) => (
                                <FindingContent
                                    key={finding.id}
                                    finding={finding}
                                    className="text-base"
                                />
                            ))}
                    </div>
                    {keyTakeaways.length > 0 && (
                        <div className="space-y-8">
                            <Headline level={4} separator>
                                Key Takeaways
                            </Headline>
                            <ExecutiveSummaryKeyTakeaways
                                findings={keyTakeaways}
                            />
                        </div>
                    )}
                </div>
            );
        case FindingGroupType.risks:
            return (
                <div className="grid gap-x-20 gap-y-12 @3xl:grid-cols-2">
                    <Headline level={2} className="col-span-full" highlighted>
                        {pluralize(findingGroup.findings.length, "Risk")}
                    </Headline>
                    {findingGroup.findings.map((finding) => (
                        <TitledTextFinding key={finding.id} finding={finding} />
                    ))}
                </div>
            );
        case FindingGroupType.opportunities:
            return (
                <div className="grid gap-x-20 gap-y-12 @3xl:grid-cols-2">
                    <Headline level={2} className="col-span-full" highlighted>
                        {pluralize(findingGroup.findings.length, "Opportunity")}
                    </Headline>
                    {findingGroup.findings.map((finding) => (
                        <TitledTextFinding key={finding.id} finding={finding} />
                    ))}
                </div>
            );
        default:
            return null;
    }
};

const TitledTextFinding = ({ finding }: { finding: GenericFindingContent }) => {
    const chatEnabled = useFeatureFlagBool(FeatureFlagBool.chat_enabled, true);
    switch (finding.content_type) {
        case FindingContentType.titled_long_text:
            return (
                <div className="space-y-6 border-t pt-4">
                    <h4 className="font-headline text-3xl font-bold">
                        {finding.title}
                    </h4>
                    <p>{finding.text}</p>
                    {chatEnabled && (
                        <ActionButtons
                            actionTypes={[
                                APActionType.show_evidence,
                                APActionType.tell_me_more,
                            ]}
                            payload={getFindingContentForAction(finding) ?? ""}
                        />
                    )}
                </div>
            );
        default:
            return null;
    }
};
