import { createContext } from "react";
import { SWRResponse } from "swr";

import { AccountSettings } from "@/api/types";

type TContext = {
    settings: AccountSettings;
    activeUserCount: SWRResponse<number, Error, { suspense: true }>;
};

export const ManageUserContext = createContext({} as TContext);
