export const setRemove = <T>(set: Set<T>, item: T): Set<T> => {
    if (!set.has(item)) return set;
    const result = new Set(set);
    result.delete(item);
    return result;
};

export const setRemoveAll = <T>(set: Set<T>, items: T[]): Set<T> => {
    if (items.length === 0) return set;
    const result = new Set(set);
    for (const item of items) {
        result.delete(item);
    }
    return result;
};

export const setAdd = <T>(set: Set<T>, item: T): Set<T> =>
    new Set(set).add(item);

export const setAddAll = <T>(set: Set<T>, items: T[]): Set<T> => {
    if (items.length === 0) return set;
    const result = new Set(set);
    for (const item of items) {
        result.add(item);
    }
    return result;
};

export const setToggle = <T>(set: Set<T>, item: T, state?: boolean): Set<T> =>
    (state !== undefined ? !state : set.has(item))
        ? setRemove(set, item)
        : setAdd(set, item);

export const setFilter = <T>(
    set: Set<T>,
    predicate: (item: T) => boolean,
): Set<T> => new Set(Array.from(set.values()).filter(predicate));
