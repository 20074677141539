import {
    ActionConfigBase,
    ActionLaunchStatus,
    APActionType,
} from "@/api/types";

type ActionConfigMap = {
    [key in APActionType]: ActionConfigBase;
};

export const actionConfigMap: ActionConfigMap = {
    [APActionType.chat_message]: {
        launchStatus: ActionLaunchStatus.LAUNCHED,
        label: "Inquiry",
        description: `Ask questions about anything covered by this report or the primary sources.
        Discover does not have access to other sources of information.`,
    },

    [APActionType.show_evidence]: {
        launchStatus: ActionLaunchStatus.LAUNCHED,
        label: "Show Evidence",
        description: `Highlight any text in the report to show inline citations from the
        underlying source documents.`,
    },

    [APActionType.tell_me_more]: {
        launchStatus: ActionLaunchStatus.LAUNCHED,
        label: "Tell Me More",
        description: `Select any text in the report to and ask Brightwave to provide a more
        detailed explanation.`,
    },

    [APActionType.market_commentary]: {
        launchStatus: ActionLaunchStatus.LAUNCHED,
        label: "Additional Commentary",
        description: `Highlight any text and generate a professional market commentary that
        reflects the current economic and financial landscape. This analysis will provide insights
        into recent data, trends, and events in the financial markets, along with their potential
        implications for investors and other stakeholders.`,
    },

    [APActionType.implications]: {
        launchStatus: ActionLaunchStatus.LAUNCHED,
        label: "Implications",
        description: `Highlight any text and receive a comprehensive analysis of its implications,
        considering potential risks, opportunities, and key considerations. This analysis draws
        from relevant information across all available sources to provide a thorough understanding
        of the highlighted content's impact.`,
    },

    [APActionType.follow_up_questions]: {
        launchStatus: ActionLaunchStatus.SUPERADMIN,
        label: "Follow-Up Questions",
        description: `Select any text from the report to generate questions to gain new
        perspectives and ideas to dive deeper into the topic.`,
    },

    [APActionType.search]: {
        launchStatus: ActionLaunchStatus.SUPERADMIN,
        label: "Search",
        description: `Search`,
    },

    [APActionType.thesis_generation]: {
        launchStatus: ActionLaunchStatus.SUPERADMIN,
        label: "Thesis Generation",
        description: `Highlight any text and generate a thesis statement that synthesizes
        information from multiple sources, providing a clear and comprehensive overview or argument
        related to the highlighted content. This feature will also suggest a few investible ideas
        that can be supported by the evidence and context.`,
    },
};
