import { Slot } from "@radix-ui/react-slot";
import { HTMLAttributes, PropsWithChildren, SyntheticEvent, use } from "react";
import { useNavigate } from "react-router-dom";

import { UUID } from "@/api/types";
import { DocumentLinkContext } from "@/components/document/document-link-context";

interface Props extends HTMLAttributes<HTMLDivElement> {
    documentID: UUID;
    urlPrefix?: string;
    asChild?: boolean;
}

const sanitizeUrlPrefix = (prefix: string | undefined): string => {
    if (prefix == undefined) return "";
    if (prefix.endsWith("/")) return prefix;
    return prefix + "/";
};

export const DocumentLink = ({
    documentID,
    urlPrefix,
    asChild = false,
    ...props
}: PropsWithChildren<Props>) => {
    const Comp = asChild ? Slot : "div";
    const navigate = useNavigate();
    const ctx = use(DocumentLinkContext);

    const handleClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        if (ctx) {
            ctx.onNavigate(documentID, urlPrefix);
        } else {
            navigate(`${sanitizeUrlPrefix(urlPrefix)}document/${documentID}`);
        }
    };

    return <Comp onClick={handleClick} {...props} />;
};
