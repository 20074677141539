import {
    CircleCheckBig,
    CircleEllipsis,
    CircleFadingPlus,
    CircleX,
} from "lucide-react";

import { ContextStatus } from "@/api/types";
import { HoverTooltip } from "@/components/hover-tooltip";
import { useContextContext } from "@/hooks/use-context-context";
import { DocumentContextState } from "@/stores/context";
import { nonNull } from "@/utils/fn";
import { capitalize, plural } from "@/utils/string-helpers";

export const StatusIndicator = () => {
    const status = useContextContext((s) => s.status);
    const documents = useContextContext((s) => s.documents);

    const states = Array.from(documents.values());
    const readyDocs = states.filter((s) => s === DocumentContextState.ready);
    const errorDocs = states.filter((s) => s === DocumentContextState.error);
    const addingDocs = states.filter((s) => s === DocumentContextState.adding);
    const removingDocs = states.filter(
        (s) => s === DocumentContextState.removing,
    );

    const ready =
        readyDocs.length > 0
            ? `Chat with ${plural(readyDocs.length, "document")}`
            : null;
    const error =
        errorDocs.length > 0
            ? `${plural(errorDocs.length, "document")} failed to load. Try to refresh`
            : null;
    const adding =
        addingDocs.length > 0
            ? `adding ${plural(addingDocs.length, "document")}`
            : null;
    const removing =
        removingDocs.length > 0
            ? `removing ${plural(removingDocs.length, "document")}`
            : null;

    switch (status) {
        case ContextStatus.UNINITIALIZED:
        case ContextStatus.LOADING:
            return (
                <HoverTooltip tooltip="Loading Chat...">
                    <div className="flex min-h-10 min-w-10 items-center justify-center rounded-full text-muted-foreground">
                        <CircleEllipsis className="size-5 animate-pulse" />
                    </div>
                </HoverTooltip>
            );
        case ContextStatus.FAILED:
            return (
                <HoverTooltip tooltip="Could not initialize Chat. Refresh to retry">
                    <div className="flex min-h-10 min-w-10 items-center justify-center rounded-full text-red-700">
                        <CircleX className="size-5" />
                    </div>
                </HoverTooltip>
            );
        case ContextStatus.LOADED:
            return (
                <HoverTooltip
                    tooltip={error ?? ready ?? "Add documents to use Chat"}
                >
                    <div className="flex min-h-10 min-w-10 items-center justify-center rounded-full">
                        {documents.size === 0 ? (
                            <CircleX className="size-5 text-red-700" />
                        ) : (
                            <CircleCheckBig className="size-5" />
                        )}
                    </div>
                </HoverTooltip>
            );
        case ContextStatus.UPDATING:
            return (
                <HoverTooltip
                    tooltip={capitalize(
                        [adding, removing].filter(nonNull).join(", and"),
                    )}
                >
                    <div className="flex min-h-10 min-w-10 items-center justify-center rounded-full">
                        <CircleFadingPlus className="size-5 animate-pulse" />
                    </div>
                </HoverTooltip>
            );
        default:
            return status satisfies never;
    }
};
