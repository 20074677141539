import { HTMLAttributes } from "react";

import { BillingDetails as TBillingDetails } from "@/api/types";
import { cn } from "@/lib/utils";

interface Props extends HTMLAttributes<HTMLDivElement> {
    billing_details: TBillingDetails;
}

export const BillingDetails = ({
    billing_details,
    className,
    ...props
}: Props) => (
    <div {...props} className={cn("space-y-4 p-4", className)}>
        <div>
            <p>{billing_details.billing_name}</p>
            <p className="text-sm text-gray-500">
                {billing_details.billing_email}
            </p>
        </div>
        {billing_details.billing_address && (
            <p className="flex flex-col">
                <span>{billing_details.billing_address.line1}</span>
                <span>{billing_details.billing_address.line2}</span>
                <span>
                    {billing_details.billing_address.city},{" "}
                    {billing_details.billing_address.state}{" "}
                    {billing_details.billing_address.postal_code}
                </span>
                <span>{billing_details.billing_address.country}</span>
            </p>
        )}
    </div>
);
