import { useFlags } from "launchdarkly-react-client-sdk";

import { FeatureFlagBool } from "@/conf/feature-flags";

export const useFeatureFlagBool = (
    name: FeatureFlagBool,
    defaultValue: boolean,
): boolean => {
    const flags = useFlags();
    return name in flags ? flags[name] : defaultValue;
};
