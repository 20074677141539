import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import { BrightwaveLogo } from "@/components/brightwave-logo";
import { Copyright } from "@/components/copyright";

export const LoginLayout = (props: PropsWithChildren) => {
    return (
        <div className="flex min-h-screen flex-col justify-stretch md:flex-row">
            <div className="flex basis-8/12 flex-col justify-between self-stretch bg-black bg-logo-clipped-white bg-100 bg-left-top bg-no-repeat p-20 text-white">
                <BrightwaveLogo className="h-auto w-52" />
                <div className="max-w-md space-y-10">
                    <h1 className="font-headline text-5xl font-light">
                        The Future of Financial Research
                    </h1>
                    <p>
                        Brightwave is an AI research assistant that generates
                        tight, actionable financial analysis on any subject.
                    </p>
                </div>
            </div>
            <div className="flex basis-4/12 flex-col items-center justify-between self-stretch p-4">
                <div className="w-90 my-4 flex flex-grow flex-col justify-center space-y-10">
                    {props.children ?? <Outlet />}
                </div>
                <Copyright />
            </div>
        </div>
    );
};
