import { zodResolver } from "@hookform/resolvers/zod";
import { Star, User } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { CreateUserData } from "@/api/rest";
import { UserType } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { ErrorAlert } from "@/components/error-alert";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { UseAsyncState, useAsyncState } from "@/hooks/use-async-state";

const formSchema = z.object({
    user_type: z.nativeEnum(UserType),
    username: z.string().email().trim(),
    first_name: z
        .string()
        .trim()
        .nullable()
        .transform((v) => v ?? null),
    last_name: z
        .string()
        .trim()
        .nullable()
        .transform((v) => v ?? null),
});

type CreateUserFormProps = {
    action: UseAsyncState<[CreateUserData], unknown>;
};

export const CreateUserForm = ({ action }: CreateUserFormProps) => {
    const form = useForm<CreateUserData>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            user_type: UserType.creator,
            username: "",
            first_name: "",
            last_name: "",
        },
    });

    const { submit, isSubmitting, error } = useAsyncState(action.submit, {
        onSuccess: () => {
            form.reset();
        },
    });

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(submit)} className="space-y-4">
                {error && <ErrorAlert error={error} />}
                <FormField
                    control={form.control}
                    name="user_type"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>User Type</FormLabel>
                            <FormControl>
                                <Select
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                >
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select a user type" />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectItem value={UserType.creator}>
                                            <span className="flex items-center">
                                                <User className="mr-2 h-4 w-4" />
                                                Creator
                                            </span>
                                        </SelectItem>
                                        <SelectItem value={UserType.admin}>
                                            <span className="flex items-center">
                                                <Star className="mr-2 h-4 w-4" />
                                                Admin
                                            </span>
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="username"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <Input type="email" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="first_name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>First Name</FormLabel>
                            <FormControl>
                                <Input {...field} value={field.value ?? ""} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="last_name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Last Name</FormLabel>
                            <FormControl>
                                <Input {...field} value={field.value ?? ""} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <AsyncButton type="submit" loading={isSubmitting}>
                    Create User
                </AsyncButton>
            </form>
        </Form>
    );
};
