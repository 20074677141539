import { useRef, useEffect } from "react";

import { useGridView } from "@/hooks/use-grid-view-context";

export const ColumnFocus = ({
    columnKey,
    block = "start",
    behavior = "smooth",
    inline = "center",
}: {
    columnKey: string;
    behavior?: ScrollBehavior;
    inline?: ScrollLogicalPosition;
    block?: ScrollLogicalPosition;
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const shouldFocus = useGridView((s) => s.columnFocus === columnKey);
    const clearColumnFocus = useGridView((s) => s.clearColumnFocus);

    useEffect(() => {
        if (shouldFocus && ref.current) {
            ref.current.scrollIntoView({ behavior, inline, block });
            clearColumnFocus();
        }
    }, [shouldFocus]);

    return shouldFocus ? (
        <div ref={ref} className="absolute top-0 size-0 overflow-hidden" />
    ) : null;
};
