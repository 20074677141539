import { HTMLAttributes } from "react";

import { ContractInterval, Currency, SubscriptionItem } from "@/api/types";
import { cn } from "@/lib/utils";
import { firstX } from "@/utils/collection";
import { formatCurrency, formatPaymentPeriod } from "@/utils/string-helpers";

type ItemPriceProps = {
    quantity?: number;
    amount: number;
    currency: Currency;
    recurring_interval: ContractInterval;
    recurring_interval_count?: number;
};

const SubscriptionItemPrice = ({
    quantity = 1,
    amount,
    currency,
    recurring_interval,
    recurring_interval_count = 1,
}: ItemPriceProps) => (
    <div className="flex flex-wrap items-baseline justify-end gap-1">
        {quantity > 1 && <span>{quantity} &times;</span>}
        <span>{formatCurrency(amount, currency)}</span>
        <span className="text-xs text-gray-500">
            {formatPaymentPeriod(
                currency,
                recurring_interval,
                recurring_interval_count,
            )}
        </span>
    </div>
);

interface TotalProps {
    items: SubscriptionItem[];
}

const SubscriptionTotal = ({ items }: TotalProps) => {
    const hasMany = items.length > 1 || firstX(items).quantity > 1;
    if (!hasMany) return null;

    return (
        <div className="mt-1 flex gap-4 border-t border-gray-400 pt-1 dark:border-gray-600">
            <span>Total</span>
            <SubscriptionItemPrice
                amount={items
                    .map((i) => i.quantity * i.amount)
                    .reduce((a, b) => a + b, 0)}
                currency={firstX(items).currency}
                recurring_interval={firstX(items).recurring_interval}
                recurring_interval_count={
                    firstX(items).recurring_interval_count
                }
            />
        </div>
    );
};

interface PriceProps extends HTMLAttributes<HTMLDivElement> {
    items: SubscriptionItem[];
}

export const SubscriptionPrice = ({
    items,
    className,
    ...props
}: PriceProps) => {
    return (
        <div
            {...props}
            className={cn("flex w-fit flex-col items-end", className)}
        >
            {items.map((item) => (
                <SubscriptionItemPrice key={item.id} {...item} />
            ))}
            <SubscriptionTotal items={items} />
        </div>
    );
};
