import { createContext } from "react";

import { Subscription } from "@/api/types";
import { emptyFunction } from "@/utils/empty-function";

type TStripeContext = {
    subscription: Subscription;
    clientSecret: string | null;
    setClientSecret: (clientSecret: string) => void;
};

export const StripeContext = createContext<TStripeContext>({
    subscription: {} as Subscription,
    clientSecret: null,
    setClientSecret: emptyFunction,
});
