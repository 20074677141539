import { Loader2, Quote, RefreshCw } from "lucide-react";
import useSWRImmutable from "swr/immutable";

import { FindingGroupInfo, FindingGroupStatus } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { DebugID } from "@/components/debug/debug-id";
import { DetailedFinding } from "@/components/document-table/condensed/detailed-finding";
import { useApi } from "@/hooks/use-api";
import { UseAsyncState } from "@/hooks/use-async-state";
import { useGridView } from "@/hooks/use-grid-view-context";
import { notEmptyOrNull } from "@/utils/string-helpers";

export const SynthesisDetails = (props: {
    findingGroupInfo: Omit<FindingGroupInfo, "document_id">;
    regenerateAction?: UseAsyncState<[], unknown>;
}) => {
    const api = useApi();
    const reportID = useGridView((s) => s.report.id);

    const { data } = useSWRImmutable(
        [
            reportID,
            props.findingGroupInfo.id,
            props.findingGroupInfo.status,
            "synthesis",
        ],
        async ([message_id, finding_group_id]) =>
            await api.fetch_synthesis(message_id, finding_group_id),
        { suspense: true },
    );

    switch (data.status) {
        case FindingGroupStatus.processing:
            return (
                <div className="flex items-center justify-center gap-3 p-20">
                    <Loader2 className="size-4 animate-spin" />
                    <span>Synthesizing...</span>
                </div>
            );
        case FindingGroupStatus.completed:
            return (
                <div className="space-y-4 p-4">
                    <DebugID
                        label="Finding Group"
                        id={data.id}
                        className="block"
                    />
                    {notEmptyOrNull(data.metadata?.custom_input) && (
                        <p className="flex items-center gap-2 rounded bg-muted p-2 font-medium">
                            <Quote className="size-4 -scale-100" />
                            {data.metadata.custom_input}
                        </p>
                    )}
                    {data.findings.flatMap((finding) => [
                        <DebugID
                            key={`debug_${finding.id}`}
                            label="Finding"
                            id={finding.id}
                            className="block"
                        />,
                        <DetailedFinding key={finding.id} finding={finding} />,
                    ])}
                </div>
            );
        case FindingGroupStatus.failed:
        case FindingGroupStatus.cancelled:
            return (
                <div className="space-y-4 p-4">
                    <p className="italic text-destructive">
                        Failed to synthesize findings. Please regenerate the
                        syhtnesis.
                    </p>
                    {props.regenerateAction && (
                        <AsyncButton
                            variant="outline"
                            action={props.regenerateAction}
                        >
                            <RefreshCw className="mr-2 size-4" />
                            Regenerate Synthesis
                        </AsyncButton>
                    )}
                </div>
            );
    }
};
