import { h } from "hastscript";
import type { Root, Parent, Node, PhrasingContent } from "mdast";
import { visit } from "unist-util-visit";

import { simpleHash } from "@/utils/string-helpers";

// define hastscript types
type PrimitiveValue = boolean | number | string | null | undefined;
type ArrayValue = Array<number | string>;
type PropertyValue = ArrayValue | PrimitiveValue;
type Properties = { [key: string]: PropertyValue };

interface ActionData extends Properties {
    type: string;
    message?: string;
    selected_text?: string;
    response_to?: string;
}

interface CitationData extends Properties {
    citation_id: string;
}

interface ActionDirective extends Parent {
    type: "leafDirective";
    name: "action";
    children: PhrasingContent[];
    attributes: ActionData;
}

interface UnknownTextDirective extends Node {
    type: "textDirective";
    name: string;
}

interface CitationTextDirective extends Parent {
    type: "textDirective";
    name: "cit";
    children: PhrasingContent[];
    attributes: CitationData;
}

type LeafDirectives = ActionDirective;
type TextDirectives = CitationTextDirective | UnknownTextDirective;

declare module "mdast" {
    // extend root content map interface
    interface RootContentMap {
        leafDirective: LeafDirectives;
        textDirective: TextDirectives;
    }
}

const isCitation = (node: TextDirectives): node is CitationTextDirective =>
    node.type === "textDirective" && node.name == "cit";

const parseString = (s: string | undefined): string | undefined =>
    s != undefined ? JSON.parse(`"${s}"`) : undefined;

export const customDirectives = () => (tree: Root) =>
    visit(tree, (node): void => {
        if (node.type === "leafDirective" && node.name === "action") {
            const data = node.data || (node.data = {});
            const hast = h(node.name, {
                type: node.attributes.type,
                message: parseString(node.attributes.message),
                selected_text: parseString(node.attributes.selected_text),
                response_to: node.attributes.response_to,
            });

            data.hName = hast.tagName;
            data.hProperties = hast.properties;
        } else if (node.type === "textDirective") {
            if (isCitation(node)) {
                const data = node.data || (node.data = {});
                const hast = h(node.name, {
                    id: simpleHash(
                        [
                            node.position?.start.offset,
                            node.position?.end.offset,
                            node.attributes.citation_id,
                        ].join("_"),
                    ),
                    citation_id: node.attributes.citation_id,
                });

                data.hName = hast.tagName;
                data.hProperties = hast.properties;
            } else {
                // render unreconized directives as regular text
                const data = node.data || (node.data = {});
                const hast = h("span", `:${node.name}`);
                data.hName = hast.tagName;
                data.hProperties = hast.properties;
                data.hChildren = hast.children;
            }
        }
    });
