import { create } from "zustand";

type State = {
    isOpen: boolean;
    toggle: (isOpen?: boolean) => void;

    openFilter: string | null;
    setOpenFilter: (id: string) => void;
    clearOpenFilter: () => void;
};

export const useFilter = create<State>((set) => ({
    isOpen: false,

    toggle(isOpen) {
        set(isOpen === undefined ? (s) => ({ isOpen: !s.isOpen }) : { isOpen });
    },

    openFilter: null,
    setOpenFilter(openFilter) {
        set({ openFilter });
    },
    clearOpenFilter() {
        set({ openFilter: null });
    },
}));
