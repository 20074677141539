import {
    DocumentInfo,
    FindingContentType,
    FindingGroupInfo,
    FindingGroupType,
    GenericFindingContent,
    ProbeType,
} from "@/api/types";
import { CellState } from "@/components/analyze/cell-state";
import { Collapsible } from "@/components/document-table/condensed/collapsible";
import { CollapsibleList } from "@/components/document-table/condensed/collapsible-list";
import { CompactFinding } from "@/components/document-table/condensed/compact-finding";
import { BooleanFinding } from "@/components/document-table/condensed/finding-content/boolean";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";
import { firstX } from "@/utils/collection";
import { nonNull } from "@/utils/fn";

type Props = {
    documentInfo: DocumentInfo;
    groupInfo: FindingGroupInfo;
    findings: GenericFindingContent[];
};

export const CondensedFindingGroup = (props: Props) => {
    const forceBooleanFindingToFalse = useFeatureFlagBool(
        FeatureFlagBool.force_boolean_finding_to_false,
        false,
    );
    // filter to short text from executive summary and map to list item conten type
    const findings =
        props.groupInfo.type === FindingGroupType.executive_summary
            ? props.findings
                  .filter(
                      (f) => f.content_type !== FindingContentType.long_text,
                  )
                  .map(
                      (f) =>
                          ({
                              ...f,
                              content_type: FindingContentType.list_item,
                          }) as GenericFindingContent,
                  )
                  .filter(nonNull)
            : props.findings;

    if (
        forceBooleanFindingToFalse &&
        props.groupInfo.type === FindingGroupType.user_defined_text &&
        props.groupInfo.probe?.type === ProbeType.boolean &&
        findings.length === 0
    ) {
        return <BooleanFinding value={false} />;
    }

    if (findings.length === 0) {
        return <CellState>No Findings</CellState>;
    }

    const content_type = firstX(findings).content_type;
    const children = findings.flatMap((finding) => (
        <CompactFinding
            key={finding.id}
            finding={finding}
            documentID={props.documentInfo.id}
        />
    ));
    switch (content_type) {
        case FindingContentType.list_item:
            return (
                <CollapsibleList
                    className="space-y-1"
                    data-bw-actions
                    data-action-context={`document:${props.documentInfo.id}`}
                >
                    {children}
                </CollapsibleList>
            );
        case FindingContentType.boolean:
            return children;
        case FindingContentType.titled_list:
        case FindingContentType.full_text:
        case FindingContentType.long_text:
        case FindingContentType.numerical:
        case FindingContentType.numerical_full_text:
        case FindingContentType.short_text:
        case FindingContentType.titled_long_text:
        case FindingContentType.unstructured:
            return (
                <Collapsible
                    className="flex flex-col"
                    data-bw-actions
                    data-action-context={`document:${props.documentInfo.id}`}
                >
                    {children}
                </Collapsible>
            );
        default:
            return content_type satisfies never;
    }
};
