import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { ThreadConfig } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { UseAsyncState } from "@/hooks/use-async-state";

const formSchema = z.object({
    model_name: z.string(),
    max_samples: z.coerce.number().min(0).max(100),
    num_search_queries: z.coerce.number().min(0).max(20),
    documents_per_query: z.coerce.number().min(0).max(100),
    generation_seed: z.coerce.number().min(0).max(1e6),
});

type Props = {
    settings: ThreadConfig;
    modelOptions: string[];
    action: UseAsyncState<[ThreadConfig], unknown>;
};

export const ThreadConfigForm = (props: Props) => {
    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: props.settings,
    });

    const onSubmit = (data: z.infer<typeof formSchema>) =>
        props.action.submit(data);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <FormField
                    control={form.control}
                    name="model_name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>AI Model</FormLabel>
                            <FormControl>
                                <Select
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                >
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select a GPT model" />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        {props.modelOptions.map((model) => (
                                            <SelectItem
                                                key={model}
                                                value={model}
                                            >
                                                {model}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="max_samples"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Max Samples</FormLabel>
                            <FormControl>
                                <Input type="number" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="num_search_queries"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Number of Search Queries</FormLabel>
                            <FormControl>
                                <Input type="number" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="documents_per_query"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Documents per Query</FormLabel>
                            <FormControl>
                                <Input type="number" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="generation_seed"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Generation Seed</FormLabel>
                            <FormControl>
                                <Input type="number" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <AsyncButton loading={props.action.isSubmitting}>
                    Save Changes
                </AsyncButton>
            </form>
        </Form>
    );
};
