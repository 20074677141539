/*
 * MUST mirror the Feature Flag name on Launch Darkly
 *
 * All feature flags will be transformed to lower camelCase by LD
 */
export enum FeatureFlagBool {
    chat_enabled = "chatEnabled",
    clear_chat = "clearChat",
    force_boolean_finding_to_false = "forceBooleanFindingToFalse",
    grid_view_filter_sort = "gridViewFilterSort",
    regenerate_synthesis = "regenerateSynthesis",
    synthesis_enabled = "synthesisEnabled",
}
