import { createPortal } from "react-dom";

import { ContextMenu } from "@/components/action-panel/context-menu";
import { PopoverPosition } from "@/components/popover-position";
import { UseContextMenu } from "@/hooks/use-context-menu";

type Props = {
    contextMenu?: UseContextMenu;
};

export const ContextMenuContainer = ({ contextMenu }: Props) => {
    if (contextMenu === undefined) {
        return null;
    }

    return createPortal(
        <PopoverPosition anchor={contextMenu.anchor}>
            <ContextMenu
                selection={{
                    text: contextMenu.selection,
                    actionContext: contextMenu.actionContext,
                }}
                onAction={() => contextMenu?.deselect()}
            />
        </PopoverPosition>,
        document.body,
    );
};
