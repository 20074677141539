import { ColumnDef } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { Row } from "@tanstack/react-table";
import { parseISO } from "date-fns";
import {
    FileUp,
    Info,
    Loader2,
    MessageSquareQuote,
    Plus,
    X,
} from "lucide-react";
import { PropsWithChildren, Suspense, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { ImperativePanelHandle } from "react-resizable-panels";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { validate as validateUUID } from "uuid";
import { create } from "zustand";
import { useShallow } from "zustand/react/shallow";

import {
    ContextType,
    DocumentType,
    UUID,
    AsyncFindingGroup,
    UploadFile,
    DocumentInfo as TDocumentInfo,
} from "@/api/types";
import { ActionPanel } from "@/components/action-panel/action-panel";
import { ActionPanelHeader } from "@/components/action-panel/header";
import { CopyToClipboard } from "@/components/admin/copy-to-clipboard";
import { CellState } from "@/components/analyze/cell-state";
import { DocumentSearch } from "@/components/analyze/document-search";
import { DocumentStatusGate } from "@/components/analyze/document-status-gate";
import { Dropzone } from "@/components/analyze/dropzone";
import { OverlayContainer, OverlayContent } from "@/components/analyze/overlay";
import { GridViewSkeleton } from "@/components/analyze/skeleton";
import { UploadToast } from "@/components/analyze/upload-toast";
import { Debugger } from "@/components/debug/debugger";
import { DocumentLinkContext } from "@/components/document/document-link-context";
import { ColumnHeader } from "@/components/document-table/column-header";
import { ColumnSheet } from "@/components/document-table/columns/sheet";
import { DocumentInfo } from "@/components/document-table/document-info";
import { FindingGroupContent } from "@/components/document-table/finding-group-content";
import {
    DocumentWithAsyncFindings,
    PowerTable,
} from "@/components/document-table/power-table";
import { ReportHeader } from "@/components/report/header";
import { Button } from "@/components/ui/button";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { StaticColumn } from "@/conf/grid-view";
import { MAX_DOCUMENT_COUNT } from "@/conf/report";
import { ContextMenuContainer } from "@/container/context-menu";
import { DocumentContainer } from "@/container/grid-view-document-container";
import { ActionsContextProvider } from "@/context/actions-context-provider";
import { ContextContextProvider } from "@/context/context-context-provider";
import { FileUploadContextProvider } from "@/context/file-upload-context-provider";
import { GridViewContextProvider } from "@/context/grid-view-context-provider";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useColumnSheet } from "@/hooks/use-column-sheet";
import { useContextMenu } from "@/hooks/use-context-menu";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";
import { useFileUploadContext } from "@/hooks/use-file-upload-context";
import { useGridView } from "@/hooks/use-grid-view-context";
import { usePageTitle } from "@/hooks/use-page-title";
import { AppLayout } from "@/layouts/app-layout";
import { NotFoundRoute } from "@/routes/not-found";
import { getColumId, getColumnIdFromFindingGroup } from "@/utils/columns";
import { isFileUploaded } from "@/utils/file-upload";
import { getColumnFilterFn } from "@/utils/filter";
import { maybeGetFindingGroup } from "@/utils/finding-group";
import { nonNull } from "@/utils/fn";
import { getReportTitle } from "@/utils/report";
import { plural } from "@/utils/string-helpers";

type Store = {
    id: UUID | undefined;
    setDocument: (id: UUID) => void;
    clearDocument: () => void;
};
const useAnalyze = create<Store>((set) => ({
    id: undefined,
    setDocument(id) {
        set({ id });
    },
    clearDocument() {
        set({ id: undefined });
    },
}));

const valueInSetFilterFn = (
    row: Row<DocumentWithAsyncFindings>,
    columnId: string,
    filterValue: Set<string> | undefined,
): boolean => {
    return (
        filterValue === undefined ||
        filterValue.size === 0 ||
        filterValue.has(row.getValue(columnId))
    );
};

const columnHelper = createColumnHelper<DocumentWithAsyncFindings>();

const staticColumns = [
    columnHelper.accessor((row) => row.info, {
        id: StaticColumn.document,
        header({ table }) {
            const filteredRowCount =
                table.getFilteredRowModel().flatRows.length;
            const totalCount = table.getRowCount();
            if (filteredRowCount != totalCount) {
                return `${filteredRowCount} / ${plural(totalCount, "Document")}`;
            }
            return plural(totalCount, "Document");
        },
        cell({ getValue, row }) {
            return (
                <DocumentInfo
                    info={getValue()}
                    className="relative h-full p-4"
                    onClick={row.getToggleSelectedHandler()}
                />
            );
        },
        enableColumnFilter: false,
        size: 300,
        enableResizing: false,
        meta: {
            removePadding: true,
        },
    }),
    columnHelper.accessor(
        (row) =>
            row.info.content_date ? parseISO(row.info.content_date) : undefined,
        { id: StaticColumn.content_date },
    ),
    columnHelper.accessor(
        (row) => ("ticker" in row.info ? row.info.ticker : ""),
        {
            id: StaticColumn.ticker,
            filterFn: valueInSetFilterFn,
        },
    ),
    columnHelper.accessor("info.doc_type", {
        id: StaticColumn.doc_type,
        filterFn: valueInSetFilterFn,
    }),
    columnHelper.accessor("info.status", {
        id: StaticColumn.document_status,
        header: "Status",
        cell: ({ getValue }) => (
            <DocumentStatusGate status={getValue()}>
                <CellState>Document Ready</CellState>
            </DocumentStatusGate>
        ),
        enableResizing: false,
    }),
    columnHelper.accessor("info.title", {
        id: StaticColumn.document_title,
    }),
];

const AnalyzeRouteImpl = () => {
    const chatEnabled = useFeatureFlagBool(FeatureFlagBool.chat_enabled, true);
    const actionPanelRef = useRef<ImperativePanelHandle>(null);
    const documentPanelRef = useRef<ImperativePanelHandle>(null);
    const contextMenu = useContextMenu();

    const report = useGridView((s) => s.report);
    const documents = useGridView((s) => s.documents);
    const addItems = useGridView((s) => s.addItems);
    const deleteItems = useGridView((s) => s.deleteItems);

    const selectedDocumentID = useAnalyze((s) => s.id);
    const selectDocument = useAnalyze((s) => s.setDocument);
    const closeDocument = useAnalyze((s) => s.clearDocument);

    const isActionPanelOpen = useActionsContext((s) => s.isActionPanelOpen);
    const openActionPanel = useActionsContext((s) => s.actionPanelOpen);
    const closeActionPanel = useActionsContext((s) => s.actionPanelClose);

    const files = useFileUploadContext((s) => s.files);
    const resetFiles = useFileUploadContext((s) => s.reset);

    usePageTitle(getReportTitle(report));

    const maxFiles = useFileUploadContext((s) => s.maxFiles);
    const { open: fileDialogOpen } = useFileUploadContext((s) => s.dropzone);
    const handleFileOpen = () => {
        if (maxFiles <= 0) {
            return toast.error("File limit reached");
        }
        fileDialogOpen();
    };

    const addedDocumentIDs = useGridView(
        useShallow(
            (s) =>
                new Set(
                    Array.from(s.documents.values())
                        .filter(
                            (d) =>
                                d.doc_type === DocumentType.sec_filing ||
                                d.doc_type === DocumentType.earnings_transcript,
                        )
                        .map((d) => d.unique_id),
                ),
        ),
    );

    const toggleActionPanel = () =>
        isActionPanelOpen ? closeActionPanel() : openActionPanel();

    const dismissOverlay = () => {
        closeActionPanel();
        closeDocument();
    };

    useHotkeys("mod+j", toggleActionPanel, { enableOnFormTags: true });
    useHotkeys("esc", dismissOverlay, { enableOnFormTags: true });

    const docList = useGridView(
        useShallow((s) => Array.from(s.documents.values())),
    );
    const mapping = useGridView((s) => s.documents_to_finding_groups);
    const finding_groups = useGridView((s) => s.finding_groups);
    const rows = docList.map(
        (info): DocumentWithAsyncFindings => ({
            info,
            finding_groups: new Map(
                (mapping.get(info.id) ?? [])
                    .map((id) => finding_groups.get(id))
                    .filter(nonNull)
                    .map((fg): [string, AsyncFindingGroup] | null => {
                        const key = getColumnIdFromFindingGroup(fg);
                        return key ? [key, fg] : null;
                    })
                    .filter(nonNull),
            ),
        }),
    );

    const updateReportTitle = useGridView((s) => s.updateReportTitle);
    const columns = useGridView((s) => s.columns);

    const tableCols = [
        ...staticColumns,
        ...columns.map((col) =>
            columnHelper.accessor(
                (row): [TDocumentInfo, AsyncFindingGroup | undefined] => [
                    row.info,
                    row.finding_groups.get(getColumId(col)),
                ],
                {
                    id: getColumId(col),
                    header: () => <ColumnHeader column={col} />,
                    cell: ({ getValue }) => {
                        const [info, findingGroup] = getValue();
                        return (
                            <DocumentStatusGate status={info.status}>
                                <Debugger>
                                    <CopyToClipboard
                                        value={JSON.stringify(
                                            maybeGetFindingGroup(findingGroup),
                                            null,
                                            2,
                                        )}
                                        asChild
                                    >
                                        <Button
                                            size="xs"
                                            variant="secondary"
                                            className="absolute right-1 top-1 z-10"
                                        >
                                            <Info className="size-4" />
                                        </Button>
                                    </CopyToClipboard>
                                </Debugger>
                                {findingGroup !== undefined ? (
                                    <FindingGroupContent
                                        documentInfo={info}
                                        findingGroup={findingGroup}
                                    />
                                ) : (
                                    <CellState variant="loading">
                                        Analyzing...
                                    </CellState>
                                )}
                            </DocumentStatusGate>
                        );
                    },
                    size: 400,
                    filterFn: getColumnFilterFn(col),
                },
            ),
        ),
    ] as ColumnDef<DocumentWithAsyncFindings>[];

    const columnSizing = useGridView(useShallow((s) => s.columnSizing));
    const setColumnSizing = useGridView(useShallow((s) => s.setColumnSizing));
    const columnOrder = useGridView(useShallow((s) => s.columnOrder));
    const setColumnOrder = useGridView(useShallow((s) => s.setColumnOrder));
    const columnVisibility = useGridView(useShallow((s) => s.columnVisibility));
    const sorting = useGridView(useShallow((s) => s.sorting));
    const setSorting = useGridView((s) => s.setSorting);
    const columnFilters = useGridView(useShallow((s) => s.columnFilters));
    const setColumnFilters = useGridView((s) => s.setColumnFilters);
    const addColumn = useColumnSheet((s) => s.open);

    return (
        <DocumentLinkContext value={{ onNavigate: selectDocument }}>
            <AppLayout
                header={
                    <div className="flex flex-1 justify-center">
                        <ReportHeader
                            report={report}
                            onTitleChange={updateReportTitle}
                        />
                    </div>
                }
                className="relative flex h-screen grow flex-col bg-page-background"
            >
                <div className="flex min-h-14 items-center justify-between gap-10 border-b border-foreground/5 bg-foreground px-3 dark:bg-neutral-900">
                    <div className="flex-1">
                        {chatEnabled && (
                            <Button
                                variant="primary"
                                className="gap-2"
                                onClick={toggleActionPanel}
                            >
                                <MessageSquareQuote className="size-4 translate-y-px" />
                                Chat
                            </Button>
                        )}
                    </div>
                    <div className="dark flex flex-2 items-center gap-4">
                        <Button
                            variant="secondary"
                            className="gap-2 text-muted-foreground"
                            onClick={handleFileOpen}
                        >
                            <FileUp className="size-4" />
                            Upload PDFs
                        </Button>
                        <DocumentSearch
                            addedDocumentIDs={addedDocumentIDs}
                            addItems={addItems}
                            placeholder="Search for additional companies and documents"
                            maxDocumentCount={
                                MAX_DOCUMENT_COUNT - documents.size
                            }
                        />
                    </div>
                    <div className="flex flex-1 justify-end">
                        <Button
                            variant="primary"
                            className="gap-2"
                            onClick={addColumn}
                        >
                            <Plus className="size-4" />
                            Add Analysis
                        </Button>
                    </div>
                </div>
                <PowerTable
                    data={rows}
                    columns={tableCols}
                    onDeleteRow={deleteItems}
                    columnPinning={{ left: [StaticColumn.document] }}
                    columnVisibility={{
                        ...columnVisibility,
                        document_status:
                            documents.size > 0 && columns.length === 0,
                    }}
                    initialColumnSizing={columnSizing}
                    onColumnSizingChange={setColumnSizing}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                    sorting={sorting}
                    setSorting={setSorting}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                >
                    <p className="grow text-center text-sm italic text-muted-foreground">
                        No documents.
                    </p>
                </PowerTable>
                <OverlayContainer onDismiss={dismissOverlay}>
                    {chatEnabled && (
                        <OverlayContent
                            side="left"
                            panelRef={actionPanelRef}
                            open={isActionPanelOpen}
                        >
                            <ActionPanel
                                header={
                                    <ActionPanelHeader
                                        onClose={closeActionPanel}
                                    />
                                }
                                className="flex w-full flex-col"
                            />
                        </OverlayContent>
                    )}
                    <OverlayContent
                        side="right"
                        panelRef={documentPanelRef}
                        open={selectedDocumentID != undefined}
                    >
                        <div className="flex min-h-12 items-center justify-between gap-2 border-b bg-background p-1">
                            <p className="px-3 font-headline text-xl font-bold">
                                Summary
                            </p>
                            <Button
                                variant="ghost"
                                size="icon"
                                onClick={closeDocument}
                            >
                                <X className="size-4" />
                            </Button>
                        </div>
                        <Suspense
                            fallback={
                                <div className="flex items-center p-20">
                                    <Loader2 className="size-4 animate-spin" />
                                </div>
                            }
                        >
                            <DocumentContainer
                                id={selectedDocumentID}
                                fallback={
                                    <div className="flex grow items-center justify-center">
                                        Document was deleted from this report
                                    </div>
                                }
                            />
                        </Suspense>
                    </OverlayContent>
                </OverlayContainer>
                <Dropzone />
                <UploadToast
                    files={Array.from(files.values())}
                    onDismiss={resetFiles}
                />
                {chatEnabled && (
                    <ContextMenuContainer contextMenu={contextMenu} />
                )}
                <ColumnSheet />
            </AppLayout>
        </DocumentLinkContext>
    );
};

const FileUploadWrapper = (props: PropsWithChildren) => {
    const remainingCount = useGridView(
        (s) => MAX_DOCUMENT_COUNT - s.documents.size,
    );
    const addItems = useGridView((s) => s.addItems);
    const onUploadComplete = (files: UploadFile[]) =>
        addItems(
            files.filter(isFileUploaded).map((f) => ({
                type: ContextType.EXISTING_DOCUMENT,
                document_id: f.document_info.id,
            })),
        );
    return (
        <FileUploadContextProvider
            maxFiles={remainingCount}
            onUploadComplete={onUploadComplete}
        >
            {props.children}
        </FileUploadContextProvider>
    );
};

export const AnalyzeRoute = () => {
    const { id } = useParams<{ id: string }>();

    if (id === undefined || !validateUUID(id)) {
        return <NotFoundRoute />;
    }
    return (
        <Suspense fallback={<GridViewSkeleton />}>
            <ContextContextProvider messageID={id}>
                <GridViewContextProvider key={id} messageID={id}>
                    <ActionsContextProvider messageID={id}>
                        <FileUploadWrapper>
                            <AnalyzeRouteImpl />
                        </FileUploadWrapper>
                    </ActionsContextProvider>
                </GridViewContextProvider>
            </ContextContextProvider>
        </Suspense>
    );
};
