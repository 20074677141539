export interface ConfigurationMeta {
    readonly apiDomain: string;
    readonly env: string;
    readonly googleAnalyticsId?: string;
    readonly googleMapsApiKey: string;
    readonly launchDarklyClientId: string;
    readonly isDev: boolean;
    readonly sentryDsn: string;
    readonly sentryEnv: string;
    readonly sha: string;
    readonly stripePublicKey: string;
}

// First, look into the global scope to see if the config was bootstrapped server-side
// Note that this would have been injected by the server-side rendering process BEFORE the app's entrypoint
// @ts-expect-error __BW_CONFIG__ is a global variable and eslint doesn't like it
const bootstrap: ConfigurationMeta = window["__BW_CONFIG__"] || {};

// If the config was not bootstrapped server-side, use the environment settings baked into the app by Vite
const defaultConfig: ConfigurationMeta = {
    apiDomain: import.meta.env.VITE_API_DOMAIN,
    env: import.meta.env.MODE,
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    launchDarklyClientId: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
    isDev: import.meta.env.DEV,
    sentryDsn: import.meta.env.VITE_SENTRY_DSN,
    sentryEnv: import.meta.env.VITE_SENTRY_ENV,
    sha: "unknown", // This will be provided by the server-side rendering process
    stripePublicKey: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
};

// Merge the two configurations, giving priority to the server-side config
export const config: ConfigurationMeta = { ...defaultConfig, ...bootstrap };
