import { PropsWithChildren, ReactElement, RefObject, useEffect } from "react";
import { ImperativePanelHandle } from "react-resizable-panels";

import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@/components/ui/resizable";
import { cn } from "@/lib/utils";
import { nonNull } from "@/utils/fn";

type ContentProps = PropsWithChildren<{
    side: "left" | "right";
    panelRef?: RefObject<ImperativePanelHandle | null>;
    open?: boolean;
}>;

export const OverlayContent = (props: ContentProps) => {
    return props.children;
};

type Child =
    | undefined
    | null
    | false
    | ReactElement<ContentProps, typeof OverlayContent>;

type Props = {
    onDismiss?: () => void;
    children?: Child | Child[];
};

const isElement = (
    el: Child,
): el is ReactElement<ContentProps, typeof OverlayContent> =>
    el !== false && nonNull(el);

export const OverlayContainer = ({ onDismiss, children }: Props) => {
    const childComponents = Array.isArray(children)
        ? children
        : children !== undefined
          ? [children]
          : [];
    const left = childComponents
        .filter(isElement)
        .find((c) => c.props.side === "left");
    const right = childComponents
        .filter(isElement)
        .find((c) => c.props.side === "right");
    const panelOpen = left?.props.open === true || right?.props.open === true;

    useEffect(() => {
        const panel = left?.props.panelRef?.current;
        if (panel === undefined || panel === null) return;
        if (left?.props.open === true) {
            panel.expand();
        } else {
            panel.collapse();
        }
    }, [left?.props.open, left?.props.panelRef?.current]);

    useEffect(() => {
        const panel = right?.props.panelRef?.current;
        if (panel === undefined || panel === null) return;
        if (right?.props.open === true) {
            panel.expand();
        } else {
            panel.collapse();
        }
    }, [right?.props.open, right?.props.panelRef?.current]);

    return (
        <ResizablePanelGroup
            direction="horizontal"
            className={cn(
                "absolute inset-0 z-50 bg-black/80 opacity-0 transition-opacity",
                panelOpen && "opacity-100",
            )}
            autoSaveId="analyze-table"
            style={panelOpen ? undefined : { height: 0, width: 0 }}
        >
            <ResizablePanel
                ref={left?.props.panelRef}
                defaultSize={70}
                maxSize={90}
                minSize={40}
                collapsible
                className={cn(
                    "m-2 mr-0 flex overflow-hidden rounded-md bg-muted shadow-xl",
                    left?.props.open === true && "border",
                )}
            >
                {left}
            </ResizablePanel>
            <ResizableHandle className="w-1 bg-transparent" />
            <ResizablePanel onClick={onDismiss} />
            <ResizableHandle className="w-1 bg-transparent" />
            <ResizablePanel
                ref={right?.props.panelRef}
                defaultSize={70}
                minSize={40}
                maxSize={90}
                collapsible
                className={cn(
                    "m-2 ml-0 flex flex-col rounded-md bg-background shadow-xl",
                    right?.props.open === true && "border",
                )}
            >
                {right}
            </ResizablePanel>
        </ResizablePanelGroup>
    );
};
