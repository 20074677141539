import { cva, VariantProps } from "class-variance-authority";
import { X } from "lucide-react";
import { HTMLAttributes, MouseEvent } from "react";

import { Company } from "@/api/types";
import { CompanyLogo } from "@/components/company-logo";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

const variants = cva("flex items-center rounded border bg-muted", {
    variants: {
        size: {
            default: "gap-2.5 pl-2.5 py-1",
            sm: "gap-1.5 pl-1",
        },
    },
    defaultVariants: {
        size: "default",
    },
});

interface Props
    extends HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof variants> {
    company: Company;
    onRemove?: (e: MouseEvent) => void;
}

export const CompanyPill = ({ company, onRemove, size, className }: Props) => (
    <div className={cn(variants({ size }), className)}>
        <CompanyLogo ticker={company.symbol} />
        <span className="font-bold">{company.symbol}</span>
        {onRemove && (
            <Button variant="ghost" size="icon-xs" onClick={onRemove}>
                <X className="size-4" />
            </Button>
        )}
    </div>
);
