import { CreditCard, Landmark } from "lucide-react";
import { HTMLAttributes, PropsWithChildren } from "react";

import {
    BankDetails,
    CardDetails,
    PaymentMethod as TPaymentMethod,
} from "@/api/types";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { capitalize } from "@/utils/string-helpers";

type PaymentMethodProps<T> = PropsWithChildren<{
    details: T;
}> &
    HTMLAttributes<HTMLDivElement>;

const PaymentMethodBank = ({
    details,
    className,
    ...props
}: PaymentMethodProps<BankDetails>) => (
    <div
        {...props}
        className={cn("flex items-center gap-4 px-3 py-4", className)}
    >
        <Landmark className="size-6 text-gray-500" />
        <div className="grow">
            <div className="flex items-center gap-2">{details.bank_name}</div>
            <div className="flex items-baseline gap-2">
                <span className="text-sm">
                    {capitalize(details.account_type)}
                </span>
                <span className="text-sm text-gray-500">ends in</span>
                {details.last4}
            </div>
        </div>
        {props.children}
    </div>
);

const PaymentMethodCard = ({
    details,
    className,
    ...props
}: PaymentMethodProps<CardDetails>) => (
    <div
        {...props}
        className={cn("flex items-center gap-4 px-3 py-4", className)}
    >
        <CreditCard className="size-6 text-gray-500" />
        <div className="grow">
            <div className="flex items-center gap-2">
                {capitalize(details.brand)}
            </div>

            <div className="flex items-baseline gap-6">
                <span className="flex items-baseline gap-2">
                    <span className="text-sm text-gray-500">ends in</span>
                    {details.last4}
                </span>
                <span className="text-sm text-gray-500">
                    exp. {details.exp_month}/{details.exp_year}
                </span>
            </div>
        </div>
        {props.children}
    </div>
);

export const PaymentMethodSkeleton = () => (
    <div className="flex h-20 items-center gap-4 px-3">
        <Skeleton className="size-6" />
        <div className="space-y-3">
            <Skeleton className="h-4 w-20" />
            <Skeleton className="h-3 w-60" />
        </div>
    </div>
);

export const PaymentMethod = ({
    method,
    ...props
}: PropsWithChildren<{ method: TPaymentMethod }> &
    HTMLAttributes<HTMLDivElement>) => {
    switch (method.payment_type) {
        case "card":
            return <PaymentMethodCard {...props} details={method.details} />;
        case "us_bank_account":
            return <PaymentMethodBank {...props} details={method.details} />;
        default:
            return null;
    }
};
