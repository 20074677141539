export interface Position {
    top: number;
    left: number;
}

export interface Rect extends Position {
    width: number;
    height: number;
}

export const rectRight = (r: Rect): number => r.left + r.width;

export const rectBottom = (r: Rect): number => r.top + r.height;

export const rectVCenter = (r: Rect): number => r.top + r.height / 2;

export const rectHCenter = (r: Rect): number => r.left + r.width / 2;

export const containsRect = (outer: Rect, inner: Rect): boolean =>
    inner.left >= outer.left &&
    rectRight(inner) <= rectRight(outer) &&
    inner.top >= outer.top &&
    rectBottom(inner) <= rectBottom(outer);

export const fromDOMRect = ({ top, left, width, height }: DOMRect): Rect => ({
    top,
    left,
    width,
    height,
});

export const translate = <T extends Position>(rect: T, delta: Position): T => ({
    ...rect,
    top: rect.top + delta.top,
    left: rect.left + delta.left,
});

export const translateX = <T extends Position>(rect: T, delta: number): T => ({
    ...rect,
    left: rect.left + delta,
});

export const translateY = <T extends Position>(rect: T, delta: number): T => ({
    ...rect,
    top: rect.top + delta,
});

export const getAdjustedBoundingClientRect = (el: HTMLElement) => {
    const transform = getComputedStyle(el).transform;
    const rect = fromDOMRect(el.getBoundingClientRect());
    if (transform.startsWith("matrix(")) {
        // homogeneous transformation matrix
        // matrix(1, 0, 0, 1, -161.969, 0)
        const [, , , , left, top] = transform
            .slice(7, -1)
            .split(", ")
            .map(parseFloat);
        return translate(rect, { left: -left, top: -top });
    }
    return rect;
};

const getBit = (a: number, b: number): number =>
    a === b ? 0b00 : a < b ? 0b10 : 0b01;

/*
 8 bit for intersection model, two bits for each direction denoting
 whether a is inside, outside or equal b

    tt rr bb ll
 0b 00 00 00 00

 00 -> a equals b
 10 -> a outside b
 01 -> a inside b
*/
export const getInsersection = (rectA: Rect, rectB: Rect) => {
    const a = { ...rectA, right: rectRight(rectA), bottom: rectBottom(rectA) };
    const b = { ...rectB, right: rectRight(rectB), bottom: rectBottom(rectB) };

    return (
        (getBit(a.top, b.top) << 6) |
        (getBit(b.right, a.right) << 4) |
        (getBit(b.bottom, a.bottom) << 2) |
        (getBit(a.left, b.left) << 0)
    );
};
