import { AlertCircle } from "lucide-react";
import { ComponentProps } from "react";

import { Debugger } from "@/components/debug/debugger";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

interface Props extends ComponentProps<typeof Alert> {
    title?: string;
    error?: Error;
    message?: string;
}

export const ErrorAlert = ({ title, error, message, ...props }: Props) => {
    return (
        <Alert {...props} variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>{title ?? "Error"}</AlertTitle>
            <AlertDescription>
                <p>{message ?? error?.message ?? "Something went wrong."}</p>
                {error?.stack && (
                    <Debugger showOnDevEnv>
                        {!message && <p>{error.message}</p>}
                        <pre className="text-[9px] leading-3">
                            {error.stack}
                        </pre>
                    </Debugger>
                )}
            </AlertDescription>
        </Alert>
    );
};
