import { Send } from "lucide-react";
import { cloneElement } from "react";
import useSWRInfinite from "swr/infinite";

import { InviteCode, User } from "@/api/types";
import { ButtonWithConfirmation } from "@/components/admin/button-with-confirmation";
import { InviteCodesTable } from "@/components/admin/invite-codes-table";
import { ErrorAlert } from "@/components/error-alert";
import { InfiniteScroll } from "@/components/infinite-scroll";
import { Button } from "@/components/ui/button";
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet";
import { Skeleton } from "@/components/ui/skeleton";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";
import { useUser } from "@/hooks/use-user";
import { firstX, last } from "@/utils/collection";
import { getCursorKeyFn } from "@/utils/pagination";
import { isUserSuperAdmin } from "@/utils/user";

type Props = {
    user: User;
    inviteAccount?: boolean;
    pageSize?: number;
};

export const InvitationCodesContainer = ({
    user,
    inviteAccount,
    pageSize = 5,
}: Props) => {
    const api = useApi();
    const viewer = useUser();
    const [invitesDrawer, invitesDrawerActions] = useBoolean();

    // only super admins can invite accounts
    if (!isUserSuperAdmin(viewer)) {
        inviteAccount = false;
    }

    // suspends the first page request only (same with throwing)
    // Subsequent requests are not suspended and error is returned in local error var
    const { data, isLoading, error, setSize, isValidating, mutate } =
        useSWRInfinite(
            getCursorKeyFn<InviteCode>(
                pageSize,
                `${user.id}_user_invitation_codes`,
            ),
            ({ pagination }) =>
                isUserSuperAdmin(viewer)
                    ? api.admin_fetch_user_invite_codes(user.id, pagination)
                    : api.fetch_user_invite_codes(user.id, pagination),
            { suspense: true },
        );

    const sendInvite = useAsyncState(
        async () => {
            if (isUserSuperAdmin(viewer)) {
                if (inviteAccount) {
                    return await api.admin_send_account_invite(user.account_id);
                }
                return await api.admin_send_user_invite(user.id);
            }
            return await api.send_user_invite(user.id);
        },
        {
            onSuccess: () => {
                mutate();
                invitesDrawerActions.open();
            },
        },
    );

    if (isLoading) return <Skeleton className="h-8 w-52 rounded" />;
    if (error || !data)
        return (
            <ErrorAlert
                error={error}
                message={!error ? "No data returned" : undefined}
            />
        );

    const { count } = firstX(data);

    const sendInviteButton = (
        <ButtonWithConfirmation
            variant="link"
            className="p-0"
            message="Are you sure you want to send an invitation email? This action cannot be undone. Any pending invites will be invalidated."
            action={sendInvite}
        >
            <Send className="2-4 mr-2 h-4" />
            Send {inviteAccount ? "Account" : "User"} Invite
        </ButtonWithConfirmation>
    );

    return (
        <Sheet open={invitesDrawer} onOpenChange={invitesDrawerActions.set}>
            <div className="flex space-x-8">
                {count > 0 ? (
                    <SheetTrigger asChild>
                        <Button variant="link" className="p-0">
                            Manage Invites ({count})
                        </Button>
                    </SheetTrigger>
                ) : (
                    sendInviteButton
                )}
            </div>
            <SheetContent className="sm:max-w-3/4 w-3/4 space-y-4">
                <SheetHeader>
                    <SheetTitle>
                        {inviteAccount ? "Account" : "User"} Invites ({count})
                    </SheetTitle>
                </SheetHeader>
                <div className="mb-4 flex justify-end space-x-4">
                    {cloneElement(sendInviteButton, {
                        variant: "default",
                        className: "p-4",
                    })}
                </div>
                <InfiniteScroll
                    lookahead="10%"
                    isLoading={isValidating}
                    onNextPage={() => setSize((s) => s + 1)}
                    hasNextPage={last(data)?.page_info.has_next_page ?? false}
                >
                    <InviteCodesTable codes={data.flatMap((p) => p.items)} />
                    {sendInvite.error && (
                        <ErrorAlert error={sendInvite.error} />
                    )}
                </InfiniteScroll>
            </SheetContent>
        </Sheet>
    );
};
