import { Loader2 } from "lucide-react";
import { PropsWithChildren, RefObject, useEffect } from "react";

import { useIntersection } from "@/hooks/use-intersection";

type InfiniteScrollProps = {
    rootRef?: RefObject<HTMLDivElement | null>;
    lookahead?: string; // css format px, %, rem, etc
    isLoading?: boolean;
    loader?: React.ReactNode;
    hasNextPage?: boolean;
    onNextPage?: () => void;
    className?: string;
    inverted?: boolean;
};

const DefaultLoader = () => (
    <div className="flex justify-center py-4">
        <Loader2 className="size-4 animate-spin" />
    </div>
);

export const InfiniteScroll = ({
    rootRef,
    lookahead,
    isLoading,
    loader,
    onNextPage,
    hasNextPage = false,
    inverted = false,
    ...props
}: PropsWithChildren<InfiniteScrollProps>) => {
    const { ref, entry } = useIntersection({
        root: rootRef?.current,
        rootMargin: lookahead,
    });

    useEffect(() => {
        if (entry?.isIntersecting && !isLoading && hasNextPage) {
            onNextPage?.();
        }
    }, [entry, hasNextPage, onNextPage, isLoading]);

    const loaderEl = isLoading ? (loader ?? <DefaultLoader />) : undefined;

    return (
        <div className={props.className}>
            {inverted ? loaderEl : undefined}
            {inverted && hasNextPage ? (
                <div ref={ref} className="size-0" />
            ) : undefined}
            {props.children}
            {!inverted && hasNextPage ? (
                <div ref={ref} className="size-0" />
            ) : undefined}
            {!inverted ? loaderEl : undefined}
        </div>
    );
};
