import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

const badgeVariants = cva(
    "inline-flex items-center rounded-full border text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
    {
        variants: {
            variant: {
                default:
                    "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
                secondary:
                    "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
                destructive:
                    "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
                outline: "text-foreground",
                beta: "border-green-500/30 bg-green-500/15 text-green-700 hover:bg-green-500/20",
                cmd: "uppercase bg-background rounded font-semibold",
                boolean_true:
                    "rounded border-none bg-green-200 text-green-800 dark:bg-green-900 dark:text-green-200 hover:[&[role='button']]:bg-green-300 dark:hover:[&[role='button']]:bg-green-800",
                boolean_false:
                    "rounded border-none bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200",
                table_tag:
                    "rounded border-none bg-slate-200 text-slate-500 hover:bg-slate-300 hover:text-slate-600 dark:bg-slate-800 dark:text-slate-300 dark:hover:bg-slate-700",
                primary: "bg-brightwave border-brightwave dark:text-background",
            },
            size: {
                default: "px-2.5 py-0.5 font-semibold",
                sm: "px-2",
                tag: "px-2.5 py-1",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    },
);

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
    ({ className, variant, size, ...props }, ref) => (
        <div
            ref={ref}
            className={cn(badgeVariants({ variant, size }), className)}
            {...props}
        />
    ),
);

export { Badge, badgeVariants };
