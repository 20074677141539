import { PropsWithChildren, use, useEffect, useRef } from "react";
import { toast } from "sonner";
import useSWRImmutable from "swr/immutable";
import { useStore } from "zustand";

import { UUID } from "@/api/types";
import {
    EventErrorBody,
    IncomingWebsocketEventType,
} from "@/api/ws/websocket-types";
import { ActionsContext } from "@/context/actions-context";
import { AppContext } from "@/context/app-context";
import { useApi } from "@/hooks/use-api";
import { useDevMode } from "@/hooks/use-dev-mode";
import { useWebsocket } from "@/hooks/use-websocket";
import { ActionsStore, createActionsStore } from "@/stores/actions";

type Props = {
    messageID: UUID;
    actionPanelOpen?: boolean;
};

export const ActionsContextProvider = ({
    messageID,
    actionPanelOpen,
    ...props
}: PropsWithChildren<Props>) => {
    const [devMode] = useDevMode();
    const api = useApi();
    const { websocket } = use(AppContext);
    const conversationID = useSWRImmutable(
        [messageID, "conversation_id"],
        async ([id]) => await api.fetch_conversation_id(id),
        { suspense: true },
    );

    // eslint-disable-next-line react-compiler/react-compiler
    const store = useRef<ActionsStore>(
        createActionsStore({
            api,
            websocket,
            messageID,
            isActionPanelOpen: actionPanelOpen,
            conversationID: conversationID.data,
            mutateConversationID: conversationID.mutate,
        }),
    ).current;

    const actionAck = useStore(store, (s) => s.actionAck);
    const actionChunk = useStore(store, (s) => s.actionChunk);
    const actionStatusUpdate = useStore(store, (s) => s.actionStatusUpdate);
    const actionStreamComplete = useStore(store, (s) => s.actionStreamComplete);
    const actionSuccess = useStore(store, (s) => s.actionSuccess);
    const actionUpdate = useStore(store, (s) => s.actionUpdate);
    const actionError = useStore(store, (s) => s.actionError);

    const removeAction = useStore(store, (s) => s.removeAction);
    const handleEventError = (body: EventErrorBody) => {
        removeAction(body.id);
        if (devMode) {
            toast.error(`Event Error: ${body.reason}`);
        } else {
            toast.error("Someting went wrong. Please try again.");
        }
    };

    useWebsocket(
        (ws) => {
            ws.onIf(
                IncomingWebsocketEventType.action_ack,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionAck,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_chunk,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionChunk,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_status_update,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionStatusUpdate,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_success,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionSuccess,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_update,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionUpdate,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_stream_complete,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionStreamComplete,
            );
            ws.onIf(
                IncomingWebsocketEventType.action_error,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                actionError,
            );
            ws.onIf(
                IncomingWebsocketEventType.event_error,
                (body) =>
                    body.message_id === messageID &&
                    conversationID.data === body.conversation_id,
                handleEventError,
            );
        },
        [messageID, conversationID.data],
    );

    const setWebsocket = useStore(store, (s) => s.setWebsocket);
    useEffect(() => {
        setWebsocket(websocket);
    }, [setWebsocket, websocket]);

    const isLoading = useStore(store, (s) => s.isLoading);
    const pageInfo = useStore(store, (s) => s.pageInfo);
    const fetchMoreActions = useStore(store, (s) => s.fetchMoreActions);
    useEffect(() => {
        if (!isLoading && pageInfo == undefined) {
            fetchMoreActions();
        }
    }, [isLoading, pageInfo, fetchMoreActions]);

    return <ActionsContext value={store} {...props} />;
};
