import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useDemoMode } from "@/hooks/use-demo-mode";
import { useDevMode } from "@/hooks/use-dev-mode";
import { usePageTitle } from "@/hooks/use-page-title";

export const DevSettings = () => {
    usePageTitle(["Dev", "Settings"]);
    const [devMode, setDevMode] = useDevMode();
    const [demoMode, setDemoMode] = useDemoMode();

    return (
        <div className="col-span-2 space-y-4">
            <h1 className="text-headline text-2xl font-medium">Dev Settings</h1>
            <div className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                    <Label htmlFor="dev-mode" className="text-base">
                        Dev Mode
                    </Label>
                    <div className="text-sm text-gray-500">
                        Show internal tools and features
                        <span className="ml-2 font-medium">
                            (Cmd + Shift + D)
                        </span>
                    </div>
                </div>
                <Switch
                    id="dev-mode"
                    checked={devMode}
                    onCheckedChange={setDevMode}
                />
            </div>
            <div className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                    <Label htmlFor="report-v2" className="text-base">
                        Demo Mode
                    </Label>
                    <div className="text-sm text-gray-500">
                        Disable report creation
                    </div>
                </div>
                <Switch
                    id="report-v2"
                    checked={demoMode}
                    onCheckedChange={setDemoMode}
                />
            </div>
        </div>
    );
};
