import { HTMLAttributes } from "react";

import { AsyncFindingGroup, DocumentInfo } from "@/api/types";
import { ExternalRef } from "@/components/action-message/external-ref";
import { DebugID } from "@/components/debug/debug-id";
import { FindingGroup } from "@/components/report/finding-group";
import {
    ExecutiveSummarySkeleton,
    SynthesisSkeleton,
} from "@/components/report/loading-page";
import { cn } from "@/lib/utils";
import {
    isExecutiveSummary,
    isOpportunities,
    isRisks,
} from "@/utils/finding-group";
import { formatDate } from "@/utils/time";

interface Props extends HTMLAttributes<HTMLDivElement> {
    info: DocumentInfo;
    findingGroups: AsyncFindingGroup[];
}

export const Document = ({
    info,
    findingGroups,
    className,
    ...props
}: Props) => (
    <div
        className={cn(
            "grow space-y-20 overflow-y-scroll bg-background py-20 @container",
            className,
        )}
        {...props}
    >
        <div className="container space-y-10">
            <div className="flex items-center gap-2 font-headline font-bold uppercase">
                <span className="inline-block h-4 w-2.5 rounded-sm bg-current" />
                Summary
            </div>
            <DebugID label="Document" id={info.id} />
            <h1 className="font-headline text-5xl font-bold leading-tight @5xl:w-9/12">
                {info.title}
            </h1>
            <div className="flex items-center divide-x divide-border text-sm [&_*]:px-2">
                {info.content_date ? (
                    <>
                        <span>{formatDate(info.content_date)}</span>
                        <ExternalRef info={info} className="font-medium" />
                    </>
                ) : (
                    <ExternalRef info={info} className="font-medium" />
                )}
            </div>
        </div>
        <div
            className="container space-y-20 @container"
            data-bw-actions
            data-action-context={`document:${info.id}`}
        >
            <FindingGroup
                findingGroup={findingGroups.find(isExecutiveSummary)}
                loader={
                    <div className="grid gap-x-14 gap-y-10 @3xl:grid-cols-2 @5xl:gap-x-32">
                        <ExecutiveSummarySkeleton />
                    </div>
                }
            />
            <FindingGroup
                findingGroup={findingGroups.find(isRisks)}
                loader={<SynthesisSkeleton title="Risk" />}
            />
            <FindingGroup
                findingGroup={findingGroups.find(isOpportunities)}
                loader={<SynthesisSkeleton title="Opportunity" />}
            />
        </div>
    </div>
);
