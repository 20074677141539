import { Loader2 } from "lucide-react";

import { AuthLayout } from "@/layouts/auth-layout";

export const PageLoader = () => {
    return (
        <AuthLayout>
            <Loader2 className="size-10 animate-spin" />
        </AuthLayout>
    );
};
