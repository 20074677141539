import { HTMLAttributes } from "react";

import { APIContextItem, ContextType } from "@/api/types";

export const ContextItemContent = ({
    item,
    ...props
}: { item: APIContextItem } & HTMLAttributes<HTMLDivElement>) => {
    switch (item.data.type) {
        case ContextType.AIERA_TRANSCRIPT:
            return (
                <div {...props}>
                    <p className="flex items-baseline gap-3">
                        <span className="font-bold">
                            {item.data.year} Q{item.data.quarter}
                        </span>
                        <span className="text-sm text-muted-foreground">
                            Investor Meeting
                        </span>
                    </p>
                    <p className="text-xs">{item.data.ticker}</p>
                </div>
            );
        case ContextType.EXTERNAL_SEC_FILING:
            return (
                <div {...props}>
                    <p className="flex items-baseline gap-3">
                        <span className="font-bold">
                            {item.data.sub_form_type ?? item.data.form_type}
                        </span>
                        <span className="text-sm text-muted-foreground">
                            SEC Filing
                        </span>
                    </p>
                    <p className="text-xs">{item.data.ticker}</p>
                </div>
            );
        case ContextType.EXTERNAL_EARNINGS_TRANSCRIPT:
            return (
                <div {...props}>
                    <p className="flex items-baseline gap-3">
                        <span className="font-bold">
                            {item.data.year} Q{item.data.quarter}
                        </span>
                        <span className="text-sm text-muted-foreground">
                            Earnings Call
                        </span>
                    </p>
                    <p className="text-xs">{item.data.ticker}</p>
                </div>
            );
        default:
            return <div {...props} />;
    }
};
