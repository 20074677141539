import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";

import { NotFound } from "@/components/not-found";
import { Button } from "@/components/ui/button";
import { usePageTitle } from "@/hooks/use-page-title";
import { AuthLayout } from "@/layouts/auth-layout";

export const NotFoundRoute = () => {
    usePageTitle("404 Not Found");
    return (
        <AuthLayout>
            <div className="flex flex-col justify-center">
                <NotFound />
                <Button variant="link" asChild>
                    <Link to="/">
                        <ArrowLeft className="mr-2 size-4" />
                        Home
                    </Link>
                </Button>
            </div>
        </AuthLayout>
    );
};
